import { Modal } from '@mui/material';

import CloseIcon from '@assets/icons/close.svg';
import { emptyFunction } from '@utils/functions';

export const ShellModal = ({
  open,
  onClose,
  children,
  bottomButtons,
  title,
  subtitle,
  buttonsPosition,
  contentAdditionalStyle,
}: {
  open: boolean;
  onClose: () => void;
  children?: JSX.Element;
  bottomButtons?: JSX.Element[];
  title: string;
  subtitle: string;
  buttonsPosition?: 'md:justify-start' | 'md:justify-end' | 'md:justify-center';
  contentAdditionalStyle?: string;
}) => (
  <Modal
    onBackdropClick={() => null}
    open={open}
    onClose={emptyFunction}
    aria-labelledby='modal-modal-title'
    aria-describedby='modal-modal-description'
    className='flex items-center justify-center'
  >
    <div className='absolute bottom-0 flex w-full flex-col rounded-lg bg-white px-4 py-4 md:inset-y-auto md:w-auto'>
      <div className='mb-1 flex w-full items-end justify-end'>
        <div
          onClick={onClose}
          className='mb-1 flex h-[1.75rem] w-[1.75rem] cursor-pointer items-center justify-center'
        >
          <img src={CloseIcon} alt='close' className='h-4 w-4' />
        </div>
      </div>
      <div
        className={`flex flex-col px-2
        ${contentAdditionalStyle ? contentAdditionalStyle : ''}
      `}
      >
        <div className='mb-10 mr-8'>
          <div className='font-[Roboto] text-[1.5rem] font-normal leading-[2rem]'>
            {title}
          </div>
          <div className='font-[Roboto] text-[1rem] font-normal leading-[1.75rem] tracking-[0.009rem] text-gray-500'>
            {subtitle}
          </div>
        </div>
        {children}
        <div
          className={`mb-8 flex justify-center gap-4 ${
            buttonsPosition ? buttonsPosition : 'md:justify-start'
          }`}
        >
          {bottomButtons}
        </div>
      </div>
    </div>
  </Modal>
);
