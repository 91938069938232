import { TableColumn } from './interface';

export const logsTableColumns: TableColumn[] = [
  {
    label: 'Date & Time',
    align: 'left',
    className: 'w-2/12',
    innerClassName: '',
  },
  {
    label: 'Customer Info',
    align: 'left',
    className: 'w-3/12',
    innerClassName: '',
  },
  {
    label: 'Status',
    align: 'right',
    className: 'w-4/12',
    innerClassName: 'mr-[4%]',
  },
  {
    label: 'Item Preview',
    align: 'right',
    className: 'w-3/12',
    innerClassName: 'mr-[4%]',
  },
];
