import { CircularProgress } from '@mui/material';
import { LegacyRef } from 'react';
import { CellMeasurer } from 'react-virtualized';

import { VirtualizedRowProps } from './interface';

export const VirtualizedRow = ({
  cache,
  checkIfItemIsLoaded,
  hasNextPage,
  index,
  list,
  listKey,
  onClickItem,
  selectedItem,
  style,
  children,
  parent,
  isLoading,
}: VirtualizedRowProps): JSX.Element => {
  return (
    <CellMeasurer
      cache={cache}
      columnIndex={0}
      key={listKey}
      parent={parent}
      rowIndex={index}
    >
      {({ registerChild }) => (
        <div
          ref={registerChild as LegacyRef<HTMLDivElement> | undefined}
          style={style}
        >
          {isLoading && hasNextPage && index + 1 === list.length ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            children &&
            children({
              checkIfItemIsLoaded,
              hasNextPage,
              index,
              list,
              listKey,
              onClickItem,
              selectedItem,
              style,
            })
          )}
        </div>
      )}
    </CellMeasurer>
  );
};
