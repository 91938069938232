import { EDeliveryType, EOrderSubstatus } from '@utils/enums';

const curriMessages: Record<string, string> = {
  [EOrderSubstatus.PENDING_APPROVAL_QUOTE_NEEDED]:
    'Curri Delivery Selected - Waiting For Delivery Window',
  [EOrderSubstatus.APPROVED_AWAITING_DELIVERY]:
    'Curri Delivery Selected - On Its Way!',
  [EOrderSubstatus.APPROVED_READY_DELIVERY]:
    'Curri Delivery Selected – Invoice Sent, On Its Way!',
  [EOrderSubstatus.APPROVED_DELIVERED]:
    'Curri Delivery Selected - Delivered Through Curri',
  //  NEW CURRI MESSAGES
  [EOrderSubstatus.APPROVED_AWAITING_DELIVERY_QUOTE_NEEDED_WS]:
    'Curri Delivery Selected - Waiting For Delivery Window',
  [EOrderSubstatus.APPROVED_AWAITING_DELIVERY_QUOTE_NEEDED]:
    'Curri Delivery Selected - Waiting For Delivery Window',
  [EOrderSubstatus.APPROVED_AWAITING_DELIVERY_QUOTE_UPDATED]:
    'Curri Delivery Selected - Waiting For Delivery Window',
  [EOrderSubstatus.APPROVED_DELIVERED_QUOTE_NEEDED_WS]:
    'Curri Delivery Selected - Delivered, Awaiting Supplier Confirmation',
  [EOrderSubstatus.APPROVED_DELIVERED_QUOTE_NEEDED]:
    'Curri Delivery Selected - Delivered, Awaiting Supplier Confirmation',
  [EOrderSubstatus.APPROVED_DELIVERED_QUOTE_UPDATED]:
    'Curri Delivery Selected - Delivered Through Curri',
};

const shipmentMessages: Record<string, string> = {
  [EOrderSubstatus.PENDING_APPROVAL_QUOTE_NEEDED]:
    'Shipment Selected – Store Is Preparing Invoice',
  [EOrderSubstatus.PENDING_APPROVAL_QUOTE_UPDATED]:
    'Shipment Selected – Invoice Sent (Needs Confirmation)',
  [EOrderSubstatus.APPROVED_AWAITING_DELIVERY]:
    'Shipment Selected - Waiting For Delivery Window',
};

const pickupMessages: Record<string, string> = {
  [EOrderSubstatus.PENDING_APPROVAL_QUOTE_NEEDED]:
    'In-Store Pickup Selected – Awaiting Supplier Confirmation',
  [EOrderSubstatus.APPROVED_AWAITING_DELIVERY]:
    'In-Store Pickup Selected – On Will Call',
  [EOrderSubstatus.APPROVED_READY_DELIVERY]:
    'In-Store Pickup Selected – On Will Call',
  [EOrderSubstatus.APPROVED_DELIVERED]:
    'In-Store Pickup Selected – Awaiting Supplier Confirmation',
};

export const completedMessage = 'Complete';
export const canceledMessage = 'Canceled';
export const pendingApprovalFulfilledMessage = 'Needs Confirmation';

export const messages: Record<EDeliveryType, Record<string, string>> = {
  [EDeliveryType.CURRI_DELIVERY]: curriMessages,
  [EDeliveryType.SHIPMENT_DELIVERY]: shipmentMessages,
  [EDeliveryType.PICKUP]: pickupMessages,
};
