import { Skeleton } from '@mui/material';
import React from 'react';

const ANIMATION_DURATION = { animationDuration: '0.1s' };

const ListItemSkeleton = () => {
  return (
    <div className='mb-4 flex min-h-[100px] w-full cursor-pointer items-center justify-center rounded-md border border-gray-100 px-4 py-5 shadow-md'>
      <div className='flex flex-grow flex-row items-center justify-start gap-4'>
        <Skeleton
          animation='wave'
          variant='circular'
          width={40}
          height={40}
          sx={ANIMATION_DURATION}
        />
        <div className='flex flex-grow flex-row items-center justify-between'>
          <div className='flex flex-col items-start justify-center gap-2'>
            <Skeleton
              animation='wave'
              width={160}
              height={16}
              sx={ANIMATION_DURATION}
            />
            <Skeleton
              animation='wave'
              width={120}
              height={12}
              sx={ANIMATION_DURATION}
            />
            <Skeleton
              animation='wave'
              width={120}
              height={12}
              sx={ANIMATION_DURATION}
            />
          </div>
        </div>
        <div className='images-list-wrapper mr-2 flex items-center justify-start sm:justify-end sm:pt-0'>
          <Skeleton
            animation='wave'
            variant='rectangular'
            width={32}
            height={32}
            sx={ANIMATION_DURATION}
          />
        </div>
      </div>
    </div>
  );
};

export default ListItemSkeleton;
