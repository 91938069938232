import { approveOrderResponse, itemsResponse, orderResponse } from './actions';
import {
  ApproveOrderResponseProps,
  GetItemsResponseProps,
  GetOrderResponseProps,
  OrderSummaryActionProps,
  OrderSummaryState,
} from './interface';

import { getHasNextPageFromMeta, getNextPageFromMeta } from '@utils/apiPaging';

const initialState: OrderSummaryState = {
  order: null,
  items: {
    data: [],
    hasNextPage: false,
    nextPage: null,
  },
};

const orderSummaryReducer = (
  state = initialState,
  action: OrderSummaryActionProps,
): OrderSummaryState => {
  switch (action.type) {
    case String(orderResponse): {
      const { data } = (action as GetOrderResponseProps).payload;

      return {
        ...state,
        order: data,
      };
    }

    case String(itemsResponse): {
      const { data, meta } = (action as GetItemsResponseProps).payload;

      return {
        ...state,
        items: {
          ...state.items,
          data,
          hasNextPage: getHasNextPageFromMeta(meta),
          nextPage: getNextPageFromMeta(meta),
        },
      };
    }

    case String(approveOrderResponse): {
      const { data } = (action as ApproveOrderResponseProps).payload;

      return {
        ...state,
        order: data,
      };
    }

    default:
      return state;
  }
};

export default orderSummaryReducer;
