import ErrorIcon from '@mui/icons-material/Error';
import { Box, FormHelperText, InputLabel } from '@mui/material';
import { default as ReactNumberFormat } from 'react-number-format';

import './styles.scss';
import { NumberFormatProps } from './interface';

export const NUMBER_FORMAT_TEST_ID = 'numberFormat';
export const NUMBER_FORMAT_ERROR_TEST_ID = 'numberFormatErrorIcon';

export const NumberFormat = ({
  label,
  error,
  errorMessage,
  ...props
}: NumberFormatProps): JSX.Element => {
  return (
    <Box
      className='bl-number-format-container'
      display='flex'
      flexDirection='column'
      alignContent='center'
      data-testid={NUMBER_FORMAT_TEST_ID}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <ReactNumberFormat
        className={`bl-number-format ${error ? 'error' : ''}`}
        {...props}
      />
      <div className='bl-number-format-error-icon'>
        {error && (
          <ErrorIcon
            data-testid={NUMBER_FORMAT_ERROR_TEST_ID}
            color='warning'
          />
        )}
      </div>

      {!props.removeErrorsLabel && (
        <FormHelperText>{(error && errorMessage) || ' '}</FormHelperText>
      )}
    </Box>
  );
};
