import { off, onValue, ref } from 'firebase/database';
import { useEffect, useState } from 'react';

import { IStoreRating } from '@config/api/storeInfo/interface';
import { database, storeRatingNode } from '@config/firebase';

const useRealTimeStoreRating = (storeId: string) => {
  const [rating, setRating] = useState<IStoreRating | null>(null);

  useEffect(() => {
    if (!storeId) return;
    const url = `${storeRatingNode}/${storeId}`;
    const ratingRef = ref(database, url);

    onValue(ratingRef, (snapshot) => {
      if (snapshot.val()) setRating(snapshot.val());
    });

    return () => {
      off(ratingRef);
    };
  }, [storeId]);

  return { rating };
};

export default useRealTimeStoreRating;
