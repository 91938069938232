/* eslint-disable @typescript-eslint/no-explicit-any */

import * as Eff from 'redux-saga/effects';

import {
  getChannelUsers,
  getChannelUsersResponse,
  getMoreChannelUsers,
  getMoreChannelUsersResponse,
  getUserOrders,
  getUserOrdersResponse,
  setChatOpenedAction,
  setChatOpenedResolve,
} from './actions';
import { ChannelListInterface, ChatActionProps, ChatState } from './interface';

import {
  getChannelInfo,
  getUserOrdersRequest,
  getUsersRequest,
} from '@config/api/chat';
import makeRequest from '@state/requests/make-request';

export function* workerGetUsers(action: ChatActionProps): Generator<unknown> {
  const call: any = Eff.call<typeof makeRequest>;
  yield call(makeRequest, {
    endpoint: getUsersRequest,
    requestAction: action,
    receiveAction: getChannelUsersResponse,
    params: action.payload,
  });
}

export function* workerGetMoreUsers(
  action: ChatActionProps,
): Generator<unknown> {
  const call: any = Eff.call;

  const state = yield Eff.select((state) => state);
  const chatReducer = (state as any).chatReducer as ChatState;

  yield call(makeRequest, {
    endpoint: getUsersRequest,
    params: { page: chatReducer.channelList.nextPage },
    requestAction: action,
    receiveAction: getMoreChannelUsersResponse,
  });
}

export function* workerSetChatOpened(
  action: ChatActionProps,
): Generator<unknown> {
  const call: any = Eff.call;
  const { opened, channel } = action.payload;
  const state = yield Eff.select((state) => state);
  const channelList = (state as any).chatReducer
    ?.channelList as ChannelListInterface;
  const foundChannel = channelList?.list.find(
    (item) => item.chat.channel === channel,
  );

  if (foundChannel)
    yield Eff.put(setChatOpenedResolve({ opened, channel: foundChannel }));
  else
    yield call(makeRequest, {
      endpoint: getChannelInfo,
      params: channel,
      requestAction: action,
      receiveAction: setChatOpenedResolve,
    });
}
export function* workerGetUserOrders(action: ChatActionProps): Generator<any> {
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: getUserOrdersRequest,
    requestAction: action,
    receiveAction: getUserOrdersResponse,
    params: action.payload,
  });
}

export default function* chatSagas(): Generator<unknown> {
  yield Eff.all([
    Eff.takeLatest(getChannelUsers, workerGetUsers),
    Eff.takeLatest(getMoreChannelUsers, workerGetMoreUsers),
    Eff.takeLatest(getUserOrders, workerGetUserOrders),
    Eff.takeLatest(setChatOpenedAction, workerSetChatOpened),
  ]);
}
