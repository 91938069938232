import { Box } from '@mui/material';
import { MessageRendererProps } from '@pubnub/react-chat-components';
import { usePubNub } from 'pubnub-react';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import { AutomaticOrderRequestMessage } from './AutomaticOrderRequestMessage';
import { AutomaticQuoteMessage } from './AutomaticQuoteMessage';
import { CurriMessage } from './CurriMessage';
import { FileMessage } from './FileMessage';
import { QuoteDeclinedMessage } from './QuoteDeclinedMessage';
import { QuoteStatusMessage } from './QuoteStatusMessage';

import { MessageTypes } from '@utils/enums';
import { getDateTimeMessage } from '@utils/Strings/dateFormat';
export const CustomMessageRender = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: MessageRendererProps | any,
): JSX.Element => {
  const pubnub = usePubNub();
  const messageObject = message?.message ? message.message : message;

  if (messageObject && messageObject.type === MessageTypes.NewOrder)
    return (
      <AutomaticOrderRequestMessage
        timetoken={messageObject.timetoken}
        text={messageObject.text}
      />
    );

  if (messageObject && messageObject.type === MessageTypes.Curri)
    return (
      <CurriMessage
        url={messageObject.tracking_url}
        timeToken={messageObject.timetoken}
        text={messageObject.text}
      />
    );

  if (messageObject && messageObject.type === MessageTypes.InstantQuote)
    return <AutomaticQuoteMessage message={message} />;

  const getImageLink = () => {
    if (messageObject?.file?.url) return messageObject.file.url;

    if (messageObject.file?.name)
      pubnub.getFileUrl({
        channel: message.channel,
        id: messageObject.file?.id,
        name: messageObject.file?.name,
      });

    return null;
  };

  if (messageObject && messageObject.file)
    return (
      <FileMessage
        messageObject={messageObject}
        fileLink={getImageLink()}
        isOwnMessage={message?.isOwnMessage}
      />
    );

  if (messageObject && messageObject.type === MessageTypes.OrderDeclined)
    return (
      <QuoteDeclinedMessage
        text={messageObject.text}
        timetoken={messageObject.timetoken}
      />
    );

  if (message?.isOwnMessage)
    return (
      <div className='flex max-w-3/5 items-center gap-2 '>
        <Box className='flex items-start whitespace-nowrap pb-1 pt-1 text-xs text-bluon_dark_navy opacity-50'>
          {getDateTimeMessage(messageObject.timetoken, 'hh:mm a')}
        </Box>
        <Box className={'relative min-w-1/8 rounded-lg bg-primary p-4'}>
          {messageObject?.text && messageObject?.type !== 'text' && (
            <ReactMarkdown
              components={{
                p: React.Fragment,
              }}
              className='font-roboto w-full break-words text-base font-light leading-5 text-white'
            >
              {messageObject.text}
            </ReactMarkdown>
          )}
          {messageObject?.text && messageObject?.type === 'text' && (
            <Box className='font-roboto w-full break-words text-base font-light leading-5 text-white'>
              {messageObject.text}
            </Box>
          )}
        </Box>
      </div>
    );

  if (
    messageObject &&
    (messageObject.type === MessageTypes.OrderCanceled ||
      messageObject.type === MessageTypes.ApprovedOrder)
  )
    return (
      <QuoteStatusMessage
        bidNumber={messageObject.bid_number}
        poNumber={messageObject?.po_number}
        type={messageObject.type}
        timetoken={messageObject.timetoken}
        text={messageObject.text}
      />
    );

  return (
    <div className='item-chat'>
      <Box
        className={
          'no-wrap relative mr-1  min-w-1/8 max-w-3/4 rounded-lg bg-bluon_gray p-4'
        }
      >
        <Box>
          {messageObject?.text && messageObject?.type !== 'text' && (
            <ReactMarkdown
              components={{
                p: React.Fragment,
              }}
              className='font-roboto max-w-full break-words text-base font-light leading-5 text-black'
            >
              {messageObject.text}
            </ReactMarkdown>
          )}
          {messageObject?.text && messageObject?.type === 'text' && (
            <Box className='font-roboto max-w-full break-words text-base font-light leading-5 text-black'>
              {messageObject.text}
            </Box>
          )}
          {messageObject.file && (
            <img className='w-full max-w-xs' src={messageObject.file.url} />
          )}
        </Box>
      </Box>
      <Box className='flex items-start justify-items-start pb-1 pt-3 text-xs text-bluon_dark_navy opacity-50'>
        {getDateTimeMessage(messageObject.timetoken, 'h:mm a')}
      </Box>
    </div>
  );
};
