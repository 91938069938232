import { Button, Grid, Popover, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

import { LogsDatePickerPopoverProps } from './interface';

const LogsDatePickerPopover = ({
  open,
  onClose,
  anchorEl,
  control,
  errors,
  onSubmit,
  watch,
}: LogsDatePickerPopoverProps) => {
  const id = open ? 'simple-popover' : undefined;
  const [openFromDatePicker, setOpenFromDatePicker] = useState(false);
  const [openToDatePicker, setOpenToDatePicker] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container spacing={2} p={2}>
          <Grid item xs={6}>
            <Controller
              name='fromDate'
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  readOnly={!openFromDatePicker}
                  open={openFromDatePicker}
                  onClose={() => setOpenFromDatePicker(false)}
                  label='From'
                  onChange={field.onChange}
                  value={field.value || null}
                  maxDate={watch('toDate') || dayjs().toDate()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: '100%' }}
                      onClick={() => setOpenFromDatePicker(true)}
                      InputProps={{
                        endAdornment: null,
                      }}
                      error={Boolean(errors.fromDate?.message)}
                      helperText={errors.fromDate?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name='toDate'
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  readOnly={!openToDatePicker}
                  open={openToDatePicker}
                  onClose={() => setOpenToDatePicker(false)}
                  label='To'
                  minDate={watch('fromDate') || null}
                  maxDate={dayjs().toDate()}
                  value={field.value || null}
                  onChange={field.onChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: '100%' }}
                      onClick={() => setOpenToDatePicker(true)}
                      InputProps={{
                        endAdornment: null,
                      }}
                      error={Boolean(errors.toDate?.message)}
                      helperText={errors.toDate?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={onSubmit}
              variant='contained'
              color='primary'
              fullWidth
            >
              OK
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </LocalizationProvider>
  );
};

export default LogsDatePickerPopover;
