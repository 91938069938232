import * as Yup from 'yup';

import { SetNewPasswordFormKeys } from '../interfaces';

export const SetNewPasswordValidationSchema = Yup.object<
  Record<keyof SetNewPasswordFormKeys, Yup.AnySchema>
>({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long.'),
  passwordConfirmation: Yup.string()
    .required('Password doesn’t match')
    .oneOf([Yup.ref('password'), null], 'Password doesn’t match'),
  acceptTerms: Yup.boolean()
    .required('The terms and conditions must be accepted.')
    .oneOf([true], 'The terms and conditions must be accepted.'),
});
