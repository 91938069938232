import dayjs from 'dayjs';
import { onValue, ref } from 'firebase/database';
import { useEffect } from 'react';

import { StoreInfoDataModel } from '@config/api/storeInfo/interface';
import { database, databaseNodeNotificationSound } from '@config/firebase';

const useRealTimeNotificationSound = (
  storeInfo: StoreInfoDataModel | undefined,
  onValueReceived: (value: boolean) => void,
) => {
  useEffect(() => {
    if (storeInfo && databaseNodeNotificationSound) {
      const priceAndAvailabilityRef = ref(
        database,
        databaseNodeNotificationSound +
          storeInfo.id +
          '/price_and_availability',
      );
      onValue(priceAndAvailabilityRef, (snapshot) => {
        if (
          snapshot.val() &&
          dayjs
            .utc(snapshot.val())
            .local()
            .isAfter(dayjs().subtract(15, 'second'))
        )
          onValueReceived(false);
      });

      const willCallAndApprovedRef = ref(
        database,
        databaseNodeNotificationSound +
          storeInfo.id +
          '/will_call_and_approved',
      );
      onValue(willCallAndApprovedRef, (snapshot) => {
        if (
          snapshot.val() &&
          dayjs
            .utc(snapshot.val())
            .local()
            .isAfter(dayjs().subtract(15, 'second'))
        )
          onValueReceived(true);
      });
    }
  }, [storeInfo]);
};

export default useRealTimeNotificationSound;
