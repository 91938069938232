import { Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';

import { openTermsOfService } from '@state/auth/actions';

import './styles.scss';

export const TermsOfServiceLabel = (): JSX.Element => {
  const dispatch = useDispatch();

  const showTerms = () => {
    dispatch(openTermsOfService(true));
  };

  return (
    <div className='terms-of-service'>
      <Typography variant='caption' color='initial'>
        Bluon® {`${new Date().getFullYear()}. `}
        <a data-testid='termsOfServicesLink' onClick={showTerms}>
          Terms of Services and Privacy Policies
        </a>
      </Typography>
    </div>
  );
};
