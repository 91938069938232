import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React from 'react';

import { MUIAccordionProps } from './interface';
import './styles.scss';

export const MUIAccordion = ({
  headerTitle,
  children,
  ...props
}: MUIAccordionProps) => {
  return (
    <Accordion
      {...props}
      elevation={0}
      className={`MUIAccordion ${props.className}`}
    >
      <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
        {headerTitle && headerTitle}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
