import {
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';

import { tooltipContent, tooltipTestId } from './constants';

export const ComingSoonTooltip = ({
  show = true,
  children,
}: {
  show?: boolean;
  children: JSX.Element;
}): JSX.Element => {
  const ComingSoon = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#3D5066',
      fontSize: '0.75rem',
      lineHeight: '0.875rem',
      fontWeight: '500',

      [`& .${tooltipClasses.arrow}`]: {
        color: '#3D5066',
      },
    },
  });

  return show ? (
    <ComingSoon
      title={
        <Typography
          variant='caption'
          textAlign='center'
          data-testid={tooltipTestId}
        >
          {tooltipContent}
        </Typography>
      }
      placement='top'
      arrow
    >
      {children}
    </ComingSoon>
  ) : (
    children
  );
};
