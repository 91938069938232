import { useAppDispatch } from './state';
import { useQueryParams } from './useQueryParams';

import { Order } from '@config/api/task/interface';
import { setSelectedOrder } from '@state/task/actions';

const useSetSelectedOrder = () => {
  const dispatch = useAppDispatch();
  const [, setQueryParams, clearSearchParams] = useQueryParams<{
    orderId: string | undefined;
  }>();

  const changeSelectedOrder = (
    id: string | undefined,
    order: Order | undefined,
  ) => {
    if (order) dispatch(setSelectedOrder(order));
    if (id) setQueryParams({ orderId: id }, false);
  };

  const clearSelectedOrder = (replaceRoute: boolean) => {
    clearSearchParams(replaceRoute);
    //  Redux store is cleared on a useEffect on Global.tsx
  };

  return { changeSelectedOrder, clearSelectedOrder };
};

export default useSetSelectedOrder;
