import { SelectedHoursDataModel } from '@config/api/storeInfo/interface';

export const defaultToString = '5:00 pm';

export const defaultFromString = '9:00 am';

export const weekDays: SelectedHoursDataModel[] = [
  {
    day: 'monday',
    from: defaultFromString,
    to: defaultToString,
    active: true,
  },
  {
    day: 'tuesday',
    from: defaultFromString,
    to: defaultToString,
    active: true,
  },
  {
    day: 'wednesday',
    from: defaultFromString,
    to: defaultToString,
    active: true,
  },
  {
    day: 'thursday',
    from: defaultFromString,
    to: defaultToString,
    active: true,
  },
  {
    day: 'friday',
    from: defaultFromString,
    to: defaultToString,
    active: true,
  },
  {
    day: 'saturday',
    from: defaultFromString,
    to: defaultToString,
    active: false,
  },
  {
    day: 'sunday',
    from: defaultFromString,
    to: defaultToString,
    active: false,
  },
];
export const falseWeekDays: SelectedHoursDataModel[] = [
  {
    day: 'monday',
    from: defaultFromString,
    to: defaultToString,
    active: false,
  },
  {
    day: 'tuesday',
    from: defaultFromString,
    to: defaultToString,
    active: false,
  },
  {
    day: 'wednesday',
    from: defaultFromString,
    to: defaultToString,
    active: false,
  },
  {
    day: 'thursday',
    from: defaultFromString,
    to: defaultToString,
    active: false,
  },
  {
    day: 'friday',
    from: defaultFromString,
    to: defaultToString,
    active: false,
  },
  {
    day: 'saturday',
    from: defaultFromString,
    to: defaultToString,
    active: false,
  },
  {
    day: 'sunday',
    from: defaultFromString,
    to: defaultToString,
    active: false,
  },
];

export const weekDaysOrder: Record<string, number> = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};
