import { usePubNub } from 'pubnub-react';
import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMutation } from 'react-query';

import { MessageInputProps } from './interface';

import { useSnackbar } from '@components/Snackbar/hooks';
import { sendMessageRequest } from '@config/api/chat';
import { PubnubSendMessageAPI } from '@config/api/chat/interface';
export const MessageInput: FC<MessageInputProps> = ({
  onSend,
  channel,
  onChange,
  disabled,
  placeholder,
  draftMessage,
  typingIndicator,
  techUserId,
}: MessageInputProps) => {
  const pubnub = usePubNub();

  const [file, setFile] = useState<File>();
  const [text, setText] = useState(draftMessage || '');
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);
  const [typingIndicatorSent, setTypingIndicatorSent] = useState(false);
  const { showMessage } = useSnackbar();
  const [sendingStatus, setSendingStatus] = useState<'sending' | 'idle'>(
    'idle',
  );

  const sendMessageMutation = useMutation(
    async (data: PubnubSendMessageAPI) => {
      return await sendMessageRequest(data);
    },
  );

  useEffect(() => {
    clearInput();
    inputRef.current?.focus();
  }, [channel]);

  const clearInput = () => {
    setFile(undefined);
    setText('');
    autoSize();
    if (fileRef && fileRef.current) fileRef.current.value = '';
  };

  const autoSize = () => {
    const input = inputRef.current;
    if (!input) return;

    setTimeout(() => {
      const aditionalHeight = Math.floor(input.value.length / 80) * 20;
      input.style.cssText = `min-height: ${
        (aditionalHeight > 40 ? 40 : aditionalHeight) + 40
      }px;`;
    }, 0);
  };

  const isValidInputText = () => {
    return !!text.trim().length;
  };

  const stopTypingIndicator = async () => {
    if (!typingIndicatorSent) return;
    try {
      setTypingIndicatorSent(false);
      const message = { message: { type: 'typing_off' }, channel };
      pubnub.signal(message);
    } catch (e) {
      console.error('e', e);
    }
  };

  const startTypingIndicator = async () => {
    if (typingIndicatorSent) return;
    try {
      setTypingIndicatorSent(true);
      const message = { message: { type: 'typing_on' }, channel };
      pubnub.signal(message);
    } catch (e) {
      console.error('e', e);
    }
  };

  const handleSendMessage = async () => {
    if (!file && !isValidInputText()) return;
    setSendingStatus('sending');
    sendMessageMutation.mutate(
      {
        message: text,
        userId: techUserId.toString(),
      },
      {
        onSuccess: () => {
          onSend && onSend(text);
          if (typingIndicator) stopTypingIndicator();
          clearInput();
          setSendingStatus('idle');
        },
        onError: () => {
          showMessage('We couldn’t deliver your message', {
            severity: 'error',
            autoHide: true,
            closeTextButton: 'GOT IT',
          });
          setSendingStatus('idle');
          inputRef.current?.focus();
        },
      },
    );
  };

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    try {
      const textArea = event.target as HTMLTextAreaElement;
      const newText = textArea.value;

      if (typingIndicator && newText.length) startTypingIndicator();
      if (typingIndicator && !newText.length) stopTypingIndicator();

      onChange && onChange(newText);
      setText(newText);
      autoSize();
    } catch (e) {
      console.error('e', e);
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    try {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        handleSendMessage();
      }
    } catch (e) {
      console.error('e', e);
    }
  };

  return (
    <div className='p-3 pb-0'>
      <textarea
        className='w-full resize-none rounded-lg border border-gray-300 bg-gray-50 p-2 text-base text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:text-gray-500'
        data-testid='message-input'
        maxLength={32000}
        disabled={disabled || !!file || sendingStatus === 'sending'}
        onChange={(e) => handleInputChange(e)}
        onKeyPress={(e) => handleKeyPress(e)}
        placeholder={placeholder}
        ref={inputRef}
        rows={1}
        value={text}
        autoFocus
      />
      {/* <CustomSnackBar open={showError} onClose={handleCloseError} /> */}
    </div>
  );
};
