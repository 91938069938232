import * as Yup from 'yup';

import { AddStaffModalFormKeys } from './interface';

export const INVALID_EMAIL_ERROR = 'Invalid email format';

export const AddStaffModalValidationSchema = Yup.object<
  Record<keyof AddStaffModalFormKeys, Yup.AnySchema>
>({
  name: Yup.string().required('Required').max(20, 'Maximum 20 characters.'),
  email: Yup.string().email('Invalid email format').required('Required'),
  phone: Yup.string()
    .required('Required')
    .min(7, 'Phone number needs to have at least 7 digits')
    .max(15, 'Phone number cannot have more than 15 digits'),
});
