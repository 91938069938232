import { useEffect, useState } from 'react';

import { useSnackbar } from '@components/Snackbar/hooks';
import { CustomItem } from '@config/api/task/interface';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import { AddItemModal } from '@pages/Tasks/components/AddItemModal';
import { AdditionalItemRow } from '@pages/Tasks/components/AdditionalItemRow';
import { DeleteItemModal } from '@pages/Tasks/components/DeleteItemModal';
import { isRequestRunning } from '@state/requests/selectors';
import {
  addCustomItemOrderRequest,
  deleteCustomItemOrderRequest,
  deleteCustomItemOrderResponse,
  getCustomItemsOrderRequest,
} from '@state/task/actions';
import {
  getCurrentSelectedOrder,
  getOrderCustomItems,
} from '@state/task/selectors';

export const CustomItemsComponent = ({
  onCloseModalAdd,
  addItemModalOpen,
}: {
  onCloseModalAdd: () => void;
  addItemModalOpen: boolean;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();
  const [deleteItemModal, setDeleteItemModal] = useState<CustomItem>();

  const {
    order,
    customItems,
    isLoadingAddCustomItem,
    isLoadingDeleteCustomItem,
  } = useAppSelector((state) => ({
    order: getCurrentSelectedOrder(state),
    customItems: getOrderCustomItems(state),
    isLoadingAddCustomItem: isRequestRunning(
      state,
      String(addCustomItemOrderRequest),
    ),
    isLoadingDeleteCustomItem: isRequestRunning(
      state,
      String(deleteCustomItemOrderRequest),
    ),
  }));

  useEffect(() => {
    if (order) dispatch(getCustomItemsOrderRequest({ order: order.id }));
  }, []);

  const handleAddAddtionalItem = (item: { name: string; qty: string }) => {
    dispatch(
      addCustomItemOrderRequest({
        name: item.name,
        quantity: item.qty,
        order: order?.id,
        errorCallback: () => {
          showMessage(
            'There was an error adding custom item, please try again later.',
            {
              severity: 'error',
              autoHide: true,
            },
          );
        },
        successCallback: () => {
          onCloseModalAdd();
        },
      }),
    );
  };

  const handleDeleteAdditionalItem = (item: CustomItem) => {
    dispatch(
      deleteCustomItemOrderRequest({
        order: order?.id,
        customItem: item?.id,
        errorCallback: () => {
          showMessage(
            'There was an error deleting custom item, please try again later.',
            {
              severity: 'error',
              autoHide: true,
            },
          );
        },
        successCallback: () => {
          dispatch(deleteCustomItemOrderResponse({ itemId: item.item.id }));
          setDeleteItemModal(undefined);
        },
      }),
    );
  };

  return (
    <>
      <AddItemModal
        isLoading={isLoadingAddCustomItem}
        open={addItemModalOpen}
        onClose={onCloseModalAdd}
        handleAddAddtionalItem={handleAddAddtionalItem}
      />
      {!!deleteItemModal && (
        <DeleteItemModal
          isLoading={isLoadingDeleteCustomItem}
          item={deleteItemModal as CustomItem}
          open
          onClose={() => setDeleteItemModal(undefined)}
          handleDeleteItem={() =>
            handleDeleteAdditionalItem(deleteItemModal as CustomItem)
          }
        />
      )}
      {customItems.map((item: CustomItem) => (
        <AdditionalItemRow
          key={item.item.info.name + item.quantity}
          name={item.item.info.name}
          qty={item.quantity}
          onDeleteItem={() => {
            setDeleteItemModal(item);
          }}
        />
      ))}
    </>
  );
};
