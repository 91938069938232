import * as Yup from 'yup';

import { FormKeys } from './interface';

export const ValidationSchema = Yup.object<
  Record<keyof FormKeys, Yup.AnySchema>
>({
  partNumber: Yup.string()
    .required('Part Number Required')
    .max(50, 'Maximum characters allowed is 50'),
});
