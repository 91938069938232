import { Avatar, Box, Typography } from '@mui/material';
import React, { useCallback } from 'react';

import { ImagesListProps } from './interface';

import './styles.scss';

export const IMAGES_LIST_WRAPPER_TEST_ID = 'images-list-test-id';

const ImagesList = ({
  list,
  totalLength,
  customRenderRestMessage,
}: ImagesListProps) => {
  const [first, second, third, ...rest] = list;
  const remainingLength = totalLength ? totalLength : rest.length;

  const renderRemainingMessage = useCallback(() => {
    if (remainingLength > 0) {
      const remainingMessage = `+ ${remainingLength} ${
        remainingLength === 1 ? 'Item' : 'Items'
      }`;

      if (customRenderRestMessage)
        return customRenderRestMessage(remainingMessage);

      return (
        <Typography
          variant='body1'
          className='remaining-message text-center font-normal'
        >
          {remainingMessage}
        </Typography>
      );
    }

    return null;
  }, [remainingLength]);

  return (
    <Box
      data-testid={IMAGES_LIST_WRAPPER_TEST_ID}
      className='images-list-container flex flex-row items-center justify-end gap-1'
    >
      {first && (
        <Avatar
          variant='square'
          src={first.source}
          alt={first.name}
          className='image'
        />
      )}
      {second && (
        <Avatar
          variant='square'
          src={second.source}
          alt={second.name}
          className='image'
        />
      )}
      {third && (
        <Avatar
          variant='square'
          src={third.source}
          alt={third.name}
          className='image'
        />
      )}
      {renderRemainingMessage()}
    </Box>
  );
};

export default ImagesList;
