import { BannerProps, BannerStatus } from './interface';

export const bannerContainerTestId = 'banner-container-test-id';

type AvailableBanners =
  | 'newCostumerWarning'
  | 'truckStockAndCurri'
  | 'truckStockAdded'
  | 'truckStockAndShipment'
  | 'curriDelivery'
  | 'confirmPickup'
  | 'confirmShipment'
  | 'isReadyForDelivery'
  | 'sendToCustomer';

const NewCustomerButtonConfirmation = [
  {
    message: 'Cash Buyer',
    onPress: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      console.log('EV: ', event);
    },
    className: 'primary-button',
  },
  {
    message: 'Account Buyer',
    onPress: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      console.log('EV: ', event);
    },
    className: 'primary-button',
  },
  {
    message: 'Decline Request',
    onPress: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      console.log('EV: ', event);
    },
    className: 'error-button',
  },
];

export const UpdateQuoteButtonCombination = [
  {
    message: 'Update Quote',
    onPress: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      console.log('EV: ', event);
    },
    className: 'primary-button',
  },
];

export const YesNoButtonCombination = [
  {
    message: 'Yes',
    onPress: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      console.log('EV: ', event);
    },
    className: 'primary-button',
  },
  {
    message: 'No',
    onPress: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      console.log('EV: ', event);
    },
    className: 'primary-button',
  },
];

export const AvailableBanners: Record<AvailableBanners, BannerProps> = {
  newCostumerWarning: {
    message: 'Unconfirmed Customer – Confirm Status',
    status: BannerStatus.warning,
    buttons: NewCustomerButtonConfirmation,
  },
  truckStockAdded: {
    message: 'Customer added Truck Stock. You need to update the quote.',
    status: BannerStatus.success,
    buttons: UpdateQuoteButtonCombination,
  },
  curriDelivery: {
    message: 'Customer chose Curri delivery. You need to update the quote.',
    status: BannerStatus.success,
    buttons: UpdateQuoteButtonCombination,
  },
  confirmPickup: {
    message: 'Customer Confirmed Pickup - Was the Total ${fee}?',
    status: BannerStatus.success,
    buttons: YesNoButtonCombination,
  },
  isReadyForDelivery: {
    message: 'Confirm Customer Picked it up. Was the Price ${fee}?',
    status: BannerStatus.success,
    buttons: YesNoButtonCombination,
  },
  confirmShipment: {
    message: 'Customer chose Shipment. You need to update the quote.',
    status: BannerStatus.success,
    buttons: UpdateQuoteButtonCombination,
  },
  sendToCustomer: {
    message: 'Did you send this to the Customer?',
    status: BannerStatus.success,
    buttons: YesNoButtonCombination,
  },
  truckStockAndCurri: {
    message:
      'Customer added Truck Stock and chose Curri Delivery. You need to update the quote.',
    status: BannerStatus.success,
    buttons: UpdateQuoteButtonCombination,
  },
  truckStockAndShipment: {
    message:
      'Customer added Truck Stock and chose Shipment. You need to update the quote.',
    status: BannerStatus.success,
    buttons: UpdateQuoteButtonCombination,
  },
};
