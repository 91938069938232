import { createAction } from 'redux-actions';

export const orderRequest = createAction('order-summary/quotes-request');

export const orderResponse = createAction('order-summary/quotes-response');

export const itemsRequest = createAction('order-summary/items-request');

export const itemsResponse = createAction('order-summary/items-response');

export const moreItemsRequest = createAction(
  'order-summary/more-items-request',
);

export const moreItemsResponse = createAction(
  'order-summary/more-items-response',
);

export const approveOrder = createAction('order-summary/approve-order');

export const approveOrderResponse = createAction(
  'order-summary/approve-order-response',
);
