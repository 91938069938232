import { dashboardSetShowThankYouModal } from './actions';
import { DashboardActionsProps, DashboardState } from './interface';

const initialState: DashboardState = {
  showThankYouModal: false,
};

const dashboardReducer = (
  state = initialState,
  action: DashboardActionsProps,
): DashboardState => {
  switch (action.type) {
    case String(dashboardSetShowThankYouModal): {
      return {
        ...state,
        showThankYouModal: action.payload,
      };
    }

    default:
      return state;
  }
};

export default dashboardReducer;
