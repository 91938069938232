import { createAction } from 'redux-actions';

export const notificationSettingsRequest = createAction(
  'notification/notification-settings-request',
);

export const notificationSettingsResponse = createAction(
  'notification/notification-settings-response',
);

export const updateNotificationSettingsRequest = createAction(
  'notification/update-notification-settings-request',
);

export const updateNotificationSettingsResponse = createAction(
  'notification/update-notification-settings-response',
);
