/* eslint-disable no-self-assign */
import { handleRoundDecimals, handleThousandsValue } from '../functions';

export const roundNumber = (number: number, decimals: number): number => {
  return handleThousandsValue(handleRoundDecimals(number, decimals));
};

export const getHoursMinutesSecondsDiff = (
  dateTo: number,
  dateFrom: number,
): {
  hours: number;
  minutes: number;
  seconds: number;
} => {
  const hours = Math.abs(dateTo - dateFrom) / (1000 * 60 * 60);
  const minutes = (Math.abs(dateTo - dateFrom) / (1000 * 60)) % 60;
  const seconds = (Math.abs(dateTo - dateFrom) / 1000) % 60;

  return {
    hours,
    minutes,
    seconds,
  };
};

export const convertTime12to24 = (
  time12h: string,
  type: 'array' | 'string' = 'string',
): string | Array<string> => {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12') hours = '00';

  if (modifier === 'pm') hours = String(parseInt(hours, 10) + 12);

  if (hours.length === 1) hours = '0' + hours;

  if (minutes.length === 1) minutes = '0' + minutes;

  if (type === 'array') return [hours, minutes];

  return `${hours}:${minutes}`;
};

export const convertTime24to12 = (time24h: string): string => {
  const is12hFormat = /^([1-9]|1[0-2]):[0-5][0-9] (am|pm)$/.test(time24h);

  if (is12hFormat) return time24h;

  const isValid = (time: string) => {
    const d = new Date(`1970-01-01T${time}:00.000Z`);

    return d.getHours().toString().padStart(2, '0') === time.split(':')[0];
  };

  if (isValid(time24h)) return time24h;

  const get12hFormat = (hours: string) => {
    const h = parseInt(hours, 10);

    return `${h % 12 || 12}:${hours.slice(-2)} ${h < 12 ? 'am' : 'pm'}`;
  };

  return get12hFormat(time24h);
};
