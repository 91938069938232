import { useEffect, useState } from 'react';

import { Order } from '@config/api/task/interface';
import { getLogColorClassName, getLogStatusMessage } from '@utils/Orders';

const useOrderLogStatus = (log: Order) => {
  const [message, setMessage] = useState<string>('');
  const [className, setClassName] = useState<string>('');

  //  Watching changes
  useEffect(() => {
    //  Setting new message
    const newMessage = getLogStatusMessage(
      log?.delivery,
      log?.current_status,
      log?.working_on_it,
    );
    setMessage(newMessage);

    //  Setting new className
    const newClassName = getLogColorClassName(log?.current_status?.substatus);
    setClassName(newClassName);
  }, [
    log?.delivery?.type,
    log?.delivery?.info?.shipment_delivery_preference?.id,
    log?.current_status?.substatus,
    log?.working_on_it,
  ]);

  return { message, className };
};

export default useOrderLogStatus;
