/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { put } from 'redux-saga/effects';
import * as Eff from 'redux-saga/effects';

import { endRequest, endRequestWithError, startRequest } from './actions';
import { OptionsProps, ResponseGenerator } from './interface';

export default function* (options: OptionsProps): any {
  const {
    endpoint,
    params = [],
    requestAction,
    successMessage = '',
    receiveAction,
    receiveActionParams = [],
  } = options;
  let errorOccurred = false;

  try {
    const requestId = _.has(params, 'requestId')
      ? (params as never)['requestId']
      : undefined;
    // Start tracking of the network request.
    yield put(startRequest(requestAction.type, requestId));

    // Start the network request.
    const call: any = Eff.call;
    const result: ResponseGenerator = yield call(endpoint, params);

    // Update the store with the results.
    if (receiveAction)
      if (receiveActionParams.length)
        yield put(
          receiveAction({
            response: result,
            customParams: receiveActionParams,
          }),
        );
      else yield put(receiveAction(result));
  } catch (e: any) {
    // TODO: Improve error handling.s
    //
    // Stop tracking of the network request and store the error.
    const error: any = e?.response?.data?.errors ?? e;
    errorOccurred = true;
    yield put(endRequestWithError(requestAction.type, error));
  } finally {
    // Stop tracking the network request.
    if (!errorOccurred)
      yield put(endRequest(requestAction.type, successMessage));
  }
}
