import { RootState } from '..';

import { NotificationSettingsDataModel } from '@config/api/notificationSettings/interface';

export function getNotificationSettings(
  state: RootState,
): NotificationSettingsDataModel[] {
  return state.notificationSettingsReducer.settings;
}

export function getAllEmailNotificationsEnabled(state: RootState): boolean {
  return state.notificationSettingsReducer.settings
    .filter((setting) => setting.id.endsWith('email'))
    .every((setting) => setting.value);
}

export function getAllSmsNotificationsEnabled(state: RootState): boolean {
  return state.notificationSettingsReducer.settings
    .filter((setting) => setting.id.endsWith('sms'))
    .every((setting) => setting.value);
}
