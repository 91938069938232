import * as Yup from 'yup';

import { DeclineModalForm } from './interface';

export const OTHER_TEXT_MAX_LENGTH_DOC = 255;
export const OTHER_TEXT_MIN_LENGTH_DOC = 10;

export const DeclineModalValidationSchema = Yup.object<
  Record<keyof DeclineModalForm, Yup.AnySchema>
>({
  selectedOption: Yup.string(),
  otherText: Yup.string()
    .min(OTHER_TEXT_MIN_LENGTH_DOC, 'Minimum 10 characters required.')
    .max(OTHER_TEXT_MAX_LENGTH_DOC + 1, ''),
  acceptTerms: Yup.boolean(),
});
