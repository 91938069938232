import { RootState } from '..';

import { ChannelSelectedState } from './interface';

import { OrderItemList } from '@config/api/chat/interface';

export function getChannelList(state: RootState): ChannelSelectedState[] {
  return state.chatReducer.channelList?.list;
}

export function getChatListHasNextPage(state: RootState): boolean {
  return state.chatReducer.channelList?.hasNextPage;
}

export function getCurrentChatSelected(
  state: RootState,
): ChannelSelectedState | undefined {
  return state.chatReducer.currentChatSelected;
}

export function getChatOpened(state: RootState): boolean {
  return state.chatReducer.chatOpened;
}

export function getChatUnreadMessages(
  state: RootState,
): Record<string, number> {
  return state.chatReducer.unreadChatMessages;
}

export function getUserOrderList(
  state: RootState,
): OrderItemList[] | undefined {
  return state.chatReducer.userOrderList;
}
