import { createAction } from 'redux-actions';

//  Get Order
export const getShareableOrderRequest = createAction(
  'shareable-order/get-shareable-order-request',
);
export const getShareableOrderResponse = createAction(
  'shareable-order/get-shareable-order-response',
);

//  Get Order Parts
export const getShareableOrderPartsRequest = createAction(
  'shareable-order/get-shareable-order-parts-request',
);
export const getShareableOrderPartsResponse = createAction(
  'shareable-order/get-shareable-order-parts-response',
);

//  Get Order Extra Items
export const getShareableOrderExtraItemsRequest = createAction(
  'shareable-order/get-shareable-order-extra-items-request',
);
export const getShareableOrderExtraItemsResponse = createAction(
  'shareable-order/get-shareable-order-extra-items-response',
);

//  Get order added items
export const getShareableOrderAddedItemsRequest = createAction(
  'shareable-order/get-shareable-order-added-items-request',
);
export const getShareableOrderAddedItemsResponse = createAction(
  'shareable-order/get-shareable-order-added-items-response',
);

//  Get Order Extra Items
export const getShareableOrderCustomItemsRequest = createAction(
  'shareable-order/get-shareable-order-custom-items-request',
);
export const getShareableOrderCustomItemsResponse = createAction(
  'shareable-order/get-shareable-order-custom-items-response',
);

//  Reset
export const resetShareableOrder = createAction(
  'shareable-order/reset-shareable-order',
);

// Decline
export const declineShareableOrder = createAction(
  'shareable-order/decline-shareable-order',
);
export const declineShareableOrderResponse = createAction(
  'shareable-order/decline-shareable-order-response',
);

// Approve pending approval fulfilled Order 200
export const approveShareableOrderRequest = createAction(
  'shareable-order/approve-shareable-order-request',
);
export const approveShareableOrderResponse = createAction(
  'shareable-order/approve-shareable-order-response',
);

//  Approve pending approval updated (shipment) order
export const approveUpdatedShipmentOrderRequest = createAction(
  'shareable-order/approve-updated-shipment-order-request',
);
export const approveUpdatedShipmentOrderResponse = createAction(
  'shareable-order/approve-updated-shipment-order-response',
);
