export const autoFocusInput = (
  totalInputRef: React.MutableRefObject<HTMLInputElement | undefined>,
) => {
  const timeout = setTimeout(() => {
    totalInputRef?.current?.focus();
  }, 100);

  return () => {
    clearTimeout(timeout);
  };
};
