/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { ActionRequestProps } from './interface';

export const START_REQUEST = 'requests/start-request';
export const END_REQUEST = 'requests/end-request';
export const END_REQUEST_ERROR = 'requests/end-request-with-error';
export const RESET_REQUEST = 'requests/reset';

export const startRequest = (
  id: string,
  requestId?: string,
): ActionRequestProps => ({
  type: START_REQUEST,
  payload: { id, requestId: requestId },
});

export const endRequest = (
  id: string,
  successMessage: string,
): ActionRequestProps => ({
  type: END_REQUEST,
  payload: { id, successMessage },
});

export const endRequestWithError = (
  id: string,
  error: any,
): ActionRequestProps => ({
  type: END_REQUEST_ERROR,
  payload: { id, error },
});

export const resetRequests = (): ActionRequestProps => ({
  type: RESET_REQUEST,
});
