import { ShareableOrder, ShareableOrderState } from './interface';

import { CustomItem, ExtraItem, OrderItem } from '@config/api/task/interface';

export const getShareableOrder = (state: any): ShareableOrder | null => {
  return (state?.shareableOrderReducer as ShareableOrderState)?.order;
};

export const getShareableOrderParts = (state: any): OrderItem[] => {
  return (
    (state?.shareableOrderReducer as ShareableOrderState)?.parts?.data || []
  );
};

export const getShareableOrderExtraItems = (state: any): ExtraItem[] => {
  return (
    (state?.shareableOrderReducer as ShareableOrderState)?.extraItems?.data ||
    []
  );
};

export const getShareableOrderCustomItems = (state: any): CustomItem[] => {
  return (
    (state?.shareableOrderReducer as ShareableOrderState)?.customItems?.data ||
    []
  );
};

export const getShareableOrderAddedItems = (state: any): ExtraItem[] => {
  return (
    (state?.shareableOrderReducer as ShareableOrderState)?.addedItems?.data ||
    []
  );
};
