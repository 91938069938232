/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash';
import { all, select, takeLatest } from 'redux-saga/effects';
import * as Eff from 'redux-saga/effects';

import makeRequest from '../requests/make-request';
import { getRequestError } from '../requests/selectors';

import {
  approveOrder,
  approveOrderResponse,
  itemsRequest,
  itemsResponse,
  moreItemsRequest,
  moreItemsResponse,
  orderRequest,
  orderResponse,
} from './actions';
import {
  OrderSummaryOrderActionParamsProps,
  OrderSummaryState,
} from './interface';

import {
  approveOrderSummary,
  getItems,
  getOrder,
} from '@config/api/orderSummary';

export function* workerOrderRequest(
  action: OrderSummaryOrderActionParamsProps,
): Generator<any> {
  const { orderId } = action.payload;
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: getOrder,
    params: { orderId },
    requestAction: action,
    receiveAction: orderResponse,
  });
}

export function* workerItemsRequest(
  action: OrderSummaryOrderActionParamsProps,
): Generator<any> {
  const { orderId } = action.payload;
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: getItems,
    params: { page: 1, orderId },
    requestAction: action,
    receiveAction: itemsResponse,
  });
}

export function* workerMoreItemsRequest(
  action: OrderSummaryOrderActionParamsProps,
): Generator<any> {
  const { orderId } = action.payload;
  const call: any = Eff.call;
  const state = yield select((state) => state);
  const orderSummaryReducer = (state as any)
    .orderSummaryReducer as OrderSummaryState;
  yield call(makeRequest, {
    endpoint: getItems,
    params: { page: orderSummaryReducer.items.nextPage, orderId },
    requestAction: action,
    receiveAction: moreItemsResponse,
  });
}

export function* workerApproveOrder(action: {
  payload: { orderId: string; name: string; errorCallback: () => void };
  type: string;
}): Generator<any> {
  const { orderId, name, errorCallback } = action.payload;
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: approveOrderSummary,
    params: { orderId, name },
    requestAction: action,
    receiveAction: approveOrderResponse,
  });

  const error = yield select((state) => getRequestError(state, approveOrder));
  if (!isEmpty(error)) {
    yield call(errorCallback);

    return;
  }
}

export default function* orderSummarySagas(): Generator<any> {
  yield all([
    takeLatest(orderRequest, workerOrderRequest),
    takeLatest(itemsRequest, workerItemsRequest),
    takeLatest(moreItemsRequest, workerMoreItemsRequest),
    takeLatest(approveOrder, workerApproveOrder),
  ]);
}
