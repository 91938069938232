import { useLocation, useNavigate } from 'react-router-dom';

export const useQueryParams = <T>(): [
  T,
  (newParams: T, replaceRoute: boolean) => void,
  (replaceRoute: boolean) => void,
] => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const setSearchParams = (newParams: any, replaceRoute = false) => {
    const searchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(newParams))
      searchParams.set(key, value as string);

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: replaceRoute,
    });
  };

  const clearSearchParams = (replace = false) => {
    navigate(location?.pathname, { replace });
  };

  return [
    Object.fromEntries(searchParams) as T,
    setSearchParams,
    clearSearchParams,
  ];
};
