import {
  Container,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import './styles.scss';

import { CircularLoading } from '../../../../components/CircularLoading';
import { useSnackbar } from '../../../../components/Snackbar/hooks';

import { NotificationIndexedSettings } from './interfaces';

import { NotificationSettingsDataModel } from '@config/api/notificationSettings/interface';
import { useAppDispatch, useAppSelector } from '@hooks/state';
import {
  notificationSettingsRequest,
  updateNotificationSettingsRequest,
} from '@state/notificationSettings/actions';
import {
  getAllEmailNotificationsEnabled,
  getAllSmsNotificationsEnabled,
  getNotificationSettings,
} from '@state/notificationSettings/selectors';
import { isRequestRunning } from '@state/requests/selectors';
import { getStoreInfoContacts } from '@state/storeInfo/selectors';

export const Notification = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();

  useEffect(() => {
    dispatch(notificationSettingsRequest());
  }, [dispatch]);

  const {
    notificationSettings,
    allEmailNotificationsEnabled,
    allSmsNotificationsEnabled,
    storeContacts,
    isLoading,
    isUpdating,
  } = useAppSelector((state) => ({
    notificationSettings: getNotificationSettings(state),
    allEmailNotificationsEnabled: getAllEmailNotificationsEnabled(state),
    allSmsNotificationsEnabled: getAllSmsNotificationsEnabled(state),
    storeContacts: getStoreInfoContacts(state),
    isLoading: isRequestRunning(
      state,
      String(notificationSettingsRequest),
      true,
    ),
    isUpdating: isRequestRunning(
      state,
      String(updateNotificationSettingsRequest),
    ),
  }));

  const dispatchUpdate = (params: NotificationIndexedSettings) =>
    dispatch(
      updateNotificationSettingsRequest({
        ...params,
        errorCallback: () => {
          showMessage('Error updating notification', {
            severity: 'error',
            autoHide: true,
          });
        },
      }),
    );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    dispatchUpdate({ [name]: event.target.checked });
  };

  const settingsIndexBySlug: NotificationIndexedSettings =
    notificationSettings.reduce(
      (
        memo: Record<string, Record<string, boolean | null>>,
        setting: NotificationSettingsDataModel,
      ) => {
        const slug = setting.id.replace(/-(sms|email)$/, '');
        const type = setting.id.replace(slug + '-', '');

        if (!memo[slug]) memo[slug] = {};

        memo[slug][type] = setting.value;

        return memo;
      },
      {},
    );

  const handleAllNotifications = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: string,
  ) => {
    dispatchUpdate(
      notificationSettings.reduce((memo: Record<string, boolean>, setting) => {
        if (setting.id.indexOf(index) === -1) return memo;
        memo[setting.id] = e.target.checked;

        return memo;
      }, {}),
    );
  };

  const notificationsStructure = [
    {
      title: 'All Notifications',
      switchValues: [
        {
          value: 'all-email-notifications',
          checked: allEmailNotificationsEnabled,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleAllNotifications(e, 'email'),
        },
        {
          value: 'all-sms-notifications',
          checked: allSmsNotificationsEnabled,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleAllNotifications(e, 'sms'),
        },
      ],
    },
    {
      title: 'New Order Request comes in',
      switchValues: [
        {
          value: 'new-order-request-email',
          checked: settingsIndexBySlug['new-order-request']?.email ?? false,
          onChange: handleChange,
        },
        {
          value: 'new-order-request-sms',
          checked: settingsIndexBySlug['new-order-request']?.sms ?? false,
          onChange: handleChange,
        },
      ],
    },
    {
      title: 'Quote/Bid is Approved',
      switchValues: [
        {
          value: 'order-approved-email',
          checked: settingsIndexBySlug['order-approved']?.email ?? false,
          onChange: handleChange,
        },
        {
          value: 'order-approved-sms',
          checked: settingsIndexBySlug['order-approved']?.sms ?? false,
          onChange: handleChange,
        },
      ],
    },
    {
      title: 'Quote/Bid is Rejected',
      switchValues: [
        {
          value: 'order-rejected-email',
          checked: settingsIndexBySlug['order-rejected']?.email ?? false,
          onChange: handleChange,
        },
        {
          value: 'order-rejected-sms',
          checked: settingsIndexBySlug['order-rejected']?.sms ?? false,
          onChange: handleChange,
        },
      ],
    },
    {
      title: 'New Messages',
      switchValues: [
        {
          value: 'new-message-email',
          checked: settingsIndexBySlug['new-message']?.email ?? false,
          onChange: handleChange,
        },
        {
          value: 'new-message-sms',
          checked: settingsIndexBySlug['new-message']?.sms ?? false,
          onChange: handleChange,
        },
      ],
    },
    {
      title: 'New Bluon Member to be Verified',
      switchValues: [
        {
          value: 'new-member-email',
          checked: settingsIndexBySlug['new-member']?.email ?? false,
          onChange: handleChange,
        },
        {
          value: 'new-member-sms',
          checked: settingsIndexBySlug['new-member']?.sms ?? false,
          onChange: handleChange,
        },
      ],
    },
  ];

  return (
    <Container maxWidth='xl' className='bl-notification-container'>
      {isLoading ? (
        <CircularLoading />
      ) : (
        <TableContainer className='bl-notification-form-wrapper'>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className='bl-notification-table-cell-large'>
                  <Typography variant='h6'>Notifications</Typography>
                </TableCell>

                <TableCell
                  align='center'
                  className='bl-notification-table-cell-small'
                >
                  <Typography variant='body1'>E-mail</Typography>
                  {storeContacts?.contact_email && (
                    <Typography variant='body2'>
                      {storeContacts?.contact_email}
                    </Typography>
                  )}
                  {storeContacts?.contact_secondary_email && (
                    <Typography variant='body2'>
                      {storeContacts?.contact_secondary_email}
                    </Typography>
                  )}
                </TableCell>

                <TableCell
                  align='center'
                  className='bl-notification-table-cell-small'
                >
                  <Typography variant='body1'>SMS</Typography>
                  {storeContacts?.contact_phone && (
                    <Typography variant='body2'>
                      Phone #: {storeContacts?.contact_phone}
                    </Typography>
                  )}
                  {storeContacts?.prokeep_phone && (
                    <Typography variant='body2'>
                      Prokeep #: {storeContacts?.prokeep_phone}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notificationsStructure.map((structure, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Typography variant='body1'>{structure.title}</Typography>
                  </TableCell>
                  {structure.switchValues.map((st) => (
                    <TableCell align='center' key={st.value}>
                      <Switch
                        checked={st.checked}
                        value={st.value}
                        onChange={st.onChange}
                        disabled={isUpdating}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};
