import DeleteIcon from '@mui/icons-material/Delete';

export const AdditionalItemRow = ({
  name,
  qty,
  onDeleteItem,
}: {
  name: string;
  qty: string | number;
  onDeleteItem: () => void;
}) => (
  <div className='flex w-full justify-between rounded-md border px-4 py-2'>
    <div className='font-[Roboto] text-[1.125rem] font-bold leading-[1.609rem] tracking-[0.011rem]'>
      {name}
    </div>
    <div className='flex flex-row items-center justify-center'>
      <div className='mr-3 font-[Roboto] text-[0.875rem] font-normal leading-[1.25rem] tracking-[0.011rem] text-[#00000099] text-slate-700'>
        Qty: {qty}
      </div>
      <div className='cursor-pointer' onClick={onDeleteItem}>
        <DeleteIcon
          className='text-[var(--bluon--default--red)]'
          sx={{ height: 18 }}
        />
      </div>
    </div>
  </div>
);
