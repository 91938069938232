import Toolbar from '@mui/material/Toolbar';

import { EnhancedTableToolbarProps } from './interface';

import { SearchInput } from '@components/SearchInput';

import './styles.scss';

export const EnhancedTableToolbar = ({
  handleSearchChange,
  searchCriteria,
}: EnhancedTableToolbarProps) => {
  return (
    <Toolbar
      className='supplier-table-toolbar flex justify-between sm:max-w-[32rem] 2xl:max-w-4xl'
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <SearchInput
        label='Search Store Names, Branch ID´s or Addresses'
        containerClassName='bl-search-input-custom !pl-0'
        onChange={handleSearchChange}
        value={searchCriteria}
        placeholder='Search Store Names, Branch ID´s or Addresses'
        data-testid='searchInput'
        alwaysShowBorder
        searchClassName='!fill-[var(--bluon--ds--search-light-black)]'
        customInputType='text'
        useCustomSearchAdornment
      />
    </Toolbar>
  );
};
