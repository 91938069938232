import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { Box } from '@mui/material';
import React, { useMemo } from 'react';

import Shipment from '@assets/icons/Shipment.svg';
import { Delivery } from '@config/api/interface';
import {
  checkIfIsTodayOrTomorrow,
  transformStringDateTo12HoursFormat,
} from '@utils/Dates';
import { EDeliveryType } from '@utils/enums';
import { handleRoundDecimals } from '@utils/functions';
import { capitalizeFirstLetter } from '@utils/Strings/capitalize';

export const Message = ({
  delivery,
  customDeliveryBarMessage,
}: {
  delivery: Delivery;
  customDeliveryBarMessage: string;
}) => {
  const isShipment = delivery?.type === EDeliveryType.SHIPMENT_DELIVERY;
  const isPickup = delivery?.type === EDeliveryType.PICKUP;
  const { requested_date, requested_start_time, requested_end_time } = delivery;

  const parsedDate = useMemo(() => {
    if (!requested_date) return requested_date;
    let reqDate = checkIfIsTodayOrTomorrow(requested_date.toLocaleString());
    if (reqDate !== 'Today' && reqDate !== 'Tomorrow') reqDate = reqDate + ' -';

    return reqDate;
  }, [requested_date]);

  const message = {
    [EDeliveryType.CURRI_DELIVERY]: `Need to add Curri charge ($${handleRoundDecimals(
      delivery?.fee,
      2,
    )}) to invoice`,
    [EDeliveryType.SHIPMENT_DELIVERY]: `${capitalizeFirstLetter(
      delivery?.info?.shipment_delivery_preference?.id || '',
    )} Shipment Selected`,
    [EDeliveryType.PICKUP]: 'In Store Pickup',
  };

  return (
    <Box className='flex w-full justify-center'>
      {isShipment ? (
        <img alt='shipment' src={Shipment} />
      ) : isPickup ? (
        <DirectionsWalkIcon sx={{ color: '#0567D1', height: 21 }} />
      ) : null}
      <span className='mx-1'>
        {customDeliveryBarMessage || message[delivery?.type] || ''}
      </span>
      <span>
        {isPickup
          ? ` • ${parsedDate} ${transformStringDateTo12HoursFormat(
              requested_start_time,
            )} - ${transformStringDateTo12HoursFormat(requested_end_time)}`
          : null}
      </span>
    </Box>
  );
};
