import {
  approveShareableOrderResponse,
  approveUpdatedShipmentOrderResponse,
  declineShareableOrderResponse,
  getShareableOrderAddedItemsResponse,
  getShareableOrderCustomItemsResponse,
  getShareableOrderExtraItemsResponse,
  getShareableOrderPartsResponse,
  getShareableOrderResponse,
  resetShareableOrder,
} from './actions';
import {
  CustomItemData,
  ExtraItemData,
  PartData,
  ShareableOrderAction,
  ShareableOrderState,
} from './interface';

import { handleCustomReplacement } from '@utils/functions';

const initialState: ShareableOrderState = {
  order: null,
  parts: null,
  extraItems: null,
  customItems: null,
  addedItems: null,
};

const shareableOrderReducer = (
  state = initialState,
  action: ShareableOrderAction,
): ShareableOrderState => {
  switch (action?.type) {
    case String(getShareableOrderResponse): {
      return { ...state, order: (action?.payload as any)?.data || {} };
    }
    case String(getShareableOrderPartsResponse): {
      const newData = (action?.payload as any)?.data.map((item: any) => {
        if (item.replacement && !item.replacement.details)
          return {
            ...item,
            replacement: handleCustomReplacement(item.replacement),
          };

        return item;
      });

      return {
        ...state,
        parts: {
          ...(action?.payload as any),
          data: newData,
        } as PartData,
      };
    }
    case String(getShareableOrderExtraItemsResponse): {
      return { ...state, extraItems: action?.payload as ExtraItemData };
    }
    case String(getShareableOrderCustomItemsResponse): {
      return { ...state, customItems: action?.payload as CustomItemData };
    }
    case String(resetShareableOrder): {
      return { ...state, ...initialState };
    }
    case String(getShareableOrderAddedItemsResponse): {
      return { ...state, addedItems: action?.payload as ExtraItemData };
    }
    case String(declineShareableOrderResponse): {
      return { ...state, order: (action?.payload as any)?.data || {} };
    }
    case String(approveShareableOrderResponse):
    case String(approveUpdatedShipmentOrderResponse): {
      return {
        ...state,
        order: {
          ...(state?.order || {}),
          ...((action?.payload as any)?.data || {}),
        },
      };
    }
    default:
      return state;
  }
};

export default shareableOrderReducer;
