import { Skeleton } from '@mui/material';
import React from 'react';

import BluonLogo from '@assets/images/Bluon.svg';

const LoadingSkeleton = () => {
  return (
    <div className='flex w-full flex-row items-center justify-center'>
      <div className='flex w-full flex-col items-center justify-center'>
        <img src={BluonLogo} className='w-25 mb-4 h-9' />
        <div className='max-w-full break-all text-center text-xl font-medium leading-8 tracking-wide text-black'>
          <Skeleton width={700} height={30} />
        </div>
        <div className='font-sm max-w-full break-all text-center font-normal tracking-wide text-black'>
          <Skeleton width={700} height={30} />
        </div>
        <div className='text-xl font-medium leading-8 tracking-wide'>
          <Skeleton width={700} height={30} />
        </div>
      </div>
    </div>
  );
};

export default LoadingSkeleton;
