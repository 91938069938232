import { StoreInfoPayloadModel } from './interface';

import { StoreInfoFormKeys } from '@pages/Account/subpages/StoreInfo/interfaces';

export const mapPatchPayload = (
  formData: StoreInfoFormKeys,
): StoreInfoPayloadModel => {
  const {
    // Variable not passed to the patch request
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    branch_hours,
    image,
    logo,
    ...otherPayloadValues
  } = formData;

  return {
    image,
    logo,
    ...otherPayloadValues,
  };
};

export function buildFormData(
  formData: FormData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>,
  parentKey = '',
): void {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key,
      );
    });
  } else {
    const value = data == null ? '' : (data as string | Blob);
    formData.append(parentKey, value);
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function formDataToObject(formData: FormData): object {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const object = {} as any;
  formData.forEach((value, key) => {
    if (!Reflect.has(object, key)) {
      object[key] = value;

      return;
    }
    if (!Array.isArray(object[key])) object[key] = [object[key]];

    object[key].push(value);
  });

  return object;
}
export function prepareFormData(
  formData: FormData,
  mappedPayload: StoreInfoPayloadModel,
): void {
  buildFormData(formData, mappedPayload);

  formData.append('_method', 'PATCH');
}
