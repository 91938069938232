import classNames from 'classnames';
import React from 'react';

import { OrderStatusLabelProps } from './interface';
import './styles.scss';

import useOrderLogStatus from '@hooks/useOrderLogStatus';

const OrderStatusLabel = ({
  order,
  extraClassName = '',
  isLogScreen,
  noLeftMargin,
}: OrderStatusLabelProps) => {
  const { message, className } = useOrderLogStatus(order);

  return (
    <div
      className={`flex flex-row items-center justify-end ${className} ${
        !noLeftMargin && 'ml-auto'
      } w-max gap-1 rounded p-1 ${extraClassName}`}
    >
      <span
        className={classNames(extraClassName || '', {
          'max-w-[26.375em] overflow-hidden truncate': isLogScreen,
        })}
      >
        {message}
      </span>
    </div>
  );
};

export default OrderStatusLabel;
