import { Grid, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';

import { FormCardContainerProps } from './interface';
import './styles.scss';

const FormCardContainer = ({
  title,
  subTitle,
  previewComponent,
  form,
}: FormCardContainerProps): JSX.Element => {
  return (
    <Paper elevation={0}>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Typography
            className='bl-card-title'
            variant='h6'
            color='initial'
            fontWeight='bold'
          >
            {title}
          </Typography>
          <Typography
            className='bl-card-subtitle'
            variant='subtitle1'
            color='initial'
          >
            {subTitle}
          </Typography>

          {previewComponent}
        </Grid>
        <Grid item xs={12} md={7}>
          {form}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FormCardContainer;
