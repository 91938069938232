import ErrorIcon from '@mui/icons-material/Error';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Skeleton,
  Stack,
} from '@mui/material';
import { useState } from 'react';

import {
  errorIconTestId,
  inputLabelTestId,
  inputSublabelTestId,
  loadingSkeletonTestId,
  passwordIconTestId,
} from './constants';
import { TextInputProps } from './interface';
import './styles.scss';

const TextInput = ({
  label,
  subLabel,
  error,
  errorMessage,
  maxLength,
  type,
  isLoading = false,
  'data-testid': dataTestId,
  min,
  max,
  pattern,
  customAdornment,
  ...props
}: TextInputProps): JSX.Element => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeInputHandler = (event: any) => {
    const value = event.target.value;
    if (value === ' ') return;
    if (pattern && !pattern.test(value)) return;
    if (props.onChange) props.onChange(event);
  };

  return (
    <Box className={props.inlinelabel ? 'inline-label' : ''}>
      <Stack spacing={1} direction='row'>
        {label && (
          <InputLabel
            data-testid={inputLabelTestId}
            className={props.disabled ? 'disabled-label' : ''}
          >
            {label}
            {subLabel && (
              <em data-testid={inputSublabelTestId} className='lb-subtitle'>
                {' '}
                {subLabel}
              </em>
            )}
          </InputLabel>
        )}
      </Stack>
      {isLoading ? (
        <Skeleton
          role='progressbar'
          data-testid={loadingSkeletonTestId}
          className='text-input-skeleton'
          animation='wave'
        />
      ) : (
        <Input
          fullWidth
          disableUnderline
          error={error}
          type={showPassword ? 'text' : type}
          {...props}
          onChange={changeInputHandler}
          endAdornment={
            <InputAdornment position='end'>
              {type === 'password' && (
                <IconButton
                  data-testid={passwordIconTestId}
                  aria-label='toggle password visibility'
                  onClick={handleTogglePassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              )}
              {customAdornment}
              {error && (
                <ErrorIcon
                  className='warning-icon'
                  color='warning'
                  data-testid={errorIconTestId}
                />
              )}
            </InputAdornment>
          }
          inputProps={{
            maxLength: maxLength?.toString(),
            min,
            max,
            'data-testid': dataTestId,
            'aria-label': label,
            role: 'textbox',
          }}
        />
      )}
      {!props.removeErrorsLabel && (
        <FormHelperText>{(error && errorMessage) || ' '}</FormHelperText>
      )}
    </Box>
  );
};

export default TextInput;
