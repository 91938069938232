import { Box } from '@mui/material';

import Money from '@assets/images/money.png';
import { getDateTimeMessage } from '@utils/Strings/dateFormat';

export const AutomaticOrderRequestMessage = ({
  text,
  timetoken,
}: {
  text: string;
  timetoken: string;
}): JSX.Element => {
  return (
    <div className='item-chat'>
      <Box className='inline-element tech-message rounded-lg bg-bluon_gray p-4'>
        <Box className='flex flex-row items-center justify-center'>
          <img src={Money} alt='Logo' className='mr-3 w-9' />
          <Box className='flex'>{text}</Box>
        </Box>
      </Box>
      <Box className='time-message'>
        {getDateTimeMessage(timetoken, 'h:mm a')}
      </Box>
    </div>
  );
};
