import React, { createContext, useState } from 'react';

import { Snackbar } from '..';
import { SnackbarOptions } from '../interface';

import { SnackbarContextProps } from './interface';

const SnackbarContext = createContext({} as SnackbarContextProps);

export const useSnackbar = (): SnackbarContextProps => {
  const context = React.useContext(SnackbarContext);

  if (!context)
    throw new Error('useSnackbar must be used inside SnackbarProvider');

  return context;
};

export const SnackbarProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [item, setItem] = useState<{
    message: string | JSX.Element | null;
    open: boolean;
    options: SnackbarOptions;
  }>({
    message: null,
    open: false,
    options: {
      severity: 'info',
      position: 'bottom-left',
      closeTextButton: 'GOT IT',
    },
  });

  const {
    message,
    open,
    options: { severity, position, closeTextButton, subMessage, xCloseButton },
  } = item;

  const showMessage = React.useCallback(
    (message: string | JSX.Element, options: SnackbarOptions) => {
      setItem({ message, options, open: true });
      if (options.autoHide)
        setTimeout(() => {
          setItem((prevState) => ({ ...prevState, open: false }));
        }, 2000);
    },
    [],
  );

  const handleClose = React.useCallback(
    (_event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') return;
      setItem((prevState) => ({ ...prevState, open: false }));
    },
    [],
  );

  const value = React.useMemo(() => ({ showMessage }), [showMessage]);

  return (
    <SnackbarContext.Provider value={value}>
      {message && (
        <Snackbar
          message={message}
          open={open}
          severity={severity}
          position={position ?? 'bottom-left'}
          closeTextButton={closeTextButton ?? 'GOT IT'}
          subMessage={subMessage}
          onClose={handleClose}
          xCloseButton={xCloseButton}
        />
      )}
      {children}
    </SnackbarContext.Provider>
  );
};
