import { TableCell } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

import { CellProps } from '../LogsTable/interface';

const DateTimeCell = ({ log, ...restOfProps }: CellProps) => {
  const formatDateTime = (format = '') => {
    if (!log?.created_at) return null;

    const createdAt = new Date(log?.created_at);
    const dateTime = DateTime.fromJSDate(createdAt);

    return dateTime.toFormat(format);
  };

  return (
    <TableCell {...restOfProps}>
      <div className='h-1/1 w-1/1 flex flex-col items-start justify-center'>
        <div className='w-1/1 text-sm font-normal text-black text-opacity-60'>
          {formatDateTime('LL/dd/yy')}
        </div>
        <div className='w-1/1 text-sm font-normal text-black text-opacity-40'>
          {formatDateTime('t')}
        </div>
      </div>
    </TableCell>
  );
};

export default DateTimeCell;
