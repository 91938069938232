import { DateTime } from 'luxon';

export const getDateTimeFormat = (
  timestamp: string,
  pattern: string,
  isUTC = false,
): string => {
  const unixTimestamp = parseInt(timestamp) / 10000;

  const newDate = DateTime.fromMillis(unixTimestamp);

  return isUTC ? newDate.toUTC().toFormat(pattern) : newDate.toFormat(pattern);
};

export const getDateTimeMessage = (
  timestamp: string,
  format = 'h:mm a | MMM dd, yyyy',
): string => {
  return getDateTimeFormat(timestamp, format, false);
};

export const getDateTimeUTCMessage = (
  timestamp: string,
  format = 'h:mm a | MMM dd, yyyy',
): string => {
  return getDateTimeFormat(timestamp, format, true);
};

export const getDateFormat = (dateStr: string, pattern: string): string => {
  const isoDate = DateTime.fromISO(dateStr);

  return isoDate.toFormat(pattern);
};
