import { Order } from '@config/api/task/interface';

interface BannerButtons {
  message: string;
  onPress: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
}

export enum BannerStatus {
  success = 'success',
  warning = 'warning',
}

export interface BannerProps {
  message: string;
  buttons: Array<BannerButtons>;
  status: keyof typeof BannerStatus;
  fee?: number;
  order?: Order;
}
