import RoomServiceIcon from '@mui/icons-material/RoomService';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { diffDateAndLabels } from '@utils/Dates';

export const OrderHubTimer = ({ date }: { date: Date }) => {
  const [formattedLabel, setFormattedLabel] = useState<string>('');
  const [status, setStatus] = useState<string>('');

  const handleCheckChangeDate = () => {
    const { formattedLabel, status } = diffDateAndLabels(date, new Date());
    setFormattedLabel(formattedLabel);
    setStatus(status);
  };

  useEffect(() => {
    handleCheckChangeDate();
    const interval = setInterval(() => {
      handleCheckChangeDate();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  if (!formattedLabel) return null;

  return (
    <div
      className={classNames(
        'flex max-h-5 max-w-fit items-center justify-center rounded px-2 py-1',
        {
          'bg-[var(--bluon--light--red-3)]': status === 'danger',
          'bg-[var(--bluon--light--yellow-3)]': status === 'warning',
          'bg-[var(--bluon--light--green-2)]': status === 'green',
        },
      )}
    >
      <RoomServiceIcon
        style={{ width: '0.75rem' }}
        className={classNames('mr-[0.313rem]', {
          'text-bluon_dark_red': status === 'danger',
          'text-bluon_light_yellow': status === 'warning',
          'text-bluon_green': status === 'green',
        })}
      />
      <div
        className={classNames(
          'flex items-center justify-center text-[0.625rem] font-bold leading-[0.733rem] tracking-[0.042rem]',
          {
            'text-bluon_dark_red': status === 'danger',
            'text-bluon_light_yellow': status === 'warning',
            'text-bluon_green': status === 'green',
          },
        )}
      >
        {formattedLabel}
      </div>
    </div>
  );
};
