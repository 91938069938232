import { Star } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';

import { RatingBarProps } from '../interface';

import './index.scss';

const RatingBar = ({ label, percentage }: RatingBarProps) => {
  return (
    <div className='mb-2 flex w-full flex-row items-center justify-start'>
      <div className='mr-1 text-sm font-normal'>{label}</div>
      <Star
        className='text-[var(--bluon--ds--star--yellow)]'
        style={{ width: 16, height: 16 }}
      />
      <div className='flex w-[78%] flex-row items-center justify-center'>
        <LinearProgress
          variant='determinate'
          value={percentage}
          style={{
            height: 10,
            borderRadius: 5,
            backgroundColor: '#E5E5E5',
          }}
          className='bar-container ml-2 w-[100%]'
        />
      </div>
      <div className='ml-1 text-sm font-normal'>{`${percentage}%`}</div>
    </div>
  );
};

export default RatingBar;
