import * as Yup from 'yup';

import { ForgotPasswordFormKeys } from '../interfaces';

export const ForgotPasswordValidationSchema = Yup.object<
  Record<keyof ForgotPasswordFormKeys, Yup.AnySchema>
>({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
});
