import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import { CustomDialogTitleProps } from './interface';

const CustomDialogTitle = (props: CustomDialogTitleProps): JSX.Element => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ mb: 1, p: 0 }} {...other}>
      {children}
      {onClose && (
        <IconButton
          aria-label='close'
          data-testid='closeIconButton'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 15,
            top: 16,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};

export default CustomDialogTitle;
