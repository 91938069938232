import { StarBorderOutlined as Star } from '@mui/icons-material';
import { Rating, Tooltip } from '@mui/material';

import { SupplierRatingProps } from './interface';
import SupplierRatingDetail from './SupplierRatingDetail';

const SupplierRating = ({ ratingProps, rating }: SupplierRatingProps) => {
  //  Rendering
  return (
    <Tooltip
      arrow
      placement='top-end'
      classes={{ tooltip: 'w-[25vw] min-w-[25vw]' }}
      title={<SupplierRatingDetail rating={rating} />}
    >
      <div className='mr-2 flex flex-row items-center justify-center'>
        <Rating
          emptyIcon={
            <Star
              fontSize='inherit'
              className='text-[var(--bluon--ds--star--yellow)]'
            />
          }
          precision={0.1}
          value={rating?.score || 0}
          readOnly
          {...ratingProps}
        />
      </div>
    </Tooltip>
  );
};

export default SupplierRating;
