import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import './styles.scss';

import SkeletonCell from '../Skeleton';
import { headCells } from '../TableHead';
import { HeadCell } from '../TableHead/interface';

import { TableRowProps } from './interface';

const SupplierTableRow = ({
  row,
  handleRowClick,
  isLoading,
}: TableRowProps) => {
  return (
    <TableRow
      hover
      onClick={() => handleRowClick(row)}
      tabIndex={-1}
      className='supplier-row !contents'
      key={row.name}
      sx={{ cursor: 'pointer' }}
    >
      {headCells.map((column: HeadCell) => {
        const isPendingTasks = column.id === 'pending_tasks';
        const showPendingTasks = isPendingTasks && row.pending_tasks > 0;

        if (isLoading) return <SkeletonCell id={column.id} align='left' />;

        if (isPendingTasks)
          return (
            <TableCell
              className='supplier-cell no-wrap overflow-x-hidden overflow-ellipsis'
              key={column.id}
              align={column.alignRight ? 'right' : 'left'}
            >
              {showPendingTasks && (
                <div className='float-right flex h-6 w-6 items-center justify-center rounded-full bg-[var(--bluon--ds--lightred)] text-[var(--bluon--ds--white)]'>
                  <div>{row.pending_tasks > 9 ? '9+' : row.pending_tasks}</div>
                </div>
              )}
            </TableCell>
          );

        return (
          <TableCell
            className='supplier-cell no-wrap overflow-x-hidden overflow-ellipsis'
            key={column.id}
            align={column.alignRight ? 'right' : 'left'}
          >
            {row[column.id]}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default SupplierTableRow;
