import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import { PageMessageProps } from './interface';

import './styles.scss';

export const PAGE_MESSAGE_TEST_ID = 'pageMessage';

export const PageMessage = (props: PageMessageProps): JSX.Element => {
  const { icon, title, description } = props;

  return (
    <div data-testid={PAGE_MESSAGE_TEST_ID} className='content'>
      <div>{icon}</div>
      <Grid container display='flex' flexDirection='column' alignItems='center'>
        <Grid item xs>
          <Typography variant='h5'>{title}</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h6'>{description}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};
