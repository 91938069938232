import { Typography } from '@mui/material';

import { EmptyStateProps } from './interface';

const EmptyState = ({ searchCriteria }: EmptyStateProps) => {
  return (
    <section className='empty-state-container m-auto flex flex-col items-center justify-center'>
      <Typography
        className='!font-normal italic text-[var(--bluon--brand--blue)]'
        variant='h6'
      >
        We&lsquo;re sorry, but there are no matches for:
      </Typography>
      <Typography
        className='!font-normal italic text-[var(--bluon--brand--blue)]'
        variant='h3'
      >
        ‘{searchCriteria}’
      </Typography>
    </section>
  );
};

export default EmptyState;
