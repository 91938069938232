import React from 'react';

import { OrderDetailTypes } from '../../../constants';

import { StatusLabelProps } from './interface';

import OrderStatusLabel from '@components/OrderStatusLabel';

const StatusLabel = ({ orderDetailType, order }: StatusLabelProps) => {
  const renderPendingLabel = () => {
    return (
      <div className='flex max-w-[60%] items-center rounded-md border bg-[var(--bluon--light--gray-22)] px-2 text-xs'>
        <span className='overflow-hidden truncate'>
          {order?.working_on_it?.name} is working on it
        </span>
      </div>
    );
  };

  const renderPendingApprovalOrApprovedLabel = () => {
    return (
      <OrderStatusLabel
        order={order}
        extraClassName='font-normal text-xs leading-3 justify-start ml-0'
        noLeftMargin
      />
    );
  };

  return orderDetailType === OrderDetailTypes.PENDING
    ? renderPendingLabel()
    : renderPendingApprovalOrApprovedLabel();
};

export default StatusLabel;
