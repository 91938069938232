import Pubnub from 'pubnub';

import { ChannelSelectedState } from '@state/chat/interface';

export const saveLastReadTimeStamp = (
  pubnub: Pubnub,
  channel: ChannelSelectedState,
) => {
  if (!channel) return;

  const seconds = +new Date() * 10000;

  return pubnub.objects.setChannelMetadata({
    channel: channel?.chat.channel,
    data: {
      name: channel.name as string,
      custom: {
        lastReadTimetoken: seconds,
        techLastReadTimetoken: channel.chat.techLastReadTimetoken,
      },
    },
  });
};
export const getAllChannelMetadata = async (
  pubnub: Pubnub,
  include: { customFields: boolean },
  filter: string,
): Promise<{ status: unknown; response: any }> => {
  return new Promise((resolve, reject) => {
    try {
      pubnub.objects.getAllChannelMetadata(
        {
          include: include,
          filter: filter,
        },
        (_status, response) => {
          resolve({ status: _status, response });
        },
      );
    } catch (error) {
      reject(error);
    }
  });
};
export const getMessageCounts = async (
  pubnub: Pubnub,
  channelsIds: Array<string>,
  channelTimetokens: Array<number>,
): Promise<{ [channel: string]: number }> => {
  return new Promise((resolve, reject) => {
    try {
      pubnub.messageCounts(
        {
          channels: channelsIds,
          channelTimetokens: channelTimetokens,
        },
        (_status, results) => {
          const channels = results?.channels;
          resolve(channels);
        },
      );
    } catch (error) {
      reject(error);
    }
  });
};
