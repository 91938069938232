class Account {
  static shared;
  constructor() {
    if (Account.shared) return Account.shared;

    Account.shared = this;
  }

  register() {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // eslint-disable-next-line no-undef
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-2YKYTLQN96');
  }
}

const Analytics = {
  account: new Account(),
};

export default Analytics;
