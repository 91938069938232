import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Box, Typography } from '@mui/material';

import CancelIcon from '@assets/icons/canceled-icon.svg';
import { MessageTypes } from '@utils/enums';
import { getDateTimeMessage } from '@utils/Strings/dateFormat';

export const QuoteStatusMessage = ({
  bidNumber,
  poNumber,
  type,
  timetoken,
  text,
}: {
  bidNumber?: string;
  poNumber?: string;
  type: MessageTypes.OrderCanceled | MessageTypes.ApprovedOrder;
  timetoken: string;
  text: string;
}): JSX.Element => (
  <div className='chat-item'>
    <Box className='time-message'>
      {getDateTimeMessage(timetoken, 'h:mm a')}
    </Box>
    <Box className='flex flex-col rounded-lg bg-bluon_light_gray p-3.5'>
      <Box className='mb-2 flex'>
        {type === MessageTypes.ApprovedOrder ? (
          <CheckCircleOutlineOutlinedIcon />
        ) : (
          <Box className='mr-4'>
            <img src={CancelIcon} alt='Logo' className='h-5 w-5' />
          </Box>
        )}
        <Box>
          <Typography className='mb-1 text-base font-normal leading-5 tracking-tighter'>
            {text}
          </Typography>
          {type === MessageTypes.ApprovedOrder && (
            <Typography className='break-words text-base font-bold leading-5 tracking-tighter'>
              {poNumber ? `PO #: ${poNumber}` : ''}
              {poNumber && bidNumber ? ', ' : ''}
              {bidNumber ? `Bid #: ${bidNumber}` : ''}
            </Typography>
          )}
          {type === MessageTypes.OrderCanceled && bidNumber && (
            <Typography className='break-words text-base font-bold leading-5 tracking-tighter'>
              Bid #: {bidNumber || ''}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  </div>
);
