import { TableCell } from '@mui/material';
import React from 'react';

import { CellProps } from '../LogsTable/interface';

import OrderStatusLabel from '@components/OrderStatusLabel';

const LogStatusCell = ({ log, ...restOfProps }: CellProps) => {
  return (
    <TableCell {...restOfProps}>
      <OrderStatusLabel order={log} isLogScreen />
    </TableCell>
  );
};

export default LogStatusCell;
