import { Grid, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';

import './styles.scss';

export const Help = (): JSX.Element => {
  return (
    <>
      <AppBar
        className='bl-help-nav'
        color='inherit'
        position='static'
        elevation={0}
      >
        <Typography className='bl-help-nav-title' variant='h4'>
          Video Tutorials & FAQ
        </Typography>
      </AppBar>

      <Grid container className='bl-help-page-container'>
        <div className='bl-help-iframe-container'>
          <iframe
            className='bl-help-iframe'
            src='https://bluon.com/live-faq'
            allow='fullscreen;'
          />
        </div>
      </Grid>
    </>
  );
};
