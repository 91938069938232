/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  END_REQUEST,
  END_REQUEST_ERROR,
  RESET_REQUEST,
  START_REQUEST,
} from './actions';
import { RequestActionsProps, RequestStateProps } from './interface';

const initialState = {};

const requestReducer = (
  state = initialState,
  action: RequestActionsProps,
): RequestStateProps => {
  switch (action.type) {
    case START_REQUEST: {
      return {
        ...state,
        [action.payload.id]: {
          running: true,
          error: '',
          success: '',
          requestId: action.payload.requestId,
        },
      };
    }
    case END_REQUEST: {
      return {
        ...state,
        [action.payload.id]: {
          running: false,
          error: '',
          success: action.payload.successMessage || 'Success',
          requestId: action.payload.requestId,
        },
      };
    }
    case END_REQUEST_ERROR: {
      return {
        ...state,
        [action.payload.id]: {
          running: false,
          error: action.payload.error,
          success: '',
          requestId: action.payload.requestId,
        },
      };
    }
    case RESET_REQUEST: {
      return initialState;
    }
    default:
      return state;
  }
};

export default requestReducer;
