import { ArrowForward, FilePresent } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import React, { useCallback } from 'react';

import { OrderProps } from '../../interface';

import { getInvoiceFileName, getInvoiceFileType } from '@utils/Orders';

const InvoicePreview = ({ order, isLoading }: OrderProps) => {
  const onInvoiceClicked = useCallback(() => {
    if (!order?.invoice?.url) return;
    window.open(order?.invoice?.url, '_blank');
  }, [order?.invoice?.url]);

  const renderInvoiceCard = () => {
    if (isLoading) return <Skeleton width={300} height={100} />;

    const name = getInvoiceFileName(order?.invoice?.url);
    const type = getInvoiceFileType(order?.invoice?.url);

    return (
      <div
        className='flex w-full min-w-[12%] cursor-pointer flex-row items-center justify-center rounded bg-[var(--bluon--ds--gray--bg)] px-2 py-4 md:w-auto '
        onClick={onInvoiceClicked}
      >
        <div className='flex flex-col'>
          <FilePresent className='h-5 w-4' color='primary' />
        </div>
        <div className='ml-2 flex min-w-[50%] flex-col justify-center'>
          <div className='flex flex-col text-base font-medium text-[var(--bluon--ds--darkblue-01)]'>
            {name}
          </div>
          <div className='mt-1 flex flex-col text-xs font-normal leading-3 text-bluon_dark_gray'>
            {`${type} Document`}
          </div>
        </div>
        <div className='ml-auto flex flex-col'>
          <ArrowForward className='h-4 w-4 fill-[var(--bluon--ds--gray--3)] text-[var(--bluon--ds--gray--3)]' />
        </div>
      </div>
    );
  };

  if (!order?.invoice) return null;

  //  Rendering
  return (
    <div className='mb-6 flex w-full flex-col items-center justify-center px-4'>
      <div className='w-full text-xl font-medium leading-8 text-black md:w-auto'>
        Quote / Invoice
      </div>
      {renderInvoiceCard()}
    </div>
  );
};

export default InvoicePreview;
