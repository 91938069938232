import { onValue, ref } from 'firebase/database';
import { useEffect } from 'react';

import { StoreInfoDataModel } from '@config/api/storeInfo/interface';
import { database, databaseNode, databaseNodeOrders } from '@config/firebase';

const useRealTimeOrderCount = (
  storeInfo: StoreInfoDataModel | undefined,
  onValueReceived: (value: any) => void,
) => {
  useEffect(() => {
    if (storeInfo) {
      if (databaseNode) {
        const countersRef = ref(database, databaseNode + storeInfo.id);
        onValue(countersRef, (snapshot) => {
          onValueReceived(snapshot.val());
        });
      }

      if (databaseNodeOrders) {
        const countersRef = ref(database, databaseNodeOrders + storeInfo.id);
        onValue(countersRef, (snapshot) => {
          onValueReceived(snapshot.val());
        });
      }
    }
  }, [storeInfo]);
};

export default useRealTimeOrderCount;
