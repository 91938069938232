/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, takeLatest } from 'redux-saga/effects';
import * as Eff from 'redux-saga/effects';

import makeRequest from '../requests/make-request';

import {
  validationSettingsRequest,
  validationSettingsResponse,
} from './actions';
import { ValidationSettingsActionProps } from './interface';

import { getValidationSettings } from '@config/api/validationSettings';

export function* workerGetValidationSettings(
  action: ValidationSettingsActionProps,
): Generator<any> {
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: getValidationSettings,
    requestAction: action,
    receiveAction: validationSettingsResponse,
  });
}

export default function* validationSettingsSagas(): Generator<any> {
  yield all([
    takeLatest(validationSettingsRequest, workerGetValidationSettings),
  ]);
}
