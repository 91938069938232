import { isEmpty } from 'lodash';
import { all, call, select, takeLatest } from 'redux-saga/effects';

import {
  approveShareableOrderRequest,
  approveShareableOrderResponse,
  approveUpdatedShipmentOrderRequest,
  approveUpdatedShipmentOrderResponse,
  declineShareableOrder,
  declineShareableOrderResponse,
  getShareableOrderAddedItemsRequest,
  getShareableOrderAddedItemsResponse,
  getShareableOrderCustomItemsRequest,
  getShareableOrderCustomItemsResponse,
  getShareableOrderExtraItemsRequest,
  getShareableOrderExtraItemsResponse,
  getShareableOrderPartsRequest,
  getShareableOrderPartsResponse,
  getShareableOrderRequest,
  getShareableOrderResponse,
} from './actions';
import {
  CancelShareableOrderAction,
  ExtraItemsPayload,
  OrderIdPayload,
  OrderWithCallbacksPayload,
  ShareableOrderAction,
} from './interface';

import {
  approveShareableOrder,
  approveUpdatedShipmentOrder,
  cancelShareableOrder,
  getShareableOrder,
  getShareableOrderCustomItems,
  getShareableOrderExtraItems,
  getShareableOrderParts,
} from '@config/api/shareableOrder';
import makeRequest from '@state/requests/make-request';
import { getRequestError } from '@state/requests/selectors';

export function* workerGetShareableOrderRequest(
  action: ShareableOrderAction,
): Generator<unknown> {
  const customCall: any = call;
  const orderId = (action?.payload as OrderIdPayload).orderId;

  yield customCall(makeRequest, {
    endpoint: getShareableOrder,
    params: orderId,
    requestAction: action,
    receiveAction: getShareableOrderResponse,
  });
}

export function* workerGetShareableOrderPartsRequest(
  action: ShareableOrderAction,
): Generator<unknown> {
  const customCall: any = call;
  const orderId = (action?.payload as OrderIdPayload).orderId;

  yield customCall(makeRequest, {
    endpoint: getShareableOrderParts,
    params: orderId,
    requestAction: action,
    receiveAction: getShareableOrderPartsResponse,
  });
}

export function* workerGetShareableOrderExtraItemsRequest(
  action: ShareableOrderAction,
): Generator<unknown> {
  const customCall: any = call;
  const orderId = (action?.payload as ExtraItemsPayload).orderId;
  const initial_request = (action?.payload as ExtraItemsPayload)
    .initial_request;

  yield customCall(makeRequest, {
    endpoint: getShareableOrderExtraItems,
    params: { orderId, initial_request },
    requestAction: action,
    receiveAction: initial_request
      ? getShareableOrderExtraItemsResponse
      : getShareableOrderAddedItemsResponse,
  });
}

export function* workerGetShareableOrderCustomItemsRequest(
  action: ShareableOrderAction,
): Generator<unknown> {
  const customCall: any = call;
  const orderId = (action?.payload as OrderIdPayload).orderId;

  yield customCall(makeRequest, {
    endpoint: getShareableOrderCustomItems,
    params: orderId,
    requestAction: action,
    receiveAction: getShareableOrderCustomItemsResponse,
  });
}

export function* workerDeclineShareableOrderCustomItemsRequest(
  action: CancelShareableOrderAction,
): Generator<unknown> {
  const customCall: any = call;
  const { orderId, reason, detail, successCallback, errorCallback } =
    action.payload;

  yield customCall(makeRequest, {
    endpoint: cancelShareableOrder,
    params: { orderId, reason, detail },
    requestAction: action,
    receiveAction: declineShareableOrderResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, declineShareableOrder),
  );
  if (isEmpty(error)) yield call(successCallback);
  else yield call(errorCallback);
}

export function* workerApproveShareableOrderRequest(
  action: ShareableOrderAction,
): Generator<unknown> {
  const customCall: any = call;
  const { orderId, errorCallback, successCallback } = action?.payload as {
    orderId: string;
    errorCallback: () => void;
    successCallback: () => void;
  };

  yield customCall(makeRequest, {
    endpoint: approveShareableOrder,
    params: orderId,
    requestAction: action,
    receiveAction: approveShareableOrderResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, approveShareableOrderRequest),
  );

  return error ? yield call(errorCallback) : yield call(successCallback);
}

export function* workerApproveUpdatedShipmentOrderRequest(
  action: ShareableOrderAction,
): Generator<unknown> {
  const customCall: any = call;
  const payload = action?.payload as OrderWithCallbacksPayload;

  //  Calling API
  yield customCall(makeRequest, {
    endpoint: approveUpdatedShipmentOrder,
    params: payload?.orderId,
    requestAction: action,
    receiveAction: approveUpdatedShipmentOrderResponse,
  });

  //  Handling error state
  const error = yield select((state) =>
    getRequestError(state, approveUpdatedShipmentOrderRequest),
  );
  if (error && payload?.onError && payload?.onError instanceof Function)
    return payload?.onError();

  //  Handling success state
  if (payload?.onSuccess && payload?.onSuccess instanceof Function)
    payload?.onSuccess();
}

export default function* shareableOrderSagas(): Generator<unknown> {
  yield all([
    takeLatest(getShareableOrderRequest, workerGetShareableOrderRequest),
    takeLatest(
      getShareableOrderPartsRequest,
      workerGetShareableOrderPartsRequest,
    ),
    takeLatest(
      getShareableOrderExtraItemsRequest,
      workerGetShareableOrderExtraItemsRequest,
    ),
    takeLatest(
      getShareableOrderAddedItemsRequest,
      workerGetShareableOrderExtraItemsRequest,
    ),
    takeLatest(
      getShareableOrderCustomItemsRequest,
      workerGetShareableOrderCustomItemsRequest,
    ),
    takeLatest(
      declineShareableOrder,
      workerDeclineShareableOrderCustomItemsRequest,
    ),
    takeLatest(
      approveShareableOrderRequest,
      workerApproveShareableOrderRequest,
    ),
    takeLatest(
      approveUpdatedShipmentOrderRequest,
      workerApproveUpdatedShipmentOrderRequest,
    ),
  ]);
}
