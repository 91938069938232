import { AvailabilitiesInterface, AvailabilityType } from './interfaces';

import StoreIcon from '@assets/icons/store.svg';
import CurriStandardCarIcon from '@assets/images/curri-standard-car.png';
import CurriTruckIcon from '@assets/images/curri_truck.png';
import DeliveryIcon from '@assets/images/delivery_van.png';
import ShipmentIcon from '@assets/images/shipment.png';

export const LOGISTIC_OPTIONS = {
  CURRI: 'curri_delivery',
  WAREHOUSE_DELIVERY: 'warehouse_delivery',
  OTHER_DELIVERY: 'other_delivery',
  SHIPMENT: 'shipment_delivery',
  CURRI_TRUCK: 'curri_delivery_truck',
  PICKUP: 'pickup',
};

export const AVAILABILITIES: Array<AvailabilitiesInterface> = [
  {
    label: 'Curri Delivery (Standard)',
    vehicle: 'car',
    value: 'curri_delivery',
    type: AvailabilityType.Delivery,
    iconStart: (
      <img
        className='logistic-option-img'
        alt='DeliveryIcon'
        src={CurriStandardCarIcon}
      />
    ),
  },
  {
    label: 'Curri Delivery (Rack Truck)',
    value: 'curri_delivery_truck',
    vehicle: 'truck-with-pipe-rack',
    type: AvailabilityType.Delivery,
    iconStart: (
      <img
        className='logistic-option-img'
        alt='DeliveryIcon'
        src={CurriTruckIcon}
      />
    ),
  },
  {
    label: 'Warehouse Delivery',
    value: 'warehouse_delivery',
    type: AvailabilityType.Delivery,
    iconStart: (
      <img
        className='logistic-option-img'
        alt='DeliveryIcon'
        src={DeliveryIcon}
      />
    ),
  },
  {
    label: 'Other Delivery',
    value: 'other_delivery',
    type: AvailabilityType.Delivery,
    iconStart: (
      <img
        className='logistic-option-img'
        alt='DeliveryIcon'
        src={DeliveryIcon}
      />
    ),
  },
  {
    label: 'In Store Pickup',
    value: 'pickup',
    type: AvailabilityType.InStorePickUp,
    iconStart: (
      <img className='logistic-option-img' alt='PickupIcon' src={StoreIcon} />
    ),
  },
  {
    label: 'Shipment',
    value: 'shipment_delivery',
    type: AvailabilityType.Shipment,
    iconStart: (
      <img
        className='logistic-option-img'
        alt='DeliveryIcon'
        src={ShipmentIcon}
      />
    ),
  },
];
