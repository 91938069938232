type ErrorData = {
  response: {
    status: number;
    config: { url: string };
  };
};

export function log404ErrorsInTests(error: ErrorData): void {
  if (
    process.env.LOG_AXIOS_404_ERROR_IN_TEST &&
    error?.response?.status === 404
  ) {
    const BlueColor = '\x1b[34m';
    const ClearFormat = '\x1b[0m';
    const Bold = '\x1b[1m';
    const url = error?.response?.config?.url ?? '';
    console.log(
      '🛑 Found an error 404 in the test. If you have thrown it intentionally just ignore this log, otherwise check this:\n',
      `* the request to the url ${BlueColor}${url}${ClearFormat} has been mocked and the mock is being called before rendering the component.\n`,
      `* it is not missing any ${BlueColor}'/'${ClearFormat} char in the beggining of the URL.\n`,
      `${Bold}Common problems:${ClearFormat}\n`,
      `* to mock the url request ${BlueColor}axios.get('/route?page=1')${ClearFormat}, you need to mock like ${BlueColor}mock.onGet(bluonApi('/route?page=1'))${ClearFormat}\n`,
      `* to mock the url with params as object ${BlueColor}axios.get('/route', { params: page: 1 }))${ClearFormat}, you need to mock like ${BlueColor}mock.onGet(bluonApi('/route'), { params: { page: 1 } })${ClearFormat}\n`,
      `* to ignore any params passed as object like ${BlueColor}axios.get('/route', { params: page: 1 }))${ClearFormat}, you can mock not passing any params ${BlueColor}mock.onGet(bluonApi('/route'))${ClearFormat}\n`,
      'Check the docs https://github.com/ctimmerm/axios-mock-adapter#example for reference and to see some examples.\n\n',
    );
    console.error(error);
  }
}
