import { off, onValue, ref } from 'firebase/database';
import { useEffect } from 'react';

import { StoreInfoDataModel } from '@config/api/storeInfo/interface';
import { database, databaseNodeTaskUpdated } from '@config/firebase';

const useRealTimeOrderUpdated = (
  storeInfo: StoreInfoDataModel | undefined,
  onValueReceived: (value: boolean) => void,
) => {
  useEffect(() => {
    if (storeInfo && databaseNodeTaskUpdated) {
      const priceAndAvailabilityRef = ref(
        database,
        databaseNodeTaskUpdated + storeInfo.id + '/price_and_availability',
      );
      onValue(priceAndAvailabilityRef, (snapshot) => {
        if (snapshot.val()) onValueReceived(false);
      });

      const willCallAndApprovedRef = ref(
        database,
        databaseNodeTaskUpdated + storeInfo.id + '/will_call_and_approved',
      );
      onValue(willCallAndApprovedRef, (snapshot) => {
        if (snapshot.val()) onValueReceived(true);
      });

      return () => {
        off(priceAndAvailabilityRef);
        off(willCallAndApprovedRef);
      };
    }
  }, [storeInfo]);
};

export default useRealTimeOrderUpdated;
