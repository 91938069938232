import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Controller } from 'react-hook-form';

import ReasonsSkeleton from '../DeclineOrCancelModal/Skeleton';

import { RadioGroupReasonProps } from './interface';

const RadioGroupReasons = ({
  isLoading,
  reasons,
  formControl,
}: RadioGroupReasonProps) => {
  if (isLoading) return <ReasonsSkeleton />;

  if (!reasons || reasons?.length === 0) return null;

  return (
    <Controller
      name='selectedOption'
      control={formControl}
      render={({ field }) => (
        <>
          <RadioGroup
            row
            className='flex-col md:flex-row'
            defaultValue={0}
            value={field.value}
            name='selectedOption'
          >
            {reasons.map((reason) => (
              <FormControlLabel
                key={reason.name}
                id='selectedOption'
                name={field.name}
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={reason.id}
                control={<Radio />}
                label={reason.name}
                className='min-w-[18rem]'
              />
            ))}
          </RadioGroup>
        </>
      )}
    />
  );
};

export default RadioGroupReasons;
