import { Skeleton, TableCell } from '@mui/material';

import { SkeletonCellProps } from './interface';

const SkeletonCell = ({ id, align }: SkeletonCellProps) => {
  return (
    <TableCell key={id} align={align}>
      <div className='flex items-center justify-start gap-3'>
        <Skeleton variant='rounded' width='70%' height={12} className='my-1' />
      </div>
    </TableCell>
  );
};

export default SkeletonCell;
