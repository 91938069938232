// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const partsSpecificationsString: any = {
  example_image: 'Example Image',
  see_less: 'See Less',
  see_more: 'See More',
  compatible_parts: 'Compatible parts',
  compatible_parts_header: '%{count} compatible parts',
  compatible_parts_modal_text:
    'See how many alternative, compatible parts exist out there. Soon, you’ll be able to send this info to your local supplier!',
  tip_box_text:
    'Order parts right from the Bluon App, directly from YOUR local supply house. Get ready for a whole new world! No more wrong parts. LFG!',
  parts_specs_title: 'Part Specifications',
  got_it: 'Got It',
  air_filter: {
    type_title: 'Air Filter',
    media_type: 'Media Type',
    merv_rating: 'Merv Rating',
    nominal_width: 'Nominal Width',
    nominal_length: 'Nominal Length',
    nominal_depth: 'Nominal Depth',
    actual_width: 'Actual Width',
    actual_length: 'Actual Length',
    actual_depth: 'Actual Depth',
    efficiency: 'Efficiency',
    max_operating_temp: 'Max Operating Temp',
  },
  belt: {
    type_title: 'Belt',
    family: 'Family',
    belt_type: 'Belt Type',
    belt_length: 'Belt Length',
    pitch: 'Pitch',
    thickness: 'Thickness',
    top_width: 'Top Width',
  },
  capacitor: {
    type_title: 'Capacitor',
    microfarads: 'Microfarads',
    voltage: 'Voltage',
    shape: 'Shape',
    tolerance: 'Tolerance',
    operating_temperature: 'Operating Temp',
    depth: 'Depth',
    height: 'Height',
    width: 'Width',
  },
  compressor: {
    type_title: 'Compressor',
    rated_refrigerant: 'Rated Refrigerant',
    oil_type: 'Oil Type',
    nominal_capacity_tons: 'Capacity (Tons)',
    nominal_capacity_btuh: 'Capacity (BTUH)',
    voltage: 'Voltage',
    ph: 'Ph',
    hz: 'Hz',
    run_capacitor: 'Run Capacitor',
    start_capacitor: 'Start Capacitor',
    connection_type: 'Connection Type',
    suction_inlet_diameter: 'Suct Inlet Diam',
    discharge_diameter: 'Discharge Diameter',
    number_of_cylinders: 'Cylinders Number',
    number_of_unloaders: 'Number Unloaders',
    crankcase_heater: 'Crankcase Heater',
    protection: 'Protection',
    speed: 'Speed',
    eer: 'Eer',
    displacement: 'Displacement',
    nominal_hp: 'Nominal HP',
    nominal_power_watts: 'Nom Power Watts',
    fla: 'FLA',
    lra: 'LRA',
    rpm: 'RPM',
    compressor_length: 'Compressor Length',
    compressor_width: 'Compressor Width',
    compressor_height: 'Compressor Height',
  },
  contactor: {
    type_title: 'Contactor',
    poles: 'Poles',
    shunts: 'Shunts',
    coil_voltage: 'Coil Voltage',
    operating_voltage: 'Operating Voltage',
    ph: 'Ph',
    hz: 'Hz',
    fla: 'FLA',
    lra: 'LRA',
    connection_type: 'Connection Type',
    termination_type: 'Termination Type',
    resistive_amps: 'Resistive Amps',
    noninductive_amps: 'Noninductive Amps',
    auxialliary_contacts: 'Aux Contacts',
    push_to_test_window: 'Push Test Window',
    contactor_type: 'Contactor Type',
    height: 'Height',
    width: 'Width',
    length: 'Length',
    coil_type: 'Coil Type',
    nema_size: 'Nema Size',
    max_hp: 'Max HP',
    fuse_clip_size: 'Fuse Clip Size',
    enclosure_type: 'Enclosure Type',
    temperature_rating: 'Temperature Rating',
    current_setting_range: 'Current Set Range',
    reset_type: 'Reset Type',
    accessories: 'Accessories',
    overload_relays: 'Overload Relays',
    overload_time: 'Overload Time',
  },
  control_board: {
    type_title: 'Control Board',
    fused: 'Fused',
  },
  crankcase_heater: {
    type_title: 'Crankcase Heater',
    watts_power: 'Watts Power',
    voltage: 'Voltage',
    shape: 'Shape',
    min_dimension: 'Min Dimension',
    max_dimension: 'Max Dimension',
    probe_length: 'Probe Length',
    probe_diameter: 'Probe Diameter',
    lead_length: 'Lead Length',
  },
  fan_blade: {
    type_title: 'Fan Blade',
    diameter: 'Diameter',
    number_of_blades: 'Number of Blades',
    pitch: 'Pitch',
    bore: 'Bore',
    rotation: 'Rotation',
    rpm: 'RPM',
    cfm: 'CFM',
    bhp: 'BHP',
    material: 'Material',
  },
  filter_drier_and_core: {
    type_title: 'Filter Drier and Core',
    volume: 'Volume',
    inlet_diameter: 'Inlet Diameter',
    inlet_connection_type: 'Inlet Con Type',
    outlet_diameter: 'Outlet Diameter',
    outlet_connection_type: 'Outlet Con Type',
    direction_of_flow: 'Direction of Flow',
    desiccant_type: 'Dessiccant Type',
    number_of_cores: 'Number of Cores',
    options: 'Options',
    rated_capacity: 'Rated Capacity',
  },
  gas_valve: {
    type_title: 'Gas Valve',
    type_of_gas: 'Type of Gas',
    stages: 'Stages',
    capacity: 'Capacity',
    outlet_orientation: 'Outlet Orientation',
    reducer_bushing: 'Reducer Bushing',
    inlet_size: 'Inlet Size',
    outlet_size_type: 'Outlet Size Type',
    pilot_outlet_size: 'Pilot Outlet Size',
    factory_settings: 'Factory Settings',
    max_inlet_pressure: 'Max Inlet Pressure',
    min_adjustable_setting: 'Min Adj Setting',
    max_adjustable_setting: 'Max Adj Setting',
    terminal_type: 'Terminal Type',
    electrical_rating: 'Electrical Rating',
    side_outlet_size_type: 'Side Outlet',
    gas_cock_dial_markings: 'Gas Cock Dial Mrk',
    ambient_temperature: 'Ambient Temp',
    amp_rating: 'AMP Rating',
    capillary_length: 'Capillary Length',
    standard_dial: 'Standard Dial',
    remote_dial: 'Remote Dial',
    temperature_range: 'Temperature Range',
    height: 'Height',
    length: 'Length',
    width: 'Width',
  },
  hard_start_kit: {
    type_title: 'Hard Start Kit',
    operating_voltage: 'Operating Voltage',
    max_hp: 'Max HP',
    min_hp: 'Min HP',
    max_tons: 'Max Tons',
    min_tons: 'Min Tons',
    max_capacitance: 'Max Capacitance',
    min_capacitance: 'Min Capacitance',
    tolerance: 'Tolerance',
    torque_increase: 'Torque Increase',
  },
  igniter: {
    type_title: 'Igniter',
    application: 'Application',
    gas_type: 'Gas Type',
    voltage: 'Voltage',
    terminal_type: 'Terminal Type',
    mounting: 'Mounting',
    tip_style: 'Tip Style',
    ceramic_block: 'Ceramic Block',
    pilot_btu: 'Pilot BTU',
    orifice_diameter: 'Orifice Diameter',
    pilot_tube_length: 'Pilot Tube Length',
    lead_length: 'Lead Length',
    sensor_type: 'Sensor Type',
    steady_current: 'Steady Current',
    temp_rating: 'Temp Rating',
    time_to_temp: 'Time to Temp',
    amperage: 'Amperage',
    cold_resistance: 'Cold Resistance',
    max_current: 'Max Current',
    compression_fitting_diameter: 'Comp Fit Diam',
    probe_length: 'Probe Length',
    rod_angle: 'Rod Angle',
    length: 'Length',
    height: 'Height',
    width: 'Width',
  },
  metering_device: {
    type_title: 'Metering Device',
    rated_refrigerant: 'Rated Refrigerant',
    nominal_capacity: 'Nominal Capacity',
    inlet_diameter: 'Inlet Diameter',
    inlet_connection_type: 'Inlet Con Type',
    outlet_diameter: 'Outlet Diameter',
    outlet_connection_type: 'Outlet Con Type',
    equalizer: 'Equalizer',
    external_equalizer_connection: 'Ext Equalizer Con',
    bidirectional: 'Bidirectional',
    adjustable: 'Adjustable',
    configuration: 'Configuration',
    supply_voltage: 'Supply Voltage',
    motor_type: 'Motor Type',
    control_steps: 'Control Steps',
    step_rate: 'Step Rate',
    orfice_size: 'Orifice Size',
    capillary_tube_length: 'Cap Tube Length',
    number_of_headers: 'Number of Headers',
    spring_type: 'Spring Type',
    check_valve: 'Check Valve',
    hermetic: 'Hermetic',
    balanced_port: 'Balanced Port',
    applications: 'Applications',
    element_size: 'Element Size',
    body_type: 'Body Type',
    thermostatic_charge: 'Thermo Charge',
    mesh_strainer: 'Mesh Strainer',
    max_operating_pressures: 'Max Op Pressures',
    max_differential_pressure_drop: 'Max Diff Pr. Drop',
    ambient_temperature: 'Ambient Temp',
    refrigerant_temperature: 'Refrigerant Temp',
    current: 'Current',
    drive_frequency: 'Drive Frequency',
    phase_resistance: 'Phase Resistance',
    compatible_oils: 'Compatible Oils',
    cable_type: 'Cable Type',
    max_power_input: 'Max Power Input',
    step_angle: 'Step Angle',
    resolution: 'Resolution',
    connections: 'Connections',
    closing_steps: 'Closing Steps',
    minimum_steps: 'Minimum Steps',
    hold_current: 'Hold Current',
    percent_duty: 'Percent Duty',
    stroke: 'Stroke',
    max_internal_leakage: 'Max Int Leakage',
    max_external_leakage: 'Max Ext Leakage',
  },
  motor: {
    type_title: 'Motor',
    motor_type: 'Motor Type',
    duty_rating: 'Duty Rating',
    voltage: 'Voltage',
    ph: 'Ph',
    hz: 'Hz',
    run_capacitor: 'Run Capacitor',
    rpm: 'RPM',
    output_hp: 'Output HP',
    frame_type: 'Frame Type',
    rotation: 'Rotation',
    speed: 'Speed',
    shaft_diameter: 'Shaft Diameter',
    shaft_keyway: 'Shaft Keyway',
    bearing_type: 'Bearing Type',
    fla: 'FLA',
    mounting_type: 'Mounting Type',
    shaft_length: 'Shaft Length',
    replaceable_bearings: 'Repl. Bearings',
    motor_diameter: 'Motor Diameter',
    motor_height: 'Motor Height',
    enclosure_type: 'Enclosure Type',
    material_type: 'Material Type',
    weight: 'Weight',
    protection: 'Protection',
    rla: 'RLA',
    lra: 'LRA',
    service_factor: 'Service Factor',
    power_factor: 'Power Factor',
    cfm: 'CFM',
    capacitor_size: 'Capacitor Size',
    efficiency: 'Efficiency',
    alternate_part_numbers: 'Alt Part Numbers',
    output_watts: 'Output Watts',
    input_watts: 'Input Watts',
    ring_size: 'Ring Size',
    resilient_ring_dimension: 'Res Ring Dimens',
    armature_amps: 'Armature Amps',
    field_amps: 'Field Amps',
    service_factor_amps: 'Serv. Factor Amps',
    multi_voltage: 'Multi Voltage',
    rotation_orientation: 'Rotation Orient.',
    mounting_angle: 'Mounting Angle',
    shaft_type: 'Shaft Type',
    conduit_box_orientation: 'Conduit Box Orient',
    torque_type: 'Torque Type',
    drive_type: 'Drive Type',
    misc: 'Misc',
  },
  pressure_control: {
    type_title: 'Pressure Control',
    setpoint: 'Setpoint',
    reset: 'Reset',
    range_minimum: 'Range Minimum',
    range_maximum: 'Range Maximum',
    reset_minimum: 'Reset Minimum',
    reset_maximum: 'Reset Maximum',
    differential_minimum: 'Differential Min',
    differential_maximum: 'Differential Max',
    operation_of_contacts: 'Op Of Contacts',
    switch: 'Switch',
    action: 'Action',
    reset_type: 'Reset Type',
    connection_type: 'Connection Type',
    max_amps: 'Max Amps',
    max_volts: 'Max Volts',
  },
  relay_switch_timer_sequencer: {
    type_title: 'Relay Switch Timer Sequencer',
    poles: 'Poles',
    action: 'Action',
    coil_voltage: 'Coil Voltage',
    ph: 'Ph',
    hz: 'Hz',
    fla: 'FLA',
    operating_voltage: 'Operating Voltage',
    mounting_base: 'Mounting Base',
    terminal_type: 'Terminal Type',
    mounting_relay: 'Mounting Relay',
    delay_on_make: 'Delay on Make',
    delay_on_break: 'Delay on Break',
    adjustable: 'Adjustable',
    fused: 'Fused',
    throw_type: 'Throw Type',
    mounting_type: 'Mounting Type',
    base_type: 'Base Type',
    status_indicator: 'Status Indicator',
    options: 'Options',
    ac_contact_rating: 'AC Contact Rating',
    dc_contact_rating: 'DC Contact Rating',
    socket_code: 'Socket Code',
    number_of_pins: 'Number of Pins',
    max_switching_voltage: 'Max Switch Volts',
    min_switching_voltage: 'Min Switch Volts',
    service_life: 'Service Life',
    m1_m2_on_time: 'M1 M2 on Time',
    m1_m2_off_time: 'M1 M2 off Time',
    m3_m4_on_time: 'M3 M4 on Time',
    m3_m4_off_time: 'M3 M4 off Time',
    m5_m6_on_time: 'M5 M6 on Time',
    m5_m6_off_time: 'M5 M6 off Time',
    m7_m8_on_time: 'M7 M8 on Time',
    m7_m8_off_time: 'M7 M8 off Time',
    m9_m10_on_time: 'M9 M10 on Time',
    m9_m10_off_time: 'M9 M10 off Time',
    resistive_watts: 'Resistive Watts',
    lra: 'LRA',
    pilot_duty: 'Pilot Duty',
    ambient_temperature: 'Ambient Temp',
  },
  sensor: {
    type_title: 'Sensor',
    application: 'Application',
    signal_type: 'Signal Type',
    measurement_range: 'Measurement Range',
    connection_type: 'Connection Type',
    configuration: 'Configuration',
    number_of_wires: 'Number of Wires',
    accuracy: 'Accuracy',
    enclosure_rating: 'Enclosure Rating',
    lead_length: 'Lead Length',
    operating_temperature: 'Op Temperature',
  },
  sheave_and_pulley: {
    type_title: 'Sheave and Pulley',
    belt_type: 'Belt Type',
    number_of_grooves: 'Number of Grooves',
    bore_diameter: 'Bore Diameter',
    outside_diameter: 'Outside Diameter',
    adjustable: 'Adjustable',
    bore_mate_type: 'Bore Mate Type',
    bushing_connection: 'Bushing Connection',
    keyway_types: 'Keyway Types',
    keyway_height: 'Keyway Height',
    keyway_width: 'Keyway Width',
    minimum_dd: 'Minimum DD',
    maximum_dd: 'Maximum DD',
    material: 'Material',
  },
  temperature_control: {
    type_title: 'Temperature Control',
    programmable: 'Programmable',
    application: 'Application',
    wifi: 'Wifi',
    power_requirements: 'Power Requirements',
    operating_voltage: 'Operating Voltage',
    switch: 'Switch',
    action: 'Action',
    operation_of_contacts: 'Op of Contacts',
    adjustable: 'Adjustable',
    range_minimum: 'Range Minimum',
    range_maximum: 'Range Maximum',
    reset_minimum: 'Reset Minimum',
    reset_maximum: 'Reset Maximum',
    differential_minimum: 'Differential Min',
    differential_maximum: 'Differential Max',
    setpoint: 'Setpoint',
    reset: 'Reset',
    reset_type: 'Reset Type',
    capillary_length: 'Capillary Length',
    max_amps: 'Max Amps',
    max_volts: 'Max Volts',
    replaceable_bulb: 'Replaceable Bulb',
    mount: 'Mount',
  },
  wheel: {
    type_title: 'Wheel',
    diameter: 'Diameter',
    width: 'Width',
    bore: 'Bore',
    rotation: 'Rotation',
    max_rpm: 'Max RPM',
    material: 'Material',
    keyway: 'Keyway',
    center_disc: 'Center disc',
    number_hubs: 'Number of Hubs',
    hub_lock: 'Hub Lock',
    number_setscrews: 'Number Setscrews',
    number_blades: 'Number of Blades',
    wheel_type: 'Wheel Type',
    drive_type: 'Drive Type',
  },
  other: {
    type_title: 'Other',
    sort: 'Sort',
  },
};
