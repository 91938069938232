export const stripNonNumeric = (value: string | undefined | null): string => {
  return value ? value.replace(/\D/g, '') : '';
};

export const checkNumber = (number: number): number =>
  isFinite(Number(number)) ? Number(number) : 0;

export const numberWithCommas = (number: number, locales = 'en'): string => {
  // here the docs the see the possible locales and options
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
  const formattedNumber = Intl.NumberFormat(locales, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);

  return checkNumber(number) === 0 ? '0.00' : formattedNumber.toString();
};
