// In use
export const CountryCodeAndFlagsOptions = [
  {
    label: '🇺🇸 +1',
    value: 'US',
  },
  {
    label: '🇨🇦 +1',
    value: 'CA',
  },
  {
    label: '🇦🇺 +61',
    value: 'AU',
  },
  {
    label: '🇲🇽 +52',
    value: 'MX',
  },
  {
    label: '🇦🇷 +54',
    value: 'AR',
  },
  {
    label: '🇨🇴 +57',
    value: 'CO',
  },
];

// Not in use
// Kept here for referencing
export const CountryCodeAndFlags = [
  { code: 'AD', name: 'Andorra', phone: '376', emoji: '🇦🇩' },
  { code: 'AE', name: 'United Arab Emirates', phone: '971', emoji: '🇦🇪' },
  { code: 'AF', name: 'Afghanistan', phone: '93', emoji: '🇦🇫' },
  { code: 'AG', name: 'Antigua and Barbuda', phone: '1-268', emoji: '🇦🇬' },
  { code: 'AI', name: 'Anguilla', phone: '1-264', emoji: '🇦🇮' },
  { code: 'AL', name: 'Albania', phone: '355', emoji: '🇦🇱' },
  { code: 'AM', name: 'Armenia', phone: '374', emoji: '🇦🇲' },
  { code: 'AO', name: 'Angola', phone: '244', emoji: '🇦🇴' },
  { code: 'AQ', name: 'Antarctica', phone: '672', emoji: '🇦🇶' },
  { code: 'AR', name: 'Argentina', phone: '54', emoji: '🇦🇷' },
  { code: 'AS', name: 'American Samoa', phone: '1-684', emoji: '🇦🇸' },
  { code: 'AT', name: 'Austria', phone: '43', emoji: '🇦🇹' },
  { code: 'AU', name: 'Australia', phone: '61', suggested: true, emoji: '🇦🇺' },
  { code: 'AW', name: 'Aruba', phone: '297', emoji: '🇦🇼' },
  { code: 'AX', name: 'Alland Islands', phone: '358', emoji: '🇦🇽' },
  { code: 'AZ', name: 'Azerbaijan', phone: '994', emoji: '🇦🇿' },
  { code: 'BA', name: 'Bosnia and Herzegovina', phone: '387', emoji: '🇧🇦' },
  { code: 'BB', name: 'Barbados', phone: '1-246', emoji: '🇧🇧' },
  { code: 'BD', name: 'Bangladesh', phone: '880', emoji: '🇧🇩' },
  { code: 'BE', name: 'Belgium', phone: '32', emoji: '🇧🇪' },
  { code: 'BF', name: 'Burkina Faso', phone: '226', emoji: '🇧🇫' },
  { code: 'BG', name: 'Bulgaria', phone: '359', emoji: '🇧🇬' },
  { code: 'BH', name: 'Bahrain', phone: '973', emoji: '🇧🇭' },
  { code: 'BI', name: 'Burundi', phone: '257', emoji: '🇧🇮' },
  { code: 'BJ', name: 'Benin', phone: '229', emoji: '🇧🇯' },
  { code: 'BL', name: 'Saint Barthelemy', phone: '590', emoji: '🇧🇱' },
  { code: 'BM', name: 'Bermuda', phone: '1-441', emoji: '🇧🇲' },
  { code: 'BN', name: 'Brunei Darussalam', phone: '673', emoji: '🇧🇳' },
  { code: 'BO', name: 'Bolivia', phone: '591', emoji: '🇧🇴' },
  { code: 'BR', name: 'Brazil', phone: '55', emoji: '🇧🇷' },
  { code: 'BS', name: 'Bahamas', phone: '1-242', emoji: '🇧🇸' },
  { code: 'BT', name: 'Bhutan', phone: '975', emoji: '🇧🇹' },
  { code: 'BV', name: 'Bouvet Island', phone: '47', emoji: '🇧🇻' },
  { code: 'BW', name: 'Botswana', phone: '267', emoji: '🇧🇼' },
  { code: 'BY', name: 'Belarus', phone: '375', emoji: '🇧🇾' },
  { code: 'BZ', name: 'Belize', phone: '501', emoji: '🇧🇿' },
  { code: 'CA', name: 'Canada', phone: '1', suggested: true, emoji: '🇨🇦' },
  { code: 'CC', name: 'Cocos (Keeling) Islands', phone: '61', emoji: '🇨🇨' },
  {
    code: 'CD',
    name: 'Congo, Democratic Republic of the',
    phone: '243',
    emoji: '🇨🇩',
  },
  { code: 'CF', name: 'Central African Republic', phone: '236', emoji: '🇨🇫' },
  { code: 'CG', name: 'Congo, Republic of the', phone: '242', emoji: '🇨🇬' },
  { code: 'CH', name: 'Switzerland', phone: '41', emoji: '🇨🇭' },
  { code: 'CI', name: "Cote d'Ivoire", phone: '225', emoji: '🇨🇮' },
  { code: 'CK', name: 'Cook Islands', phone: '682', emoji: '🇨🇰' },
  { code: 'CL', name: 'Chile', phone: '56', emoji: '🇨🇱' },
  { code: 'CM', name: 'Cameroon', phone: '237', emoji: '🇨🇲' },
  { code: 'CN', name: 'China', phone: '86', emoji: '🇨🇳' },
  { code: 'CO', name: 'Colombia', phone: '57', emoji: '🇨🇴' },
  { code: 'CR', name: 'Costa Rica', phone: '506', emoji: '🇨🇷' },
  { code: 'CU', name: 'Cuba', phone: '53', emoji: '🇨🇺' },
  { code: 'CV', name: 'Cape Verde', phone: '238', emoji: '🇨🇻' },
  { code: 'CW', name: 'Curacao', phone: '599', emoji: '🇨🇼' },
  { code: 'CX', name: 'Christmas Island', phone: '61', emoji: '🇨🇽' },
  { code: 'CY', name: 'Cyprus', phone: '357', emoji: '🇨🇾' },
  { code: 'CZ', name: 'Czech Republic', phone: '420', emoji: '🇨🇿' },
  { code: 'DE', name: 'Germany', phone: '49', suggested: true, emoji: '🇩🇪' },
  { code: 'DJ', name: 'Djibouti', phone: '253', emoji: '🇩🇯' },
  { code: 'DK', name: 'Denmark', phone: '45', emoji: '🇩🇰' },
  { code: 'DM', name: 'Dominica', phone: '1-767', emoji: '🇩🇲' },
  { code: 'DO', name: 'Dominican Republic', phone: '1-809', emoji: '🇩🇴' },
  { code: 'DZ', name: 'Algeria', phone: '213', emoji: '🇩🇿' },
  { code: 'EC', name: 'Ecuador', phone: '593', emoji: '🇪🇨' },
  { code: 'EE', name: 'Estonia', phone: '372', emoji: '🇪🇪' },
  { code: 'EG', name: 'Egypt', phone: '20', emoji: '🇪🇬' },
  { code: 'EH', name: 'Western Sahara', phone: '212', emoji: '🇪🇭' },
  { code: 'ER', name: 'Eritrea', phone: '291', emoji: '🇪🇷' },
  { code: 'ES', name: 'Spain', phone: '34', emoji: '🇪🇸' },
  { code: 'ET', name: 'Ethiopia', phone: '251', emoji: '🇪🇹' },
  { code: 'FI', name: 'Finland', phone: '358', emoji: '🇫🇮' },
  { code: 'FJ', name: 'Fiji', phone: '679', emoji: '🇫🇯' },
  {
    code: 'FK',
    name: 'Falkland Islands (Malvinas)',
    phone: '500',
    emoji: '🇫🇰',
  },
  {
    code: 'FM',
    name: 'Micronesia, Federated States of',
    phone: '691',
    emoji: '🇫🇲',
  },
  { code: 'FO', name: 'Faroe Islands', phone: '298', emoji: '🇫🇴' },
  { code: 'FR', name: 'France', phone: '33', suggested: true, emoji: '🇫🇷' },
  { code: 'GA', name: 'Gabon', phone: '241', emoji: '🇬🇦' },
  { code: 'GB', name: 'United Kingdom', phone: '44', emoji: '🇬🇧' },
  { code: 'GD', name: 'Grenada', phone: '1-473', emoji: '🇬🇩' },
  { code: 'GE', name: 'Georgia', phone: '995', emoji: '🇬🇪' },
  { code: 'GF', name: 'French Guiana', phone: '594', emoji: '🇬🇫' },
  { code: 'GG', name: 'Guernsey', phone: '44', emoji: '🇬🇬' },
  { code: 'GH', name: 'Ghana', phone: '233', emoji: '🇬🇭' },
  { code: 'GI', name: 'Gibraltar', phone: '350', emoji: '🇬🇮' },
  { code: 'GL', name: 'Greenland', phone: '299', emoji: '🇬🇱' },
  { code: 'GM', name: 'Gambia', phone: '220', emoji: '🇬🇲' },
  { code: 'GN', name: 'Guinea', phone: '224', emoji: '🇬🇳' },
  { code: 'GP', name: 'Guadeloupe', phone: '590', emoji: '🇬🇵' },
  { code: 'GQ', name: 'Equatorial Guinea', phone: '240', emoji: '🇬🇶' },
  { code: 'GR', name: 'Greece', phone: '30', emoji: '🇬🇷' },
  {
    code: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
    phone: '500',
    emoji: '🇬🇸',
  },
  { code: 'GT', name: 'Guatemala', phone: '502', emoji: '🇬🇹' },
  { code: 'GU', name: 'Guam', phone: '1-671', emoji: '🇬🇺' },
  { code: 'GW', name: 'Guinea-Bissau', phone: '245', emoji: '🇬🇼' },
  { code: 'GY', name: 'Guyana', phone: '592', emoji: '🇬🇾' },
  { code: 'HK', name: 'Hong Kong', phone: '852', emoji: '🇭🇰' },
  {
    code: 'HM',
    name: 'Heard Island and McDonald Islands',
    phone: '672',
    emoji: '🇭🇲',
  },
  { code: 'HN', name: 'Honduras', phone: '504', emoji: '🇭🇳' },
  { code: 'HR', name: 'Croatia', phone: '385', emoji: '🇭🇷' },
  { code: 'HT', name: 'Haiti', phone: '509', emoji: '🇭🇹' },
  { code: 'HU', name: 'Hungary', phone: '36', emoji: '🇭🇺' },
  { code: 'ID', name: 'Indonesia', phone: '62', emoji: '🇮🇩' },
  { code: 'IE', name: 'Ireland', phone: '353', emoji: '🇮🇪' },
  { code: 'IL', name: 'Israel', phone: '972', emoji: '🇮🇱' },
  { code: 'IM', name: 'Isle of Man', phone: '44', emoji: '🇮🇲' },
  { code: 'IN', name: 'India', phone: '91', emoji: '🇮🇳' },
  {
    code: 'IO',
    name: 'British Indian Ocean Territory',
    phone: '246',
    emoji: '🇮🇴',
  },
  { code: 'IQ', name: 'Iraq', phone: '964', emoji: '🇮🇶' },
  { code: 'IR', name: 'Iran, Islamic Republic of', phone: '98', emoji: '🇮🇷' },
  { code: 'IS', name: 'Iceland', phone: '354', emoji: '🇮🇸' },
  { code: 'IT', name: 'Italy', phone: '39', emoji: '🇮🇹' },
  { code: 'JE', name: 'Jersey', phone: '44', emoji: '🇯🇪' },
  { code: 'JM', name: 'Jamaica', phone: '1-876', emoji: '🇯🇲' },
  { code: 'JO', name: 'Jordan', phone: '962', emoji: '🇯🇴' },
  { code: 'JP', name: 'Japan', phone: '81', suggested: true, emoji: '🇯🇵' },
  { code: 'KE', name: 'Kenya', phone: '254', emoji: '🇰🇪' },
  { code: 'KG', name: 'Kyrgyzstan', phone: '996', emoji: '🇰🇬' },
  { code: 'KH', name: 'Cambodia', phone: '855', emoji: '🇰🇭' },
  { code: 'KI', name: 'Kiribati', phone: '686', emoji: '🇰🇮' },
  { code: 'KM', name: 'Comoros', phone: '269', emoji: '🇰🇲' },
  { code: 'KN', name: 'Saint Kitts and Nevis', phone: '1-869', emoji: '🇰🇳' },
  {
    code: 'KP',
    name: "Korea, Democratic People's Republic of",
    phone: '850',
    emoji: '🇰🇵',
  },
  { code: 'KR', name: 'Korea, Republic of', phone: '82', emoji: '🇰🇷' },
  { code: 'KW', name: 'Kuwait', phone: '965', emoji: '🇰🇼' },
  { code: 'KY', name: 'Cayman Islands', phone: '1-345', emoji: '🇰🇾' },
  { code: 'KZ', name: 'Kazakhstan', phone: '7', emoji: '🇰🇿' },
  {
    code: 'LA',
    name: "Lao People's Democratic Republic",
    phone: '856',
    emoji: '🇱🇦',
  },
  { code: 'LB', name: 'Lebanon', phone: '961', emoji: '🇱🇧' },
  { code: 'LC', name: 'Saint Lucia', phone: '1-758', emoji: '🇱🇨' },
  { code: 'LI', name: 'Liechtenstein', phone: '423', emoji: '🇱🇮' },
  { code: 'LK', name: 'Sri Lanka', phone: '94', emoji: '🇱🇰' },
  { code: 'LR', name: 'Liberia', phone: '231', emoji: '🇱🇷' },
  { code: 'LS', name: 'Lesotho', phone: '266', emoji: '🇱🇸' },
  { code: 'LT', name: 'Lithuania', phone: '370', emoji: '🇱🇹' },
  { code: 'LU', name: 'Luxembourg', phone: '352', emoji: '🇱🇺' },
  { code: 'LV', name: 'Latvia', phone: '371', emoji: '🇱🇻' },
  { code: 'LY', name: 'Libya', phone: '218', emoji: '🇱🇾' },
  { code: 'MA', name: 'Morocco', phone: '212', emoji: '🇲🇦' },
  { code: 'MC', name: 'Monaco', phone: '377', emoji: '🇲🇨' },
  { code: 'MD', name: 'Moldova, Republic of', phone: '373', emoji: '🇲🇩' },
  { code: 'ME', name: 'Montenegro', phone: '382', emoji: '🇲🇪' },
  {
    code: 'MF',
    name: 'Saint Martin (French part)',
    phone: '590',
    emoji: '🇲🇫',
  },
  { code: 'MG', name: 'Madagascar', phone: '261', emoji: '🇲🇬' },
  { code: 'MH', name: 'Marshall Islands', phone: '692', emoji: '🇲🇭' },
  {
    code: 'MK',
    name: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
    emoji: '🇲🇰',
  },
  { code: 'ML', name: 'Mali', phone: '223', emoji: '🇲🇱' },
  { code: 'MM', name: 'Myanmar', phone: '95', emoji: '🇲🇲' },
  { code: 'MN', name: 'Mongolia', phone: '976', emoji: '🇲🇳' },
  { code: 'MO', name: 'Macao', phone: '853', emoji: '🇲🇴' },
  {
    code: 'MP',
    name: 'Northern Mariana Islands',
    phone: '1-670',
    emoji: '🇲🇵',
  },
  { code: 'MQ', name: 'Martinique', phone: '596', emoji: '🇲🇶' },
  { code: 'MR', name: 'Mauritania', phone: '222', emoji: '🇲🇷' },
  { code: 'MS', name: 'Montserrat', phone: '1-664', emoji: '🇲🇸' },
  { code: 'MT', name: 'Malta', phone: '356', emoji: '🇲🇹' },
  { code: 'MU', name: 'Mauritius', phone: '230', emoji: '🇲🇺' },
  { code: 'MV', name: 'Maldives', phone: '960', emoji: '🇲🇻' },
  { code: 'MW', name: 'Malawi', phone: '265', emoji: '🇲🇼' },
  { code: 'MX', name: 'Mexico', phone: '52', emoji: '🇲🇽' },
  { code: 'MY', name: 'Malaysia', phone: '60', emoji: '🇲🇾' },
  { code: 'MZ', name: 'Mozambique', phone: '258', emoji: '🇲🇿' },
  { code: 'NA', name: 'Namibia', phone: '264', emoji: '🇳🇦' },
  { code: 'NC', name: 'New Caledonia', phone: '687', emoji: '🇳🇨' },
  { code: 'NE', name: 'Niger', phone: '227', emoji: '🇳🇪' },
  { code: 'NF', name: 'Norfolk Island', phone: '672', emoji: '🇳🇫' },
  { code: 'NG', name: 'Nigeria', phone: '234', emoji: '🇳🇬' },
  { code: 'NI', name: 'Nicaragua', phone: '505', emoji: '🇳🇮' },
  { code: 'NL', name: 'Netherlands', phone: '31', emoji: '🇳🇱' },
  { code: 'NO', name: 'Norway', phone: '47', emoji: '🇳🇴' },
  { code: 'NP', name: 'Nepal', phone: '977', emoji: '🇳🇵' },
  { code: 'NR', name: 'Nauru', phone: '674', emoji: '🇳🇷' },
  { code: 'NU', name: 'Niue', phone: '683', emoji: '🇳🇺' },
  { code: 'NZ', name: 'New Zealand', phone: '64', emoji: '🇳🇿' },
  { code: 'OM', name: 'Oman', phone: '968', emoji: '🇴🇲' },
  { code: 'PA', name: 'Panama', phone: '507', emoji: '🇵🇦' },
  { code: 'PE', name: 'Peru', phone: '51', emoji: '🇵🇪' },
  { code: 'PF', name: 'French Polynesia', phone: '689', emoji: '🇵🇫' },
  { code: 'PG', name: 'Papua New Guinea', phone: '675', emoji: '🇵🇬' },
  { code: 'PH', name: 'Philippines', phone: '63', emoji: '🇵🇭' },
  { code: 'PK', name: 'Pakistan', phone: '92', emoji: '🇵🇰' },
  { code: 'PL', name: 'Poland', phone: '48', emoji: '🇵🇱' },
  { code: 'PM', name: 'Saint Pierre and Miquelon', phone: '508', emoji: '🇵🇲' },
  { code: 'PN', name: 'Pitcairn', phone: '870', emoji: '🇵🇳' },
  { code: 'PR', name: 'Puerto Rico', phone: '1', emoji: '🇵🇷' },
  { code: 'PS', name: 'Palestine, State of', phone: '970', emoji: '🇵🇸' },
  { code: 'PT', name: 'Portugal', phone: '351', emoji: '🇵🇹' },
  { code: 'PW', name: 'Palau', phone: '680', emoji: '🇵🇼' },
  { code: 'PY', name: 'Paraguay', phone: '595', emoji: '🇵🇾' },
  { code: 'QA', name: 'Qatar', phone: '974', emoji: '🇶🇦' },
  { code: 'RE', name: 'Reunion', phone: '262', emoji: '🇷🇪' },
  { code: 'RO', name: 'Romania', phone: '40', emoji: '🇷🇴' },
  { code: 'RS', name: 'Serbia', phone: '381', emoji: '🇷🇸' },
  { code: 'RU', name: 'Russian Federation', phone: '7', emoji: '🇷🇺' },
  { code: 'RW', name: 'Rwanda', phone: '250', emoji: '🇷🇼' },
  { code: 'SA', name: 'Saudi Arabia', phone: '966', emoji: '🇸🇦' },
  { code: 'SB', name: 'Solomon Islands', phone: '677', emoji: '🇸🇧' },
  { code: 'SC', name: 'Seychelles', phone: '248', emoji: '🇸🇨' },
  { code: 'SD', name: 'Sudan', phone: '249', emoji: '🇸🇩' },
  { code: 'SE', name: 'Sweden', phone: '46', emoji: '🇸🇪' },
  { code: 'SG', name: 'Singapore', phone: '65', emoji: '🇸🇬' },
  { code: 'SH', name: 'Saint Helena', phone: '290', emoji: '🇸🇭' },
  { code: 'SI', name: 'Slovenia', phone: '386', emoji: '🇸🇮' },
  { code: 'SJ', name: 'Svalbard and Jan Mayen', phone: '47', emoji: '🇸🇯' },
  { code: 'SK', name: 'Slovakia', phone: '421', emoji: '🇸🇰' },
  { code: 'SL', name: 'Sierra Leone', phone: '232', emoji: '🇸🇱' },
  { code: 'SM', name: 'San Marino', phone: '378', emoji: '🇸🇲' },
  { code: 'SN', name: 'Senegal', phone: '221', emoji: '🇸🇳' },
  { code: 'SO', name: 'Somalia', phone: '252', emoji: '🇸🇴' },
  { code: 'SR', name: 'Suriname', phone: '597', emoji: '🇸🇷' },
  { code: 'SS', name: 'South Sudan', phone: '211', emoji: '🇸🇸' },
  { code: 'ST', name: 'Sao Tome and Principe', phone: '239', emoji: '🇸🇹' },
  { code: 'SV', name: 'El Salvador', phone: '503', emoji: '🇸🇻' },
  {
    code: 'SX',
    name: 'Sint Maarten (Dutch part)',
    phone: '1-721',
    emoji: '🇸🇽',
  },
  { code: 'SY', name: 'Syrian Arab Republic', phone: '963', emoji: '🇸🇾' },
  { code: 'SZ', name: 'Swaziland', phone: '268', emoji: '🇸🇿' },
  {
    code: 'TC',
    name: 'Turks and Caicos Islands',
    phone: '1-649',
    emoji: '🇹🇨',
  },
  { code: 'TD', name: 'Chad', phone: '235', emoji: '🇹🇩' },
  {
    code: 'TF',
    name: 'French Southern Territories',
    phone: '262',
    emoji: '🇹🇫',
  },
  { code: 'TG', name: 'Togo', phone: '228', emoji: '🇹🇬' },
  { code: 'TH', name: 'Thailand', phone: '66', emoji: '🇹🇭' },
  { code: 'TJ', name: 'Tajikistan', phone: '992', emoji: '🇹🇯' },
  { code: 'TK', name: 'Tokelau', phone: '690', emoji: '🇹🇰' },
  { code: 'TL', name: 'Timor-Leste', phone: '670', emoji: '🇹🇱' },
  { code: 'TM', name: 'Turkmenistan', phone: '993', emoji: '🇹🇲' },
  { code: 'TN', name: 'Tunisia', phone: '216', emoji: '🇹🇳' },
  { code: 'TO', name: 'Tonga', phone: '676', emoji: '🇹🇴' },
  { code: 'TR', name: 'Turkey', phone: '90', emoji: '🇹🇷' },
  { code: 'TT', name: 'Trinidad and Tobago', phone: '1-868', emoji: '🇹🇹' },
  { code: 'TV', name: 'Tuvalu', phone: '688', emoji: '🇹🇻' },
  { code: 'TW', name: 'Taiwan, Province of China', phone: '886', emoji: '🇹🇼' },
  {
    code: 'TZ',
    name: 'United Republic of Tanzania',
    phone: '255',
    emoji: '🇹🇿',
  },
  { code: 'UA', name: 'Ukraine', phone: '380', emoji: '🇺🇦' },
  { code: 'UG', name: 'Uganda', phone: '256', emoji: '🇺🇬' },
  {
    code: 'US',
    name: 'United States',
    phone: '1',
    suggested: true,
    emoji: '🇺🇸',
  },
  { code: 'UY', name: 'Uruguay', phone: '598', emoji: '🇺🇾' },
  { code: 'UZ', name: 'Uzbekistan', phone: '998', emoji: '🇺🇿' },
  {
    code: 'VA',
    name: 'Holy See (Vatican City State)',
    phone: '379',
    emoji: '🇻🇦',
  },
  {
    code: 'VC',
    name: 'Saint Vincent and the Grenadines',
    phone: '1-784',
    emoji: '🇻🇨',
  },
  { code: 'VE', name: 'Venezuela', phone: '58', emoji: '🇻🇪' },
  { code: 'VG', name: 'British Virgin Islands', phone: '1-284', emoji: '🇻🇬' },
  { code: 'VI', name: 'US Virgin Islands', phone: '1-340', emoji: '🇻🇮' },
  { code: 'VN', name: 'Vietnam', phone: '84', emoji: '🇻🇳' },
  { code: 'VU', name: 'Vanuatu', phone: '678', emoji: '🇻🇺' },
  { code: 'WF', name: 'Wallis and Futuna', phone: '681', emoji: '🇼🇫' },
  { code: 'WS', name: 'Samoa', phone: '685', emoji: '🇼🇸' },
  { code: 'XK', name: 'Kosovo', phone: '383', emoji: '🇽🇰' },
  { code: 'YE', name: 'Yemen', phone: '967', emoji: '🇾🇪' },
  { code: 'YT', name: 'Mayotte', phone: '262', emoji: '🇾🇹' },
  { code: 'ZA', name: 'South Africa', phone: '27', emoji: '🇿🇦' },
  { code: 'ZM', name: 'Zambia', phone: '260', emoji: '🇿🇲' },
  { code: 'ZW', name: 'Zimbabwe', phone: '263', emoji: '🇿🇼' },
];
