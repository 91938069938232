import { createAction } from 'redux-actions';

export const seriesRequest = createAction('search/series-request');

export const seriesResponse = createAction('search/series-response');

export const moreSeriesRequest = createAction('search/more-series-request');

export const moreSeriesResponse = createAction('search/more-series-response');

export const modelsRequest = createAction('search/models-request');

export const modelsResponse = createAction('search/models-response');

export const moreModelsRequest = createAction('search/more-models-request');

export const moreModelsResponse = createAction('search/more-models-response');

export const modelSpecificationsRequest = createAction(
  'search/model-specifications-request',
);

export const modelSpecificationsResponse = createAction(
  'search/model-specifications-response',
);

export const partsRequest = createAction('search/parts-request');

export const partsResponse = createAction('search/parts-response');

export const morePartsRequest = createAction('search/more-parts-request');

export const morePartsResponse = createAction('search/more-parts-response');

export const partsResetRequest = createAction('search/parts-reset-request');

export const partsResetResponse = createAction('search/parts-reset-response');

export const partDetailRequest = createAction('search/part-detail-request');

export const partDetailResponse = createAction('search/part-detail-response');

export const partDetailResetRequest = createAction(
  'search/part-detail-reset-request',
);

export const partDetailResetResponse = createAction(
  'search/part-replacement-detail-reset-response',
);

export const partReplacementDetailRequest = createAction(
  'search/part-replacement-detail-request',
);

export const partReplacementDetailResponse = createAction(
  'search/part-replacement-detail-response',
);

export const partReplacementDetailResetRequest = createAction(
  'search/part-replacement-detail-reset-request',
);

export const partReplacementDetailResetResponse = createAction(
  'search/part-detail-reset-response',
);

export const partReplacementRequest = createAction(
  'search/part-replacement-request',
);

export const partReplacementResponse = createAction(
  'search/part-replacement-response',
);

export const morePartReplacementRequest = createAction(
  'search/more-part-replacement-request',
);

export const morePartReplacementResponse = createAction(
  'search/more-part-replacement-response',
);

export const modelPartRequest = createAction('search/model-part-request');

export const modelPartResponse = createAction('search/model-part-response');

export const moreModelPartRequest = createAction(
  'search/more-model-part-request',
);

export const searchModelsRequest = createAction('search/search-models-request');

export const searchModelsResponse = createAction(
  'search/search-models-response',
);

export const searchMoreModelsRequest = createAction(
  'search/search-more-models-request',
);

export const searchMoreModelsResponse = createAction(
  'search/search-more-models-response',
);

export const searchPartRecommendReplacementsRequest = createAction(
  'search/search-part-recommend-replacements-request',
);

export const searchPartRecommendReplacementsResponse = createAction(
  'search/search-part-recommend-replacements-response',
);

export const previousPartsRequest = createAction(
  'search/previous-parts-request',
);

export const previousPartsResponse = createAction(
  'search/previous-parts-response',
);

export const previousSearchModelsRequest = createAction(
  'search/previous-search-models-request',
);

export const previousSearchModelsResponse = createAction(
  'search/previous-search-models-response',
);

export const previousModelPartRequest = createAction(
  'search/previous-model-parts-request',
);

export const previousModelPartResponse = createAction(
  'search/previous-model-parts-response',
);
