import * as Yup from 'yup';

import { ResetPasswordFormKeys } from '../interfaces';

export const ResetPasswordValidationSchema = Yup.object<
  Record<keyof ResetPasswordFormKeys, Yup.AnySchema>
>({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long.'),
  passwordConfirmation: Yup.string()
    .required('Password doesn’t match')
    .oneOf([Yup.ref('password'), null], 'Password doesn’t match'),
});
