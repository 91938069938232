import { Box, Skeleton, Typography } from '@mui/material';

const ChatSkeleton = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ flexGrow: 1, overflowY: 'scroll', p: 1 }}>
        {[...Array(3)].map((_, index) => (
          <Box
            key={index}
            sx={{
              mb: 1,
              p: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: index % 2 === 0 ? 'flex-end' : 'flex-start',
            }}
          >
            {index % 2 !== 0 && (
              <Typography
                variant='body1'
                className='flex w-full flex-col items-end justify-end'
              >
                <Skeleton
                  variant='rounded'
                  width='70%'
                  height={35}
                  className='my-1'
                />
                <Skeleton
                  className='my-1'
                  variant='rounded'
                  width='40%'
                  height={35}
                />
              </Typography>
            )}

            {index % 2 === 0 && (
              <Typography
                variant='body1'
                className='flex w-full flex-col items-start justify-start'
              >
                <Skeleton
                  variant='rounded'
                  width='70%'
                  height={35}
                  className='my-1'
                />
                <Skeleton
                  variant='rounded'
                  width='40%'
                  height={35}
                  className='my-1'
                />
              </Typography>
            )}
          </Box>
        ))}
      </Box>
      <Box sx={{ p: 1 }}>{/* Your message input and send button here */}</Box>
    </Box>
  );
};

export default ChatSkeleton;
