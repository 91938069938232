/* eslint-disable @typescript-eslint/no-explicit-any */
import { RequestRunningReturn, StateSelectorProps } from './interface';

export function isRequestRunning(
  state: StateSelectorProps,
  request: string,
  initialValue = false,
): boolean {
  return state.request[request.toString()]?.running ?? initialValue;
}

export function getRequestRunning(
  state: StateSelectorProps,
  request: string,
  initialValue = false,
): RequestRunningReturn {
  return {
    running: state.request[request.toString()]?.running ?? initialValue,
    requestId: state.request[request.toString()]?.requestId ?? '',
  };
}

export function getRequestError(
  state: StateSelectorProps,
  request: string | any,
): string | any {
  return state.request[request.toString()]?.error ?? '';
}

export function getRequestSuccess(
  state: StateSelectorProps,
  request: string,
): string {
  return state.request[request.toString()]?.success ?? '';
}
