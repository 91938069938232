import { Box, Typography } from '@mui/material';

import CancelIcon from '@assets/icons/cancel-white.svg';
import { getDateTimeMessage } from '@utils/Strings/dateFormat';

export const QuoteDeclinedMessage = ({
  text,
  timetoken,
}: {
  text?: string;
  timetoken: string;
}): JSX.Element => (
  <div className='item-chat'>
    <Box className='time-message'>
      <span className='break-words text-xs font-normal leading-4 opacity-50'>
        {' '}
        Auto Generated Message
      </span>
      <span>{getDateTimeMessage(timetoken, 'h:mm a')}</span>
    </Box>
    <Box className='flex flex-col rounded-lg bg-red-600 p-4 text-white'>
      <Box className='mb-2 flex'>
        <Box>
          <Box className='mr-4 flex flex-row items-center'>
            <img
              src={CancelIcon}
              alt='Logo'
              className='mr-4 h-5 w-5 text-white'
            />
            <Typography className='text-base font-normal leading-5 tracking-tighter'>
              {text}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  </div>
);
