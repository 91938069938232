//  Test constants
const testOptions = [
  {
    value: '1',
    label: 'Label 1',
    subLabel: 'Sublabel 1',
  },
  {
    value: '2',
    label: 'Label 2',
    subLabel: 'Sublabel 2',
  },
  {
    value: '3',
    label: 'Label 3',
    subLabel: 'Sublabel 3',
  },
  {
    value: '4',
    label: 'None',
    subLabel: 'None',
  },
];
export const dropdownLabelTestId = 'dropdown-label-test-id';
export const dropdownLoadingTestId = 'skeleton';
export const dropdownErrorTestId = 'dropdown-error-test-id';
export const dropdownPlaceholderTestId = 'dropdown-placeholder-test-id';
export const dropdownTestProps = {
  options: testOptions,
  placeholder: 'Dropdown',
  id: 'test-dropdown-id',
  'data-testid': 'test-dropdown-testid',
  selected: '',
};
