import { Box } from '@mui/material';
import { MessageEnvelope } from '@pubnub/react-chat-components';
import { FileEvent, MessageEvent } from 'pubnub';
import { usePubNub } from 'pubnub-react';
import { useCallback, useEffect, useReducer, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { saveLastReadTimeStamp } from '../pubnub';
import { groupByHour } from '../utils';

import { CustomMessageRender } from './CustomMessageRender';
import {
  ActionType,
  ChatActionProp,
  ChatState,
  GroupedMessages,
  MessageListProps,
} from './interface';
import ChatSkeleton from './Skeleton';

import { SomethingWrong } from '@components/SomethingWrong';
import { useAppSelector } from '@hooks/state';
import { getUserOrders } from '@state/chat/actions';
import { getStoreInfoData } from '@state/storeInfo/selectors';
import { getDateTimeMessage } from '@utils/Strings/dateFormat';

const messageListReducer = (
  state: ChatState,
  action: ChatActionProp,
): ChatState => {
  switch (action.type) {
    case ActionType.Loading: {
      return { ...state, status: 'loading' };
    }
    case ActionType.Resolved: {
      const channelId = action.data[0]?.channel;
      const newData = { ...state.data };

      newData[channelId] = action.data;

      return {
        ...state,
        status: 'resolved',
        data: newData,
        error: null,
      };
    }
    case ActionType.Rejected: {
      return { ...state, status: 'rejected', error: action.error };
    }
    case ActionType.SetExpanded: {
      return {
        ...state,
        orderDetailExpanded: action.data,
      };
    }

    case ActionType.NewMessage: {
      const allMessages = { ...state.data };

      allMessages[action.data.channel] = allMessages[action.data.channel]
        ? [...allMessages[action.data.channel], action.data]
        : [action.data];

      return {
        ...state,
        status: 'resolved',
        data: allMessages,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const MessageList = ({
  fetchMessagesCount,
  currentChatSelected,
}: MessageListProps): JSX.Element => {
  const pubnub = usePubNub();
  const dispatch = useDispatch();
  const endRef = useRef<HTMLDivElement>(null);
  const channelId = currentChatSelected.chat.channel;

  const { supplierLoggedId } = useAppSelector((state) => ({
    supplierLoggedId: getStoreInfoData(state)?.id,
  }));

  const [state, insideDispatch] = useReducer(messageListReducer, {
    status: 'loading',
    data: {},
    error: null,
    orderDetailExpanded: false,
  });
  const { status, data: messages, error } = state;

  const isLoading = status === 'loading';

  const messagesGroupedByHour = groupByHour(messages[channelId] || []);

  const fetchMessages = useCallback(async () => {
    try {
      insideDispatch({ type: ActionType.Loading });
      const response = await pubnub.fetchMessages({
        channels: [channelId],
        count: fetchMessagesCount,
      });
      const data = response?.channels[channelId] || [];
      insideDispatch({ type: ActionType.Resolved, data });
      scrollToBottom();
    } catch (e) {
      insideDispatch({ type: ActionType.Rejected, error: e });
    }
  }, [channelId, fetchMessagesCount]);

  const isOwnMessage = (uuid?: string) => {
    if (!uuid) return false;

    return [pubnub.getUUID(), supplierLoggedId].includes(uuid);
  };

  useEffect(() => {
    dispatch(getUserOrders(currentChatSelected.userId));
    fetchMessages();
    saveLastReadTimeStamp(pubnub, currentChatSelected);
  }, [fetchMessagesCount, fetchMessages]);

  const scrollToBottom = () => {
    if (!endRef.current) return;
    endRef.current.scrollIntoView({ block: 'end' });
  };

  const handleMessage = (newMessage: MessageEvent | FileEvent) => {
    setTimeout(() => scrollToBottom(), 0);
    insideDispatch({ type: ActionType.NewMessage, data: newMessage });
    saveLastReadTimeStamp(pubnub, currentChatSelected);
  };

  useEffect(() => {
    if (!supplierLoggedId) return;
    const listener = {
      message: (m: MessageEvent) => handleMessage(m),
      file: (e: FileEvent) => handleMessage(e),
    };

    pubnub.addListener(listener);
    pubnub.subscribe({
      channels: [`supplier-${supplierLoggedId}.*`],
    });

    return () => {
      pubnub.removeListener(listener);
      pubnub.unsubscribeAll();
    };
  }, [dispatch, pubnub, supplierLoggedId, currentChatSelected]);

  if (isLoading)
    return (
      <div className='h-full w-full'>
        <ChatSkeleton />
      </div>
    );

  if (error) return <SomethingWrong onReload={fetchMessages} />;

  const renderGroup = (group: GroupedMessages, i: number) => {
    const arrayGroup = Object.entries(group);

    const arrayMessages: Array<MessageEnvelope> = arrayGroup[0][1];

    const timeToken = arrayGroup[0][0];
    const dateTimeMessage = getDateTimeMessage(
      timeToken,
      'MMM dd, yyyy h:mm a',
    );

    return (
      <div key={'message-group-' + i} className='my-2 flex flex-col'>
        <span className='flex items-center justify-center text-xs text-bluon_dark_gray'>
          {dateTimeMessage}
        </span>

        <div>{arrayMessages.map((item) => renderItem(item))}</div>
      </div>
    );
  };
  const renderItem = (item: MessageEnvelope) => {
    const ownMessage = isOwnMessage(item.uuid) || isOwnMessage(item.publisher);

    const currentUserClass = ownMessage ? 'justify-end' : '';

    return (
      <div
        className={`relative flex h-auto flex-row items-end px-4 py-2.5 ${currentUserClass}`}
        key={'message-' + item.timetoken}
      >
        <CustomMessageRender
          message={{ ...item.message, timetoken: item.timetoken }}
          isOwnMessage={ownMessage}
        />
      </div>
    );
  };

  return (
    <div className={'flex h-full items-center justify-center'}>
      <div className='relative flex h-full w-full flex-col'>
        <div className='flex-auto' />
        {messagesGroupedByHour.map((g, i) => renderGroup(g, i))}
        <div className='h-px w-full flex-shrink-0' ref={endRef}></div>
      </div>
      <Box
        id='message-overlay'
        className='fixed bottom-0 left-0 right-0 top-0 z-50 hidden h-full w-full bg-black'
      />
    </div>
  );
};

export default MessageList;
