import { StoreInfoResponseStatesCountries } from '@state/storeInfo/interface';
import { DeliveryItem } from '@state/task/interface';

export interface ILogisticsProps {
  availabilityError: boolean;
  currentOrderId: string;
  disabled: boolean;
  etaAvailability: string;
  isExpanded: boolean;
  savedDelivery?: DeliveryItem | null | undefined;
  setEtaAvailability: (etaAvailability: string) => void;
  logisticMethod: string;
  setLogisticMethod: (logisticMethod: string) => void;
  deliveryFeeError: string;
  setDeliveryFeeError: (error: string) => void;
  fee: string;
  setFee: (value: string) => void;
  stepStatus: StoreInfoResponseStatesCountries;
  setIsValid: (
    value: boolean,
  ) => void | React.Dispatch<React.SetStateAction<boolean>>;
}

export interface RealTimeResponseTime {
  average_time_response?: number;
  forced_rapid_responder: boolean;
  has_over_ten_responses: boolean;
}

export enum AvailabilityType {
  Shipment = 'shipment',
  Delivery = 'delivery',
  InStorePickUp = 'in_store_pick_up',
}

export interface AvailabilitiesInterface {
  label: string;
  value: string;
  vehicle?: string;
  iconStart: React.ReactElement;
  type: AvailabilityType;
  disabled?: boolean;
}

export interface ItemSpecsProps {
  itemSpecs: KeyValueInterface | undefined;
  specsCount: number;
  type: string | null;
  isSpecsLoading: boolean;
  itemSpecsHasValueItems?: boolean;
}

export interface KeyValueInterface {
  [key: string]: string;
}

export interface InfoDetailsItem {
  brand: string | null;
  description: string | null;
  id: string | null;
  image: ImageFile;
  number: string | null;
  subcategory: string | null;
  subtype: string | null;
  type: string | null;
  name: string | null;
  internal_name: string | null;
  specifications: KeyValueInterface;
  creator: string;
}

export interface OrderItemDetail {
  details?: InfoDetailsItem;
  info?: InfoDetailsItem;
  description: string;
  id: string;
  note: string;
  type: string;
}

export interface OemPart {
  id: string;
  quantity: string;
  price: number;
  status: string;
  supply_detail: string;
  custom_detail: string;
  generic_part_description: string;
  replacement: OrderItemDetail;
  item: {
    id: string;
    type: string;
    info: {
      id: string;
      number: string;
      type: string;
      subtype: string;
      description: string;
      brand: string;
      image: ImageFile;
      specifications: KeyValueInterface;
      subcategory: string;
    };
  };
}

export interface OrderItem {
  expandableInfo: OemPart;
  generic_part_description: string;
  id: string;
  item: OrderItemDetail;
  replacement: OrderItemDetail | null;
  knownReplacements: {
    list: ReplacementItem[];
    hasNextPage: boolean;
    nextPage: number | null;
    total: number | null;
  };
  price: number;
  quantity: number;
  status: string;
  supply_detail: string;
  custom_detail: string;
}

export interface ReplacementItem {
  id: string;
  type: string;
  note: string;
  details: {
    id: string;
    number: string;
    brand: string;
    image: ImageFile;
    specifications: KeyValueInterface;
  };
}

export interface ImageFile {
  id: string;
  url: string;
  conversions?: Conversions[] | null;
}

export interface Conversions {
  thumb: string;
}

export interface PartData {
  id: string;
  quantity: number;
  price: number;
  status: string;
  supply_detail: string | null;
  custom_detail: string | null;
  generic_part_description: string | null;
  item: PartItem;
  replacement: Replacement | null;
}

export interface PartItem {
  id: string;
  type: string;
  details?: PartInfo;
  info: PartInfo;
}

export interface PartInfo {
  id: string;
  number: string;
  name?: string;
  internal_name?: string;
  type: string;
  subtype: string | null;
  description: string | null;
  brand: string;
  image: ImageFile | null;
  subcategory: string | null;
}

export interface Replacement {
  id: string;
  type: string;
  note?: string;
  description?: string;
  details?: PartInfo;
}
