import { CircularProgress, Grid } from '@mui/material';

import './styles.scss';

export const CircularLoading = (): JSX.Element => (
  <Grid className='loading-container-center'>
    <Grid item xs='auto'>
      <CircularProgress />
    </Grid>
  </Grid>
);
