import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import _ from 'lodash';
import React from 'react';

import { BranchHoursProps } from './interface';

import { weekDaysOrder } from '@pages/Account/subpages/StoreInfo/components/BranchHoursModal/constants';

export const BRANCH_HOURS_TEST_ID = 'branchHours';

export const BranchHours = ({
  dataHours = [],
  'data-testid': dataTestId = BRANCH_HOURS_TEST_ID,
}: BranchHoursProps): JSX.Element => {
  return (
    <Box
      data-testid={dataTestId && `${dataTestId}Container`}
      flexDirection='column'
      display='flex'
    >
      {_.orderBy(dataHours, (item) => weekDaysOrder[item.day]).map((item) => {
        return (
          item.active && (
            <React.Fragment key={item.day}>
              <Typography
                variant='body2'
                color='initial'
                data-testid={dataTestId && `${dataTestId}Item`}
              >
                <b>{_.capitalize(item.day)}</b> : {item.from} - {item.to}
              </Typography>
            </React.Fragment>
          )
        );
      })}
    </Box>
  );
};
