import InfoIcon from '@mui/icons-material/Info';
import { Box, Switch, tooltipClasses } from '@mui/material';
import React from 'react';

import './styles.scss';
import { ToggleInputProps } from './interface';

import { CustomTooltip } from '@components/CustomTooltip';

const ToggleInputStyles = {
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#3D5066',
    width: '150px',
    height: '52px',
    borderRadius: '0.25em',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '5px',

    ['span']: {
      fontSize: '0.75rem',
      lineHeight: '14px',
      fontWeight: '500',
    },

    [`& .${tooltipClasses.arrow}`]: {
      color: '#3D5066',
    },
  },
};

export const ToggleInput = ({
  title,
  tooltipMessage,
  id,
  value,
  onChange,
  disabled,
}: ToggleInputProps) => (
  <Box className='counter-staff-toggle-notification-container'>
    <span className='counter-staff-toggle-notification-label'>{title}</span>
    {tooltipMessage && (
      <CustomTooltip
        text={tooltipMessage}
        arrow
        placement='top'
        styles={ToggleInputStyles}
      >
        <InfoIcon className='counter-staff-toggle-notification-info-icon' />
      </CustomTooltip>
    )}
    <Switch id={id} checked={value} onChange={onChange} disabled={disabled} />
  </Box>
);
