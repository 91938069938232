export const fileUploaderAreaTestId = 'file-uploader-test-id';
export const fileUploaderSkeletonTestId = 'skeleton';
export const fileUploaderHelperTextTestId = 'file-uploader-helper-text-test-id';
export const fileUploaderTestProps = {
  isLoading: false,
  fieldName: 'file',
  error: undefined,
  file: new File([], 'testFile.txt'),
  'data-testid': fileUploaderAreaTestId,
};
