const urlGenerator = (
  orderId: string,
  part?: boolean,
  sliceEndIndicator?: number,
): string => {
  const partUrl = `${window.location.host}/#/order-summary?order=${orderId}`;
  const fullUrl = `${window.location.protocol}//${partUrl}`;

  return part
    ? `${partUrl.slice(0, sliceEndIndicator ? sliceEndIndicator : 20)}...`
    : fullUrl;
};

export { urlGenerator };
