import air_filter from './air_filter.png';
import belt from './belt.png';
import capacitor from './capacitor.png';
import compressor from './compressor.png';
import contactor from './contactor.png';
import control_board from './control_board.png';
import crankcase_heater from './crankcase_heater.png';
import fan_blade from './fan_blade.png';
import filter_drier from './filter_drier.png';
import gas_valve from './gas_valve.png';
import hard_start_kit from './hard_start_kit.png';
import igniter from './igniter.png';
import motor from './motor.png';
import pressure_switch from './pressure_switch.png';
import pulley from './pulley.png';
import relay from './relay.png';
import sensor from './sensor.png';
import temperature_control from './temperature_control.png';
import txv from './txv.png';
import wheel from './wheel.png';

export const partsImages = {
  air_filter,
  belt,
  capacitor,
  compressor,
  contactor,
  control_board,
  crankcase_heater,
  fan_blade,
  filter_drier_and_core: filter_drier,
  gas_valve,
  hard_start_kit,
  igniter,
  metering_device: txv,
  motor,
  pressure_control: pressure_switch,
  relay_switch_timer_sequencer: relay,
  relay: relay,
  sensor,
  sheave_and_pulley: pulley,
  temperature_control,
  wheel,
};
