import { Skeleton, Table, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';

const ANIMATION_DURATION = { animationDuration: '0.1s' };

const LogItemSkeleton = () => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell align='left' className='w-2/12'>
            <div className='flex flex-row items-center justify-start'>
              <Skeleton
                animation='wave'
                sx={ANIMATION_DURATION}
                width={96}
                height={12}
              />
            </div>
          </TableCell>
          <TableCell align='left' className='w-3/12'>
            <div className='flex flex-row items-center justify-start'>
              <Skeleton
                animation='wave'
                variant='circular'
                sx={ANIMATION_DURATION}
                width={40}
                height={40}
              />
              <Skeleton
                className='ml-1.5'
                animation='wave'
                sx={ANIMATION_DURATION}
                width={75}
                height={12}
              />
              <Skeleton
                className='ml-1.5'
                animation='wave'
                sx={ANIMATION_DURATION}
                width={75}
                height={12}
              />
            </div>
          </TableCell>
          <TableCell align='right' className='w-4/12'>
            <div className='flex flex-row items-center justify-end'>
              <Skeleton
                animation='wave'
                sx={ANIMATION_DURATION}
                width={120}
                height={12}
              />
            </div>
          </TableCell>
          <TableCell align='right' className='w-3/12'>
            <div className='flex flex-row items-center justify-end'>
              <Skeleton
                animation='wave'
                sx={ANIMATION_DURATION}
                width={120}
                height={12}
              />
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default LogItemSkeleton;
