import { FileEvent, MessageEvent } from 'pubnub';
import { usePubNub } from 'pubnub-react';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { MetaDataChannelInterface } from './interface';
import {
  getAllChannelMetadata,
  getMessageCounts,
  saveLastReadTimeStamp,
} from './pubnub';
import { getFilterCondition } from './utils';

import { PubNubUtils } from '@config/pubnub';
import { useAppSelector } from '@hooks/state';
import {
  fillChatUnreadMessages,
  getChannelUsers,
  updateChannelListsLocally,
} from '@state/chat/actions';
import { ChannelSelectedState } from '@state/chat/interface';
import { getChannelList, getCurrentChatSelected } from '@state/chat/selectors';
import { getStoreInfoData } from '@state/storeInfo/selectors';
import { getLoginInfo } from '@utils/Auth/AuthUtils';
import { MessageTypes } from '@utils/enums';
import { playNotificationSound } from '@utils/functions';
import { getDateTimeUTCMessage } from '@utils/Strings/dateFormat';

interface TimeToken {
  id: string;
  lastReadTimetoken: number;
  techLastReadTimetoken: number;
}

export function useChat() {
  const dispatch = useDispatch();
  const pubnub = usePubNub();
  const userLoggedID = getLoginInfo().id;

  const { supplierLoggedId, channelList, currentChatSelected } = useAppSelector(
    (state) => ({
      supplierLoggedId: getStoreInfoData(state)?.id,
      channelList: getChannelList(state),
      currentChatSelected: getCurrentChatSelected(state),
    }),
  );

  const getTimeTokens = async (): Promise<TimeToken[]> => {
    const { response } = await getAllChannelMetadata(
      pubnub,
      { customFields: true },
      getFilterCondition(channelList as unknown as ChannelSelectedState[]),
    );
    const timeTokens = response.data.map((item: MetaDataChannelInterface) => {
      const data = {
        id: item.id,
        lastReadTimetoken: item?.custom?.lastReadTimetoken as number,
        techLastReadTimetoken: item?.custom?.techLastReadTimetoken as number,
      };

      return data;
    });

    return timeTokens;
  };

  const setChatUnreadMessages = async (timeTokens: TimeToken[]) => {
    if (!timeTokens) return;
    const channelsTimeTokens = timeTokens.map((item) => {
      return item.lastReadTimetoken ?? 1;
    });
    const channelIds = timeTokens.map((item) => {
      return item.id;
    });
    const channels = await getMessageCounts(
      pubnub,
      channelIds,
      channelsTimeTokens,
    );
    if (channels) dispatch(fillChatUnreadMessages(channels));
  };

  const handleSendMessage = async () => {
    if (!currentChatSelected) return;
    saveLastReadTimeStamp(pubnub, currentChatSelected);
  };

  const handleMessage = useCallback(
    async (newMessage: MessageEvent | FileEvent) => {
      const updatedChannel = channelList.find(
        (item) => item.chat.channel === newMessage.channel,
      );

      if (!updatedChannel) dispatch(getChannelUsers());
      // Update channel list
      if (updatedChannel) {
        const newDate = getDateTimeUTCMessage(
          newMessage.timetoken,
          'yyyy-MM-dd HH:mm:ss',
        );
        updatedChannel.chat.last_message_at = newDate;
      }
      const sortedChannelList = [
        updatedChannel,
        ...channelList.filter(
          (item) => item.chat.channel !== newMessage.channel,
        ),
      ];
      if (currentChatSelected?.chat?.channel === newMessage.channel) {
        setTimeout(async () => {
          await saveLastReadTimeStamp(pubnub, currentChatSelected);
          dispatch(updateChannelListsLocally({ data: sortedChannelList }));
        }, 1000);
      } else {
        const type = newMessage.message.type;
        const publisher = newMessage.publisher;
        if (
          supplierLoggedId !== publisher &&
          MessageTypes.ApprovedOrder !== type
        ) {
          if (updatedChannel != undefined) {
            const timeTokens = await getTimeTokens();
            setChatUnreadMessages(timeTokens);
          }
          playNotificationSound(false);
          dispatch(updateChannelListsLocally({ data: sortedChannelList }));
        }
      }
    },
    [currentChatSelected, channelList, pubnub],
  );

  useEffect(() => {
    dispatch(getChannelUsers());
  }, []);

  useEffect(() => {
    if (channelList.length < 1) return;
    const fetchUnreadMessages = async () => {
      const timeTokens = await getTimeTokens();
      setChatUnreadMessages(timeTokens);
    };
    fetchUnreadMessages();
  }, [channelList]);

  useEffect(() => {
    if (!supplierLoggedId) return;
    const listener = {
      message: (m: MessageEvent) => handleMessage(m),
      file: (e: FileEvent) => handleMessage(e),
    };

    pubnub.addListener(listener);
    pubnub.subscribe({
      channels: [`supplier-${supplierLoggedId}.*`],
    });

    return () => {
      pubnub.removeListener(listener);
      pubnub.unsubscribeAll();
    };
  }, [handleMessage, pubnub, supplierLoggedId]);

  useEffect(() => {
    if (pubnub && userLoggedID) PubNubUtils.getInstance().refreshUUID();
  }, [pubnub, userLoggedID]);

  return { handleSendMessage };
}
