import CloseIcon from '@mui/icons-material/Close';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { ChatHeaderProps } from './interface';
export const ChatHeader = ({
  onClose,
  title,
}: ChatHeaderProps): JSX.Element => {
  return (
    <Grid
      container
      className='z-10 bg-primary px-2.5 py-3 text-white shadow-chat'
    >
      <Grid item xs={6} className='text-left'>
        <IconButton
          sx={{ '&:hover': { background: 'transparent' } }}
          style={{
            padding: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon
            style={{
              fontSize: '1.3rem',
            }}
            className='font-bold text-white'
          />
        </IconButton>
      </Grid>
      <Grid className='text-right text-sm' item xs={6}>
        {' '}
        {title}{' '}
      </Grid>
    </Grid>
  );
};
