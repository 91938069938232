import { validationSettingsResponse } from './actions';
import {
  ValidationSettingsResponse,
  ValidationSettingsStateProps,
} from './interface';

import { ValidationSettingsDataModel } from '@config/api/validationSettings/interface';

const initialState = {
  settings: [],
};

const validationSettingsReducer = (
  state = <ValidationSettingsStateProps>initialState,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  action: any,
): ValidationSettingsStateProps => {
  switch (action.type) {
    case String(validationSettingsResponse): {
      return {
        ...state,
        settings: (action as ValidationSettingsResponse).payload
          .data as ValidationSettingsDataModel[],
      };
    }
    default:
      return state;
  }
};

export default validationSettingsReducer;
