import PubNub from 'pubnub';

import { getLoginInfo } from '@utils/Auth/AuthUtils';

export class PubNubUtils {
  private static _instance: PubNubUtils = new PubNubUtils();
  private _pubnub;
  environment = process ? process.env : null;

  constructor() {
    this._pubnub = new PubNub({
      publishKey: this.environment?.REACT_APP_PUBNUB_PUBLISH_KEY ?? '',
      subscribeKey: this.environment?.REACT_APP_PUBNUB_SUBSCRIBE_KEY ?? '',
      uuid: getLoginInfo().id || 'null',
      restore: true,
    });

    if (PubNubUtils._instance)
      throw new Error(
        'Error: Instantiation failed: Use PubNubUtils.getInstance() instead of new.',
      );

    PubNubUtils._instance = this;
  }

  public static getInstance(): PubNubUtils {
    return PubNubUtils._instance;
  }

  public getPubNubInstance(): PubNub {
    return this._pubnub;
  }

  public refreshUUID(): void {
    const userLoggedID = getLoginInfo().id;

    if (!userLoggedID) return this.refreshUUID();

    return this._pubnub.setUUID(userLoggedID);
  }
}
