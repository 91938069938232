import { Typography } from '@mui/material';

import { formatDate } from '../../constants';

import { TakeRateProps } from './interface';
export const TAKE_RATE_TEST_ID = 'takeRate';

const TakeRate = ({
  className,
  take_rate,
  take_rate_until,
}: TakeRateProps): JSX.Element => {
  return (
    <Typography className={className} data-testid={TAKE_RATE_TEST_ID}>
      Your Applicable Percentage (% commission fee) is {take_rate}% through{' '}
      {formatDate(take_rate_until)}.
    </Typography>
  );
};

export default TakeRate;
