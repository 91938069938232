import * as Yup from 'yup';

import { LoginFormKeys } from '../interfaces';

export const LoginValidationSchema = Yup.object<
  Record<keyof LoginFormKeys, Yup.AnySchema>
>({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
});
