import { Skeleton } from '@mui/material';

const IndividualSkeleton = () => (
  <div className='relative flex flex-row'>
    <div className='absolute left-2 top-2 z-10 h-5 w-5 rounded-full bg-white' />
    <Skeleton variant='rounded' width={260} height={36} />
  </div>
);

const ReasonsSkeleton = () => (
  <div className='mt-4 flex flex-col gap-4'>
    {[1, 2, 3].map((val) => (
      <div key={val} className='flex flex-col gap-4 md:flex-row'>
        <IndividualSkeleton />
        <IndividualSkeleton />
      </div>
    ))}
  </div>
);

export default ReasonsSkeleton;
