import { Skeleton } from '@mui/material';

const BannerSkeleton = () => {
  return (
    <div className='banner z-[1399] flex justify-center bg-[var(--bluon--ds--darkblue)] py-1'>
      <Skeleton variant='rounded' width={90} height={12} className='my-1' />
      <Skeleton variant='rounded' width={140} height={12} className='my-1' />
    </div>
  );
};

export default BannerSkeleton;
