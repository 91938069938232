import { Box } from '@mui/material';

import CustomChatItem from './CustomChatItem';
import { InboxListProps } from './interface';

import { VirtualizedInfiniteList } from '@components/VirtualizedInfiniteList';

const InboxList = ({
  channelList,
  currentChatSelected,
  isLoadingNextPageChannel,
  onLoadMore,
  chatListHasNextPage,
  unreadChatMessages,
}: InboxListProps): JSX.Element => {
  return (
    <Box className='h-full'>
      {channelList.length > 0 && (
        <VirtualizedInfiniteList
          hasNextPage={chatListHasNextPage}
          isLoading={isLoadingNextPageChannel}
          list={channelList}
          onLoadMore={onLoadMore}
          selectedItem={false}
          onClickItem={undefined}
          rowHeight={84}
        >
          {({ list, index }) => {
            const item = list[index];

            return (
              <CustomChatItem
                key={index}
                channel={item}
                unreadChatMessages={unreadChatMessages}
                currentChatSelected={currentChatSelected}
              />
            );
          }}
        </VirtualizedInfiniteList>
      )}
    </Box>
  );
};

export default InboxList;
