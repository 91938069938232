/* eslint-disable */
import * as Yup from 'yup';
import { ContractorLoginFormKeys } from '../interfaces';
import gPhoneNumber from 'google-libphonenumber';

const YUP_PHONE_METHOD = 'phoneNumber';
const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  YUP_PHONE_METHOD,
  function (message: string) {
    return this.test(YUP_PHONE_METHOD, message, function () {
      const {
        path,
        createError,
        parent: { phoneNumber, countryCode },
      } = this;

      if (!phoneNumber) {
        return createError({ path, message });
      }

      const number = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);

      return phoneUtil.isValidNumber(number) || createError({ path, message });
    });
  },
);

export const ContractorLoginValidationSchema = Yup.object<
  Record<keyof ContractorLoginFormKeys, Yup.AnySchema>
>({
  countryCode: Yup.string(),
  phoneNumber: Yup.string().phoneNumber('Invalid phone number'),
});
