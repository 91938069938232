import {
  notificationSettingsResponse,
  updateNotificationSettingsResponse,
} from './actions';
import {
  NotificationSettingsResponse,
  NotificationSettingsStateProps,
} from './interface';

import { NotificationSettingsDataModel } from '@config/api/notificationSettings/interface';

const initialState = {
  settings: [],
};

const notificationSettingsReducer = (
  state = <NotificationSettingsStateProps>initialState,
  action: any,
): NotificationSettingsStateProps => {
  switch (action.type) {
    case String(notificationSettingsResponse): {
      return {
        ...state,
        settings: (action as NotificationSettingsResponse).payload
          .data as NotificationSettingsDataModel[],
      };
    }
    case String(updateNotificationSettingsResponse): {
      return {
        ...state,
        settings: state.settings.map((setting) => {
          const item = action.payload.data.find(
            (d: { id: string }) => d.id === setting.id,
          );

          return {
            ...setting,
            value: item?.value ?? setting.value,
          };
        }),
      };
    }
    default:
      return state;
  }
};

export default notificationSettingsReducer;
