import Box from '@mui/material/Box';

import { TabPanelProps } from './interface';

export const TAB_PANEL_TEST_ID = 'tabPanel';

export const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, padding, ...other } = props;

  return (
    <div
      data-testid={TAB_PANEL_TEST_ID}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: padding !== undefined ? padding : 3 }}>{children}</Box>
      )}
    </div>
  );
};
