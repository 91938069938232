import * as Yup from 'yup';

export const TotalValidationSchema = Yup.object().shape({
  total: Yup.string()
    .min(1, 'Required')
    .test(
      'max',
      'Max amount cannot be higher than 9,999,999.99',
      (value, context) => {
        const total = context.parent.total.replace(/\$|,/g, '');

        return !(total > 10000000);
      },
    )
    .required(),
});
