import * as Yup from 'yup';

import { DeclineModalForm } from './interface';

export const DeclineModalValidationSchema = Yup.object<
  Record<keyof DeclineModalForm, Yup.AnySchema>
>({
  selectedOption: Yup.string(),
  otherText: Yup.string(),
});
