/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash';
import { all, select, takeLatest } from 'redux-saga/effects';
import * as Eff from 'redux-saga/effects';

import makeRequest from '../requests/make-request';
import { getRequestError } from '../requests/selectors';

import {
  notificationSettingsRequest,
  notificationSettingsResponse,
  updateNotificationSettingsRequest,
  updateNotificationSettingsResponse,
} from './actions';
import { NotificationSettingsActionProps } from './interface';

import {
  getNotificationsSettings,
  updateNotificationSettings,
} from '@config/api/notificationSettings';

export function* workerGetNotificationSettings(
  action: NotificationSettingsActionProps,
): Generator<any> {
  const call: any = Eff.call;
  yield call(makeRequest, {
    endpoint: getNotificationsSettings,
    requestAction: action,
    receiveAction: notificationSettingsResponse,
  });
}

export function* workerUpdateNotificationSettings(action: any): Generator<any> {
  const call: any = Eff.call;

  yield call(makeRequest, {
    endpoint: updateNotificationSettings,
    params: action.payload,
    requestAction: action,
    receiveAction: updateNotificationSettingsResponse,
  });

  const error = yield select((state) =>
    getRequestError(state, updateNotificationSettingsRequest),
  );

  if (!isEmpty(error)) yield call(action.payload.errorCallback);
}

export default function* notificationSettingsSagas(): Generator<any> {
  yield all([
    takeLatest(notificationSettingsRequest, workerGetNotificationSettings),
    takeLatest(
      updateNotificationSettingsRequest,
      workerUpdateNotificationSettings,
    ),
  ]);
}
