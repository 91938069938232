import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import { useState } from 'react';

import ModalButton from '../../../../components/Button';
import { ButtonProps } from '../../../../components/Button/interface';

import {
  ForgotPasswordModalButtonProps,
  ForgotPasswordModalProps,
} from './interface';

import WarningIcon from '@assets/icons/WarningIcon.svg';

import './styles.scss';

export const WithForgotPasswordModal =
  ({ label, ...props }: ForgotPasswordModalButtonProps) =>
  (Button: ({ children, loading, ...props }: ButtonProps) => JSX.Element) =>
    function ForgotPasswordModal({
      handleSubmit,
      handleSubmitForm,
    }: ForgotPasswordModalProps): JSX.Element {
      const [open, setOpen] = useState(false);

      const handleClickOpen = () => {
        setOpen(true);
      };

      const handleReset = async () => {
        await handleSubmit(handleSubmitForm)();
        setOpen(false);
      };

      const handleClose = () => {
        setOpen(false);
      };

      return (
        <>
          <Button onClick={handleClickOpen} {...props}>
            {label}
          </Button>
          <Dialog
            className='forgot-password-modal'
            open={open}
            onClose={handleClose}
          >
            <Grid className='forgot-password-warning-icon-container' container>
              <Grid item>
                <img
                  className='forgot-password-warning-icon'
                  alt='warning-icon'
                  src={WarningIcon}
                />
              </Grid>
            </Grid>

            <Grid className='forgot-password-content-container' container>
              <Grid item>
                <DialogTitle>
                  Are you sure you want to reset your password?
                </DialogTitle>
              </Grid>
              <Grid item>
                <DialogContent className='forgot-password-content-text-container'>
                  <DialogContentText className='forgot-password-content-text'>
                    Remember this will reset the password for everyone using
                    this account. After reset, inform your team members so they
                    can access the account.
                  </DialogContentText>
                </DialogContent>
              </Grid>
            </Grid>

            <DialogActions className='forgot-password-buttons-container'>
              <ModalButton variant='outlined' onClick={handleClose}>
                Cancel
              </ModalButton>
              <ModalButton
                className='forgot-password-reset-button'
                variant='contained'
                disableElevation
                onClick={handleReset}
                aria-label='Reset'
              >
                Reset
              </ModalButton>
            </DialogActions>
          </Dialog>
        </>
      );
    };
