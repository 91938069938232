import { Box, Link, Typography } from '@mui/material';
import { useState } from 'react';

import './styles.scss';
import FileIcon from '@assets/icons/pdffile.svg';
import { ImageExpander } from '@components/ImageExpander';

export const FileMessage = ({
  fileLink,
  messageObject,
  isOwnMessage,
}: {
  fileLink: string;
  messageObject: { file: { name: string; id: string } };
  isOwnMessage: boolean;
}): JSX.Element => {
  const [openImg, setOpenImg] = useState(false);

  const isPdfFile = messageObject?.file?.name?.split('.').pop() === 'pdf';

  return (
    <>
      <>
        <Box
          className={`bubble-message-container rounded-lg bg-bluon_gray p-4${
            isOwnMessage ? 'own-message' : 'tech-message'
          }`}
        >
          {!isPdfFile && messageObject.file && (
            <Box
              onClick={() => setOpenImg(true)}
              className='msg-container-img-file'
            >
              <img className='msg-img-file' src={fileLink} />
            </Box>
          )}
          {isPdfFile && messageObject.file && (
            <Link href={fileLink} target='_blank' rel='noopener noreferrer'>
              <Box className='msg-container-link-pdf'>
                <img className='img-file-pdf' src={FileIcon} />

                <Typography className='link-pdf' variant='caption'>
                  {messageObject.file.name}
                </Typography>
              </Box>
            </Link>
          )}
          <ImageExpander
            fileLink={fileLink}
            setIsOpen={setOpenImg}
            isOpen={openImg}
            overlayClass='message-overlay'
          />
        </Box>
      </>
    </>
  );
};
