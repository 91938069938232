export const getHasNextPageFromMeta = (meta: {
  current_page: number;
  last_page: number;
}): boolean => {
  if (!meta) return false;

  return meta.current_page < meta.last_page;
};

export const getNextPageFromMeta = (meta: {
  current_page: number;
  last_page: number;
}): number | null => {
  if (!meta) return null;

  return meta.current_page < meta.last_page ? meta.current_page + 1 : null;
};
