import { SupplierRatingDetailProps } from '../interface';
import RatingBar from '../RatingBar';

const SupplierRatingDetail = ({ rating }: SupplierRatingDetailProps) => {
  const getRatePercentage = (rate: number, totalReviews: number) => {
    if (!totalReviews) return 0;
    const percentage = (rate / totalReviews) * 100;

    return Math.round(percentage);
  };

  const renderRates = () => {
    const rateBars = [];
    for (const [key, value] of Object.entries(rating?.rates))
      rateBars.push(
        <RatingBar
          key={key}
          label={key}
          percentage={getRatePercentage(value, rating?.reviews)}
        />,
      );

    return rateBars.reverse();
  };

  return (
    <div className='flex w-[100%] flex-col px-2 pb-2 pt-3'>
      <div className='mb-2 flex flex-row items-center justify-start'>
        <div className='flex flex-row items-center justify-center text-lg font-normal text-white'>
          {`${
            rating?.score !== 5 ? rating?.score.toFixed(1) : rating?.score
          } out of 5 stars`}
        </div>
        <div className='ml-auto flex flex-row items-center justify-center text-sm font-normal leading-6 text-white'>
          {`${rating?.reviews} reviews`}
        </div>
      </div>
      <div className='flex w-full flex-col items-center justify-center px-2'>
        {renderRates()}
      </div>
    </div>
  );
};

export default SupplierRatingDetail;
