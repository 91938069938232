import { useCallback, useState } from 'react';

import { OrderProps } from '../../interface';

import {
  canceledMessage,
  completedMessage,
  messages,
  pendingApprovalFulfilledMessage,
} from './constants';
import LoadingSkeleleton from './LoadingSkeleton';

import BluonLogo from '@assets/images/Bluon.svg';
import BluonLogoTM from '@assets/images/BluonTM.svg';
import { Invoice, Order } from '@config/api/task/interface';
import { EDeliveryType, EOrderStatus, EOrderSubstatus } from '@utils/enums';
import { getOrderSupplierName, getOrderTechName } from '@utils/Orders';

const OrderHeader = ({ order, isLoading }: OrderProps) => {
  const [isMobile] = useState<boolean>(window.innerWidth <= 900);
  const getMessage = useCallback(
    (
      status: string,
      substatus: string,
      delivery: EDeliveryType,
      invoice: Invoice | null,
    ) => {
      if (status === EOrderStatus.Completed) return completedMessage;
      if (status === EOrderStatus.Canceled) return canceledMessage;
      if (substatus === EOrderSubstatus.PENDING_APPROVAL_FULFILLED)
        return pendingApprovalFulfilledMessage;

      const messageObject = messages[delivery];
      if (!messageObject) return '';

      //  Handling Curri special status
      if (
        delivery === EDeliveryType.CURRI_DELIVERY &&
        (substatus ===
          EOrderSubstatus.APPROVED_READY_DELIVERY_QUOTE_NEEDED_WS ||
          substatus === EOrderSubstatus.APPROVED_READY_DELIVERY_QUOTE_NEEDED ||
          substatus === EOrderSubstatus.APPROVED_READY_DELIVERY_QUOTE_UPDATED)
      )
        return invoice
          ? messageObject[EOrderSubstatus.APPROVED_READY_DELIVERY]
          : messageObject[EOrderSubstatus.APPROVED_AWAITING_DELIVERY];

      //  Returning predefined messages
      return messageObject[substatus] || '';
    },
    [],
  );

  const getMessageColor = (status: string, substatus: string) => {
    if (status === EOrderStatus.Completed)
      return 'text-[var(--bluon--ds--lightgreen)]';
    if (status === EOrderStatus.Canceled)
      return 'text-[var(--bluon--ds--lightred)]';
    if (substatus === EOrderSubstatus.PENDING_APPROVAL_QUOTE_UPDATED)
      return 'text-[var(--bluon--ds--lighorange)]';

    return 'text-[var(--bluon--ds--lightblue)]';
  };

  const renderBidNumber = () => {
    if (!order?.bid_number && !order?.name) return null;

    const bidNumber = order?.bid_number ? `Bid #${order?.bid_number}` : '';
    const reference = order?.name || '';
    const separator = bidNumber && reference ? ' \u2022 ' : '';

    return (
      <div className='max-w-full break-all text-center text-xl font-medium leading-8 tracking-wide text-black'>
        {`${bidNumber}${separator}${reference}`}
      </div>
    );
  };

  const renderStatusMessage = () => {
    const status = order?.current_status?.status || '';
    const substatus = order?.current_status?.substatus || '';
    const delivery = order?.delivery?.type as EDeliveryType;

    const message = getMessage(
      status,
      substatus,
      delivery,
      order?.invoice || null,
    );
    const color = getMessageColor(status, substatus);

    return (
      <div
        className={`mb-[1.5rem] mt-2 text-center text-xl font-medium leading-8 tracking-wide md:mt-4  ${color}`}
      >
        {message}
      </div>
    );
  };

  const renderTechSupplierData = () => {
    return (
      <div className='font-sm break-word mt-1 max-w-full text-center font-normal tracking-wide text-black'>
        {`${getOrderTechName(
          order as Order,
        )} requested to ${getOrderSupplierName(order)}`}
      </div>
    );
  };

  if (isLoading) return <LoadingSkeleleton />;

  // Rendering
  return (
    <div className='flex w-full flex-row items-center justify-center px-[1rem] md:px-0'>
      <div className='flex flex-col items-center justify-center md:w-full'>
        <img
          src={isMobile ? BluonLogoTM : BluonLogo}
          className='w-25 mb-8 h-9'
        />
        {renderBidNumber()}
        {renderTechSupplierData()}
        {renderStatusMessage()}
      </div>
    </div>
  );
};

export default OrderHeader;
