import { Box, Typography } from '@mui/material';
import { useState } from 'react';

import FastIcon from '@assets/icons/fast.svg';
import MediumIcon from '@assets/icons/medium.svg';
import SlowIcon from '@assets/icons/slow.svg';
import { hoursAndMinutesByMinutes } from '@utils/Dates';

import './styles.scss';

export const ResponseTimeBadge = ({
  minutes,
  isFlagedAsRapidResponder,
  hasMoreThanTenOrders,
}: {
  minutes: number;
  isFlagedAsRapidResponder: boolean;
  hasMoreThanTenOrders: boolean;
}) => {
  const [width, setWidth] = useState(0);

  function handleDivWidth() {
    if (document) {
      const id = document.getElementById('container-text');
      if (id?.clientWidth) setWidth(id?.clientWidth);
    }
  }

  const formattedMinutes: string = hoursAndMinutesByMinutes(minutes);
  const hoverWidth =
    minutes === 1
      ? '23'
      : minutes > 59 && formattedMinutes.includes('minute')
      ? '28'
      : '23.5';

  const handleAverageTimeSection = () => {
    if (isFlagedAsRapidResponder)
      // Green
      return (
        <Box
          sx={{
            '&:hover': {
              width: `${hoverWidth}rem`,
            },
          }}
          className='container-badge fast mr-[0.75rem] flex h-[2.688rem] w-[2.688rem] items-center rounded-[6.25rem] border bg-white shadow-md'
        >
          <Box className='container-icon right-[0.438rem]'>
            <img alt='Fast' src={FastIcon} />
          </Box>
          <Box className='container-text absolute w-max px-[0.5rem] py-[0.25rem] text-[var(--bluon--dark--green-1)]'>
            <Typography className='badge-text' variant='body1'>
              {formattedMinutes} avg. response time - rapid speed!
            </Typography>
          </Box>
        </Box>
      );

    if (!isFlagedAsRapidResponder && hasMoreThanTenOrders && minutes <= 25)
      // Yellow
      return (
        <Box className='container-badge medium mr-[0.75rem] flex h-[2.688rem] w-[2.688rem] items-center rounded-[6.25rem] border bg-white  shadow-md hover:w-[21.8rem]'>
          <Box className='container-icon right-[0.625rem]'>
            <img alt='Fast' src={MediumIcon} />
          </Box>
          <Box className='container-text absolute w-max px-[0.5rem] py-[0.25rem] text-[var(--bluon--light--gray-23)]'>
            <Typography className='badge-text' variant='body1'>
              {formattedMinutes} avg. response time - average
            </Typography>
          </Box>
        </Box>
      );

    if (!isFlagedAsRapidResponder && hasMoreThanTenOrders && minutes > 25)
      // Red
      return (
        <Box
          sx={{
            '&:hover': {
              width: `${width + 42}px`,
            },
          }}
          className='container-badge mr-[0.75rem] flex h-[2.688rem] w-[2.688rem] items-center rounded-[6.25rem] border bg-white shadow-md'
          onMouseEnter={handleDivWidth}
          onMouseLeave={handleDivWidth}
        >
          <Box className='container-icon right-[0.438rem]'>
            <img alt='Fast' src={SlowIcon} />
          </Box>
          <Box
            id='container-text'
            className='container-text absolute mr-[2rem] w-max px-[0.5rem] py-[0.25rem] text-[var(--bluon--default--red)]'
          >
            <Typography className='badge-text' variant='body1'>
              {formattedMinutes} avg. response time - below average
            </Typography>
          </Box>
        </Box>
      );
  };

  return <Box className='container-all'>{handleAverageTimeSection()}</Box>;
};
