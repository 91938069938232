import { MessageEnvelope } from '@pubnub/react-chat-components';

import { ChannelSelectedState } from '@state/chat/interface';

export interface MessageListProps {
  currentChatSelected: ChannelSelectedState;
  fetchMessagesCount: number;
  filterMessagesByOrderId?: string;
}

export const ActionType = {
  Resolved: 'resolved',
  Rejected: 'rejected',
  Idle: 'idle',
  Loading: 'loading',
  NewMessage: 'newMessage',
  FilterMessages: 'filterMessages',
  SetExpanded: 'setExpanded',
};

export interface GroupedMessages {
  [timetoken: string]: MessageEnvelope[];
}

export interface ChatState {
  status: string;
  data: Record<string, MessageEnvelope[]>;
  error: any;
  orderDetailExpanded?: boolean;
}

export interface ChatActionProp {
  data?: any;
  error?: any;
  type: string;
}
