import { TableCell } from '@mui/material';
import React, { useMemo } from 'react';

import { CellProps } from '../LogsTable/interface';

import ImagesList from '@components/ImagesList';
import { partImage } from '@utils/functions';

const PartsCell = ({ log, ...restOfProps }: CellProps) => {
  const { items, total_line_items } = log;

  const listOfImages = useMemo(
    () =>
      items?.map((item) => ({
        source: partImage(item.info, item.info.type),
        name: item.type,
      })),
    [items],
  );

  const totalImagesLength =
    items?.length === 3 && total_line_items > 3
      ? total_line_items - items.length
      : 0;

  const renderRestMessage = (message: string) => {
    return (
      <div className='align-centertext-xs flex flex-row justify-center font-normal text-[var(--bluon--darker--gray)]'>
        {message}
      </div>
    );
  };

  return (
    <TableCell {...restOfProps}>
      <ImagesList
        list={listOfImages}
        totalLength={totalImagesLength}
        customRenderRestMessage={renderRestMessage}
      />
    </TableCell>
  );
};

export default PartsCell;
